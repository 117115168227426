import { useEffect } from "react"
import { IdParams, UpdateUserRequestSchema } from "raml-lib"
import { useApiGetUser, useApiUpdateUser } from "../../api"
import { FormDialog, ShowHideProps } from "../forms/FormDialog"
import { useNotificationContext, Notification } from "../../notifications"

type Props = ShowHideProps & IdParams

export function UpdateUserDialog(props: Props) {
  const { shown, id } = props
  const { onNotification } = useNotificationContext()
  const { mutate } = useApiUpdateUser(id, {
    onSuccess: (result) => {
      onNotification(
        new Notification("User updated", <p>Updated {result.email}</p>)
      )
    },
  })

  const { data, refetch } = useApiGetUser(id)

  // We don't want email in our form data.
  // It can't be edited once set
  const { email, ...formData } = data || {}

  useEffect(() => {
    if (shown && id) {
      refetch()
    }
  }, [shown, id, refetch])

  if (!data) {
    return null
  }

  return (
    <FormDialog
      schema={UpdateUserRequestSchema}
      onSubmit={mutate}
      data={formData}
      uiSchema={{
        id: {
          "ui:widget": "hidden",
        },
        updatedAt: {
          "ui:widget": "hidden",
        },
        createdAt: {
          "ui:widget": "hidden",
        },
        enabled: {
          "ui:widget": "radio",
        },
      }}
      {...props}
    />
  )
}
