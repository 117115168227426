import { useQueryClient } from "react-query"
import {
  MeResponse,
  Id,
  GetOrderResponse,
  CreateOrderRequest,
  CreateOrderResponse,
  LogoutResponse,
  LoginResponse,
  LogoutRequest,
  LoginRequest,
  CsrfTokenResponse,
  ListOrderQuery,
  ListOrderResponse,
  UpdateOrderRequest,
  UpdateOrderResponse,
  DeleteOrderResponse,
  ExportOrdersRequest,
  ExportOrdersResponse,
  ChangePasswordRequest,
  ChangePasswordResponse,
  GetApiKeyResponse,
  CreateApiKeyRequest,
  CreateApiKeyResponse,
  DeleteApiKeyResponse,
  ListApiKeyResponse,
  UpdateApiKeyRequest,
  UpdateApiKeyResponse,
  ApiKeyTokenResponse,
  ListApiKeyQuery,
  CreateSiteRequest,
  CreateSiteResponse,
  DeleteSiteResponse,
  GetSiteResponse,
  ListSiteQuery,
  ListSiteResponse,
  UpdateSiteRequest,
  UpdateSiteResponse,
  ExportSitesRequest,
  ExportSitesResponse,
  ResetPasswordResponse,
  CreateUserRequest,
  CreateUserResponse,
  DeleteUserResponse,
  ListUserQuery,
  ListUserResponse,
  ResetPasswordRequest,
  UpdateUserResponse,
  GetUserResponse,
  CreateLicenseRequest,
  CreateLicenseResponse,
  DeleteLicenseResponse,
  GetLicenseResponse,
  ListLicenseQuery,
  ListLicenseResponse,
  UpdateLicenseRequest,
  UpdateLicenseResponse,
  UpdateUserRequest,
  DeleteFileResponse,
  GetFileResponse,
  ListFileQuery,
  UpdateFileRequest,
  UpdateFileResponse,
  ListFileResponse,
  RecheckActivityRequest,
  RecheckActivityResponse,
  SiteActivityRequest,
  SiteActivityResponse,
  CheckActivityRequest,
  CheckActivityResponse,
  ListCheckingSiteLicenseQuery,
  ListExpiringSiteLicenseQuery,
  ListCheckingSiteLicenseResponse,
  ListExpiringSiteLicenseResponse,
  ListSiteWithMultipleLicensesQuery,
  ListSiteWithMultipleLicensesResponse,
  ListSiteWithoutLicenseQuery,
  ListSiteWithoutLicenseResponse,
} from "raml-lib"
import {
  useApiQuery,
  useApiMutation,
  UseApiQueryOptions,
  UseApiMutationOptions,
  useApiContext,
} from "./hooks"
import { createKey } from "."

export const useApiGetCsrfToken = (
  options?: UseApiQueryOptions<CsrfTokenResponse>
) => useApiQuery(["auth", "csrf"], options)

export const useApiAuthMe = (options?: UseApiQueryOptions<MeResponse>) =>
  useApiQuery(["auth", "me"], options)

export const useApiAuthLogin = (
  options?: UseApiMutationOptions<LoginResponse, LoginRequest>
) => {
  const onSuccess = options?.onSuccess
  const queryClient = useQueryClient()
  const { updateMe } = useApiContext()
  return useApiMutation("POST", ["auth", "login"], {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries()
      updateMe()
      if (onSuccess) {
        onSuccess(data, variables, context)
      }
    },
  })
}

export const useApiAuthLogout = (
  options?: UseApiMutationOptions<LogoutResponse, LogoutRequest>
) => {
  const queryClient = useQueryClient()
  const { updateMe } = useApiContext()
  return useApiMutation("POST", ["auth", "logout"], {
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries()
      updateMe()
    },
  })
}

// Api keys

export const useApiGetApiKey = (
  id: Id | undefined,
  options?: UseApiQueryOptions<GetApiKeyResponse>
) => useApiQuery(["apikeys", id], options)

export const useApiListApiKeys = (
  query?: ListApiKeyQuery,
  options?: UseApiQueryOptions<ListApiKeyResponse>
) => useApiQuery(["apikeys", query], options, query)

export const useApiCreateApiKey = (
  options?: UseApiMutationOptions<CreateApiKeyResponse, CreateApiKeyRequest>
) =>
  useApiMutation<CreateApiKeyResponse, CreateApiKeyRequest>(
    "POST",
    ["apikeys"],
    options
  )

export const useApiUpdateApiKey = (
  id: Id | undefined,
  options?: UseApiMutationOptions<UpdateApiKeyResponse, UpdateApiKeyRequest>
) =>
  useApiMutation<UpdateApiKeyResponse, UpdateApiKeyRequest>(
    "PUT",
    ["apikeys", id],
    {
      invalidateKeys: [createKey(["apikeys"])],
      ...options,
    }
  )

export const useApiDeleteApiKey = (
  id: Id | undefined,
  options?: UseApiMutationOptions<DeleteApiKeyResponse, unknown>
) =>
  useApiMutation<DeleteApiKeyResponse, unknown>("DELETE", ["apikeys", id], {
    invalidateKeys: [createKey(["apikeys"])],
    ...options,
  })

export const useApiGenerateApiKeyToken = (
  id: Id | undefined,
  options?: UseApiMutationOptions<ApiKeyTokenResponse, unknown>
) =>
  useApiMutation<ApiKeyTokenResponse, unknown>(
    "POST",
    ["apikeys", id, "token"],
    options
  )

// Sites

export const useApiGetSite = (
  id: Id | undefined,
  options?: UseApiQueryOptions<GetSiteResponse>
) => useApiQuery(["sites", id], options)

export const useApiListSites = (
  query?: ListSiteQuery,
  options?: UseApiQueryOptions<ListSiteResponse>
) => useApiQuery(["sites", query], options, query)

export const useApiCreateSite = (
  options?: UseApiMutationOptions<CreateSiteResponse, CreateSiteRequest>
) =>
  useApiMutation<CreateSiteResponse, CreateSiteRequest>(
    "POST",
    ["sites"],
    options
  )

export const useApiUpdateSite = (
  id: Id | undefined,
  options?: UseApiMutationOptions<UpdateSiteResponse, UpdateSiteRequest>
) =>
  useApiMutation<UpdateSiteResponse, UpdateSiteRequest>("PUT", ["sites", id], {
    invalidateKeys: [createKey(["sites"])],
    ...options,
  })

export const useApiDeleteSite = (
  id: Id | undefined,
  options?: UseApiMutationOptions<DeleteSiteResponse, unknown>
) =>
  useApiMutation<DeleteSiteResponse, unknown>("DELETE", ["sites", id], {
    invalidateKeys: [createKey(["sites"])],
    ...options,
  })

export const useApiExportSites = (
  // query?: ExportSitesQuery,
  options?: UseApiQueryOptions<ExportSitesResponse>
) =>
  useApiMutation<ExportSitesResponse, ExportSitesRequest>(
    "POST",
    ["sites/export"],
    options
  )

// ) => useApiQuery(["sites/export", query], options, query)

// Licenses

export const useApiGetLicense = (
  id: Id | undefined,
  options?: UseApiQueryOptions<GetLicenseResponse>
) => useApiQuery(["licenses", id], options)

export const useApiListLicenses = (
  query?: ListLicenseQuery,
  options?: UseApiQueryOptions<ListLicenseResponse>
) => useApiQuery(["licenses", query], options, query)

export const useApiCreateLicense = (
  options?: UseApiMutationOptions<CreateLicenseResponse, CreateLicenseRequest>
) =>
  useApiMutation<CreateLicenseResponse, CreateLicenseRequest>(
    "POST",
    ["licenses"],
    options
  )

export const useApiUpdateLicense = (
  id: Id | undefined,
  options?: UseApiMutationOptions<UpdateLicenseResponse, UpdateLicenseRequest>
) =>
  useApiMutation<UpdateLicenseResponse, UpdateLicenseRequest>(
    "PUT",
    ["licenses", id],
    {
      invalidateKeys: [createKey(["licenses"])],
      ...options,
    }
  )

export const useApiDeleteLicense = (
  id: Id | undefined,
  options?: UseApiMutationOptions<DeleteLicenseResponse, unknown>
) =>
  useApiMutation<DeleteLicenseResponse, unknown>("DELETE", ["licenses", id], {
    invalidateKeys: [createKey(["licenses"])],
    ...options,
  })

// Files

export const useApiGetFile = (
  id: Id | undefined,
  options?: UseApiQueryOptions<GetFileResponse>
) => useApiQuery(["files", id], options)

export const useApiListFiles = (
  query?: ListFileQuery,
  options?: UseApiQueryOptions<ListFileResponse>
) => useApiQuery(["files", query], options, query)

export const useApiUpdateFile = (
  id: Id | undefined,
  options?: UseApiMutationOptions<UpdateFileResponse, UpdateFileRequest>
) =>
  useApiMutation<UpdateFileResponse, UpdateFileRequest>("PUT", ["files", id], {
    invalidateKeys: [createKey(["files"])],
    ...options,
  })

export const useApiDeleteFile = (
  id: Id | undefined,
  options?: UseApiMutationOptions<DeleteFileResponse, unknown>
) =>
  useApiMutation<DeleteFileResponse, unknown>("DELETE", ["files", id], {
    invalidateKeys: [createKey(["files"])],
    ...options,
  })

// Orders

export const useApiGetOrder = (
  id: Id | undefined,
  options?: UseApiQueryOptions<GetOrderResponse>
) => useApiQuery(["orders", id], options)

export const useApiListOrders = (
  query?: ListOrderQuery,
  options?: UseApiQueryOptions<ListOrderResponse>
) => useApiQuery(["orders", query], options, query)

export const useApiCreateOrder = (
  options?: UseApiMutationOptions<CreateOrderResponse, CreateOrderRequest>
) =>
  useApiMutation<CreateOrderResponse, CreateOrderRequest>(
    "POST",
    ["orders"],
    options
  )

export const useApiUpdateOrder = (
  id: Id | undefined,
  options?: UseApiMutationOptions<UpdateOrderResponse, UpdateOrderRequest>
) =>
  useApiMutation<UpdateOrderResponse, UpdateOrderRequest>(
    "PUT",
    ["orders", id],
    {
      invalidateKeys: [createKey(["orders"])],
      ...options,
    }
  )

export const useApiDeleteOrder = (
  id: Id | undefined,
  options?: UseApiMutationOptions<DeleteOrderResponse, unknown>
) =>
  useApiMutation<DeleteOrderResponse, unknown>("DELETE", ["orders", id], {
    invalidateKeys: [createKey(["orders"])],
    ...options,
  })

export const useApiExportOrders = (
  options?: UseApiQueryOptions<ExportOrdersResponse>
) =>
  useApiMutation<ExportOrdersResponse, ExportOrdersRequest>(
    "POST",
    ["orders/export"],
    options
  )

// Users

export const useApiChangePassword = (
  id: Id | undefined,
  options?: UseApiMutationOptions<ChangePasswordResponse, ChangePasswordRequest>
) =>
  useApiMutation<ChangePasswordResponse, ChangePasswordRequest>(
    "PUT",
    ["users", id, "password"],
    options
  )

export const useApiResetPassword = (
  id: Id | undefined,
  options?: UseApiMutationOptions<ResetPasswordResponse, ResetPasswordRequest>
) =>
  useApiMutation<ResetPasswordResponse, ResetPasswordRequest>(
    "PUT",
    ["users", id, "reset"],
    options
  )

export const useApiGetUser = (
  id: Id | undefined,
  options?: UseApiQueryOptions<GetUserResponse>
) => useApiQuery(["users", id], options)

export const useApiListUsers = (
  query?: ListUserQuery,
  options?: UseApiQueryOptions<ListUserResponse>
) => useApiQuery(["users", query], options, query)

export const useApiCreateUser = (
  options?: UseApiMutationOptions<CreateUserResponse, CreateUserRequest>
) =>
  useApiMutation<CreateUserResponse, CreateUserRequest>(
    "POST",
    ["users"],
    options
  )

export const useApiUpdateUser = (
  id: Id | undefined,
  options?: UseApiMutationOptions<UpdateUserResponse, UpdateUserRequest>
) =>
  useApiMutation<UpdateUserResponse, UpdateUserRequest>("PUT", ["users", id], {
    invalidateKeys: [createKey(["users"])],
    ...options,
  })

export const useApiDeleteUser = (
  id: Id | undefined,
  options?: UseApiMutationOptions<DeleteUserResponse, unknown>
) =>
  useApiMutation<DeleteUserResponse, unknown>("DELETE", ["users", id], {
    invalidateKeys: [createKey(["users"])],
    ...options,
  })

// Activity

export const useApiCheckOrderActivity = (
  options?: UseApiMutationOptions<CheckActivityResponse, CheckActivityRequest>
) =>
  useApiMutation<CheckActivityResponse, CheckActivityRequest>(
    "POST",
    ["activity", "check"],
    options
  )

export const useApiRecheckOrderActivity = (
  options?: UseApiMutationOptions<
    RecheckActivityResponse,
    RecheckActivityRequest
  >
) =>
  useApiMutation<RecheckActivityResponse, RecheckActivityRequest>(
    "POST",
    ["activity", "recheck"],
    options
  )

export const useApiCheckSiteActivity = (
  options?: UseApiMutationOptions<SiteActivityResponse, SiteActivityRequest>
) =>
  useApiMutation<SiteActivityResponse, SiteActivityRequest>(
    "POST",
    ["activity", "site"],
    options
  )

// Reports

export const useApiListCheckingSiteLicense = (
  query?: ListCheckingSiteLicenseQuery,
  options?: UseApiQueryOptions<ListCheckingSiteLicenseResponse>
) =>
  useApiQuery(["reports", "sites", "licenses", "check", query], options, query)

export const useApiListExpiringSiteLicense = (
  query?: ListExpiringSiteLicenseQuery,
  options?: UseApiQueryOptions<ListExpiringSiteLicenseResponse>
) =>
  useApiQuery(["reports", "sites", "licenses", "expiry", query], options, query)

export const useApiListSiteWithoutLicense = (
  query?: ListSiteWithoutLicenseQuery,
  options?: UseApiQueryOptions<ListSiteWithoutLicenseResponse>
) =>
  useApiQuery(["reports", "sites", "licenses", "none", query], options, query)

export const useApiListSiteWithMultipleLicenses = (
  query?: ListSiteWithMultipleLicensesQuery,
  options?: UseApiQueryOptions<ListSiteWithMultipleLicensesResponse>
) =>
  useApiQuery(
    ["reports", "sites", "licenses", "multiple", query],
    options,
    query
  )
