import dayjs from "dayjs"
import {
  now,
  SortOrder,
  toTimestamp,
  LicenseStatus,
  COUNTRIES,
  getCountryNameForCode,
} from "raml-lib"
import { Breadcrumb, Col, Row } from "react-bootstrap"
import { LinkContainer } from "react-router-bootstrap"
import { ExpiringSiteLicenseTable } from "../components/reports/ExpiringSiteLicenseTable"
import Select, { ActionMeta } from "react-select"
import { useState } from "react"

export function ReportExpiringLicensesPage() {
  const [countries, setCountries] = useState<string[] | []>([])
  const passed = now()
  const approaching = toTimestamp(dayjs().add(60, "days").toDate())

  const options = COUNTRIES.map((r) => ({
    value: r,
    label: `${getCountryNameForCode(r)} (${r})` ?? r,
  }))

  const onChange = (
    option: readonly typeof Option[],
    actionMeta: ActionMeta<typeof Option>
  ) => {
    // @ts-ignore
    setCountries(option.map((i) => i.value))
  }

  return (
    <>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item>Reports</Breadcrumb.Item>
        <Breadcrumb.Item active>Expired Licenses</Breadcrumb.Item>
      </Breadcrumb>
      <h1>Expiring and Expired Licenses</h1>
      <p>
        On this page you can view licenses which are approaching or past their
        expiry date, or sites which are using licenses deemed to be in renewal.
      </p>
      <>
        <Row>
          <Col md={8}>
            <></>
          </Col>
          <Col md={4}>
            <Select
              placeholder="Select countries"
              // @ts-ignore
              options={options}
              onChange={onChange}
              isMulti
            />
          </Col>
        </Row>

        <h2>Approaching expiry</h2>
        <ExpiringSiteLicenseTable
          after={passed}
          before={approaching}
          status={LicenseStatus.ACTIVE}
          countries={countries.join(",")}
          order={SortOrder.ASC}
        />

        <h2>Expiry date passed</h2>
        <ExpiringSiteLicenseTable
          before={passed}
          status={LicenseStatus.ACTIVE}
          countries={countries.join(",")}
          order={SortOrder.DESC}
          showLastOrder={true}
        />

        <h2>Timely renewals</h2>
        <ExpiringSiteLicenseTable
          before={passed}
          status={LicenseStatus.TIMELY_RENEWAL}
          countries={countries.join(",")}
          order={SortOrder.DESC}
        />
      </>
    </>
  )
}
