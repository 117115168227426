import { Table } from "react-bootstrap"
import { DEFAULT_TAKE } from "../../constants"
import { Paginator } from "../misc/Paginator"
import { ListExpiringSiteLicenseQuery } from "raml-lib"
import { Timestamp } from "../brand/Timestamp"
import { Link } from "react-router-dom"
import { useState } from "react"
import { AgedTableRow } from "../misc/AgedTableRow"
import { useApiListExpiringSiteLicense } from "../../api"

type ExpiringSiteLicenseTableProps = Partial<ListExpiringSiteLicenseQuery>

export function ExpiringSiteLicenseTable(props: ExpiringSiteLicenseTableProps) {
  const [skip, setSkip] = useState(0)
  const take = props.take ?? DEFAULT_TAKE

  const { data } = useApiListExpiringSiteLicense({
    ...props,
    take,
    skip,
  })

  function lastOrderDate(order: any) {
    return order ? (
      <td>
        <Timestamp value={order.createdAt} />
      </td>
    ) : (
      <td>No orders</td>
    )
  }

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>License Number</th>
            <th>Doing Business As (DBA) name</th>
            <th>Site Number</th>
            <th>Ship-To Customer Site name</th>
            <th>Check at</th>
            <th>Expires at</th>
            {props.showLastOrder ? <th>Last order date</th> : null}
          </tr>
        </thead>
        <tbody>
          {data?.results.map((r) => (
            <AgedTableRow
              key={`${r.site.id}-${r.license.id}`}
              timestamp={r.license.expiresAt ?? 0}
            >
              <td>
                <Link to={`/licenses/${r.license.id}`}>
                  {r.license.licenseReference}
                </Link>
              </td>
              <td>{r.site.customerName}</td>
              <td>
                <Link to={`/sites/${r.site.id}`}>{r.site.siteReference}</Link>
              </td>
              <td>{r.site.siteName}</td>
              <td>
                {r.license.checkAt && <Timestamp value={r.license.checkAt} />}
              </td>
              <td>
                <Timestamp value={r.license.expiresAt} />
              </td>
              {props.showLastOrder ? lastOrderDate(r.order) : null}
            </AgedTableRow>
          ))}
        </tbody>
      </Table>
      <Paginator
        skip={skip}
        take={take}
        total={data?.total}
        setSkip={setSkip}
      />
    </>
  )
}
