import { Table } from "react-bootstrap"
import { Id, ListOrderQuery } from "raml-lib"
import { useState, useEffect, useMemo } from "react"
import { TableRow } from "../misc/TableRow"
import { RelativeTimestamp } from "../brand/RelativeTimestamp"
import { useApiListOrders } from "../../api"
import { Paginator } from "../misc/Paginator"
import { DEFAULT_TAKE } from "../../constants"
import { Timestamp } from "../brand/Timestamp"
import { FormatDatetime } from "../brand/FormatDatetime"
import { Link } from "react-router-dom"
import { ReactNullableUseState } from "../../types"

type OrderTableProps = Partial<ListOrderQuery> & {
  selectedState?: ReactNullableUseState<Id>
}

export function OrderTable(props: OrderTableProps) {
  const { take, selectedState, orderReference, siteId, distributorSiteId } = props
  const [skip, setSkip] = useState(0)
  const query = useMemo(() => {
    return {
      orderReference,
      siteId,
      distributorSiteId,
      take: take ?? DEFAULT_TAKE,
      skip: skip,
    }
  }, [orderReference, siteId, distributorSiteId, skip, take])
  const list = useApiListOrders(query)

  // If the search query  / skip changes, we remove selection
  const setSelectedState = selectedState && selectedState[1]
  useEffect(() => {
    if (setSelectedState) {
      setSelectedState(null)
    }
  }, [query, setSelectedState])

  return (
    <>
      <Table striped={false} bordered={true} hover={true}>
        <thead>
          <tr>
            <th>Reference</th>
            <th>Activity</th>
            <th>Calibrated at</th>
            <th>Shipped at</th>
            <th>Delivery Date at Destination</th>
            <th>Treatment at</th>
            <th>Created</th>
          </tr>
        </thead>
        <tbody>
          {(list.data?.results ?? []).map((r) => (
            <TableRow key={r.id} selector={r.id} selectedState={selectedState}>
              <td>
                <Link to={`/orders/${r.id}`}>{r.orderReference}</Link>
              </td>
              <td>
                {r.activity} {r.activityUnit}
              </td>
              <td>
                <Timestamp value={r.calibratedAt} />
              </td>
              <td>
                <Timestamp value={r.shippedAt} />
              </td>
              <td>
                <FormatDatetime date={r.deliveredDate} time={r.deliveredTime} />
              </td>
              <td>
                <Timestamp value={r.treatmentAt} />
              </td>
              <td>
                <RelativeTimestamp value={r.createdAt} />
              </td>
            </TableRow>
          ))}
        </tbody>
      </Table>
      <Paginator
        skip={skip}
        take={query.take}
        total={list.data?.total}
        setSkip={setSkip}
      />
    </>
  )
}
