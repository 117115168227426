"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CancelActivityResponseSchema = exports.CancelActivityRequestSchema = exports.UpdateActivityResponseSchema = exports.UpdateActivityRequestSchema = exports.AddActivityResponseSchema = exports.AddActivityRequestSchema = exports.SiteActivityResponseSchema = exports.SiteActivityRequestSchema = exports.RecheckActivityResponseSchema = exports.RecheckActivityRequestSchema = exports.CalculateActivityResponseSchema = exports.CheckActivityResponseSchema = exports.InstantaneousCheckResultSchema = exports.CumulativeCheckResultSchema = exports.CheckMessageSchema = exports.CheckWarningSchema = exports.CheckWarning = exports.CheckErrorSchema = exports.CheckError = exports.CalculateActivityRequestSchema = exports.CheckActivityRequestSchema = exports.ValidSchema = void 0;
var typebox_1 = require("@sinclair/typebox");
var orders_1 = require("./orders");
var common_1 = require("../common");
var utils_1 = require("../utils");
var sites_1 = require("./sites");
var _1 = require(".");
var __1 = require("..");
exports.ValidSchema = typebox_1.Type.Boolean({
    title: "Valid",
    description: "Is the order permitted under licenses?",
});
exports.CheckActivityRequestSchema = (0, utils_1.strictObject)({
    siteReference: sites_1.SiteReferenceSchema,
    orderReference: orders_1.OrderReferenceSchema,
    activity: common_1.ActivitySchema,
    activityUnit: common_1.ActivityUnitSchema,
    calibratedDate: orders_1.CalibratedDateSchema,
    calibratedTime: orders_1.CalibratedTimeSchema,
    deliveredDate: orders_1.DeliveredDateSchema,
    deliveredTime: orders_1.DeliveredTimeSchema,
    shippedDate: typebox_1.Type.Optional(orders_1.ShippedDateSchema),
    shippedTime: typebox_1.Type.Optional(orders_1.ShippedTimeSchema),
    treatmentDate: typebox_1.Type.Optional(orders_1.TreatmentDateSchema),
    treatmentTime: typebox_1.Type.Optional(orders_1.TreatmentTimeSchema),
}, {
    title: "Check activity",
    description: "Information required to check is this order's activity will be permitted under license",
});
exports.CalculateActivityRequestSchema = (0, utils_1.strictObject)({
    siteReference: sites_1.SiteReferenceSchema,
    activity: common_1.ActivitySchema,
    calibratedDate: orders_1.CalibratedDateSchema,
    calculationDate: orders_1.CalibratedDateSchema,
    calculationTime: orders_1.CalibratedTimeSchema,
}, {
    title: "Calculate activity",
    description: "Information required to calculate the dosage for a license",
});
var CheckError;
(function (CheckError) {
    CheckError["ORDER_NOT_FOUND"] = "ORDER_NOT_FOUND";
    CheckError["SITE_NOT_FOUND"] = "SITE_NOT_FOUND";
    CheckError["NO_LICENSES"] = "NO_LICENSES";
    CheckError["NO_VALID_LICENSE"] = "NO_VALID_LICENSE";
    CheckError["EXCEEDS_LICENSE"] = "EXCEEDS_LICENSE";
    CheckError["EXCEEDS_LICENSE_AT_DELIVERY"] = "EXCEEDS_LICENSE_AT_DELIVERY";
    CheckError["EXCEEDS_LICENSE_OVER_WEEK"] = "EXCEEDS_LICENSE_OVER_WEEK";
    CheckError["EXCEEDS_LICENSE_THIS_WEEK"] = "EXCEEDS_LICENSE_THIS_WEEK";
    CheckError["EXCEEDS_LICENSE_OVER_MONTH"] = "EXCEEDS_LICENSE_OVER_MONTH";
    CheckError["EXCEEDS_LICENSE_THIS_MONTH"] = "EXCEEDS_LICENSE_THIS_MONTH";
    CheckError["EXCEEDS_LICENSE_OVER_YEAR"] = "EXCEEDS_LICENSE_OVER_YEAR";
    CheckError["EXCEEDS_LICENSE_THIS_YEAR"] = "EXCEEDS_LICENSE_THIS_YEAR";
    CheckError["CHECK_DATE_PASSED"] = "CHECK_DATE_PASSED";
    CheckError["CHECK_DATE_IS_BEFORE_DELIVERY_DATE"] = "CHECK_DATE_IS_BEFORE_DELIVERY_DATE";
    CheckError["EXPIRY_DATE_IS_BEFORE_DELIVERY_DATE"] = "EXPIRY_DATE_IS_BEFORE_DELIVERY_DATE";
})(CheckError = exports.CheckError || (exports.CheckError = {}));
exports.CheckErrorSchema = (0, utils_1.enumSchema)(typebox_1.Type.Enum(CheckError), {
    title: "Check Status",
    description: "Outcome of activity checking",
});
var CheckWarning;
(function (CheckWarning) {
    CheckWarning["NO_LIMIT_CONFIGURED"] = "NO_LIMIT_CONFIGURED";
    CheckWarning["CHECK_DATE_APPROACHING"] = "CHECK_DATE_APPROACHING";
    CheckWarning["CHECK_DATE_PASSED"] = "CHECK_DATE_PASSED";
    CheckWarning["EXPIRY_DATE_APPROACHING"] = "EXPIRY_DATE_APPROACHING";
    CheckWarning["CLOSE_TO_ACTIVITY_LIMIT_AT_DELIVERY"] = "CLOSE_TO_ACTIVITY_LIMIT_AT_DELIVERY";
    CheckWarning["CLOSE_TO_ACTIVITY_LIMIT_FOR_CURRENT_WEEK"] = "CLOSE_TO_ACTIVITY_LIMIT_FOR_CURRENT_WEEK";
    CheckWarning["CLOSE_TO_ACTIVITY_LIMIT_FOR_CURRENT_MONTH"] = "CLOSE_TO_ACTIVITY_LIMIT_FOR_CURRENT_MONTH";
    CheckWarning["CLOSE_TO_ACTIVITY_LIMIT_FOR_CURRENT_YEAR"] = "CLOSE_TO_ACTIVITY_LIMIT_FOR_CURRENT_YEAR";
})(CheckWarning = exports.CheckWarning || (exports.CheckWarning = {}));
exports.CheckWarningSchema = (0, utils_1.enumSchema)(typebox_1.Type.Enum(CheckWarning), {
    title: "Check Warning",
    description: "Warning outcome of activity checking",
});
exports.CheckMessageSchema = typebox_1.Type.String({
    maxLength: 1024,
    title: "Message",
    examples: ["Further information"],
});
exports.CumulativeCheckResultSchema = (0, utils_1.strictObject)({
    valid: exports.ValidSchema,
    activity: common_1.ActivitySchema,
    licensedActivity: typebox_1.Type.Optional(common_1.ActivitySchema),
    from: common_1.TimestampSchema,
    to: common_1.TimestampSchema,
    totalOrders: typebox_1.Type.Number({ title: "Number of relevant orders" }),
    totalDays: typebox_1.Type.Number({ title: "Number of days considered" }),
}, {
    title: "Activity delivered",
    description: "The radioactivity delivered to a site over a time period",
});
exports.InstantaneousCheckResultSchema = (0, utils_1.strictObject)({
    valid: exports.ValidSchema,
    activity: common_1.ActivitySchema,
    licensedActivity: typebox_1.Type.Optional(common_1.ActivitySchema),
    from: common_1.TimestampSchema,
    to: common_1.TimestampSchema,
    totalOrders: typebox_1.Type.Number({ title: "Number of relevant orders" }),
    totalDays: typebox_1.Type.Number({ title: "Number of days considered" }),
    orders: typebox_1.Type.Array((0, utils_1.strictObject)({
        id: __1.IdSchema,
        orderReference: orders_1.OrderReferenceSchema,
    })),
}, {
    title: "Activity at a point in time",
    description: "The radioactivity held at a point in site for a site",
});
exports.CheckActivityResponseSchema = (0, utils_1.strictObject)({
    valid: exports.ValidSchema,
    errors: typebox_1.Type.Array(exports.CheckErrorSchema),
    warnings: typebox_1.Type.Array(exports.CheckWarningSchema),
    messages: typebox_1.Type.Array(exports.CheckMessageSchema),
    calculatedAt: common_1.TimestampSchema,
    license: typebox_1.Type.Optional(_1.LicenseSchema),
    site: typebox_1.Type.Optional(_1.SiteSchema),
    distributorSite: typebox_1.Type.Optional(sites_1.DistributorSiteSchema),
    activityUnit: typebox_1.Type.Optional(common_1.ActivityUnitSchema),
    activityAtDelivery: typebox_1.Type.Optional(common_1.ActivitySchema),
    // At the time of delivery, based on half life etc
    deliveryActivity: typebox_1.Type.Optional(exports.InstantaneousCheckResultSchema),
    // Based on the quantity delivered within the last 7 days (etc)
    weeklyActivity: typebox_1.Type.Optional(exports.CumulativeCheckResultSchema),
    monthlyActivity: typebox_1.Type.Optional(exports.CumulativeCheckResultSchema),
    yearlyActivity: typebox_1.Type.Optional(exports.CumulativeCheckResultSchema),
    // Based on the quantity delivered 'to date' in the current week (etc)
    currentWeekActivity: typebox_1.Type.Optional(exports.CumulativeCheckResultSchema),
    currentMonthActivity: typebox_1.Type.Optional(exports.CumulativeCheckResultSchema),
    currentYearActivity: typebox_1.Type.Optional(exports.CumulativeCheckResultSchema),
}, {
    title: "Check activity",
    description: "Response from checking if an order's activity would be permitted under license",
});
exports.CalculateActivityResponseSchema = (0, utils_1.strictObject)({
    valid: exports.ValidSchema,
    errors: typebox_1.Type.Array(exports.CheckErrorSchema),
    warnings: typebox_1.Type.Array(exports.CheckWarningSchema),
    messages: typebox_1.Type.Array(exports.CheckMessageSchema),
    calculatedAt: typebox_1.Type.Optional(common_1.TimestampSchema),
    result: typebox_1.Type.Optional(common_1.ActivitySchema),
    activityUnit: typebox_1.Type.Optional(common_1.ActivityUnitSchema)
}, {
    title: "Calculate activity",
    description: "Response from checking if an order's activity would be permitted under license",
});
exports.RecheckActivityRequestSchema = (0, utils_1.strictObject)({
    orderReference: orders_1.OrderReferenceSchema,
    siteId: typebox_1.Type.Optional(common_1.StringSchema)
}, {
    title: "Recheck activity",
    description: "Check activity calculations for an existing order",
});
exports.RecheckActivityResponseSchema = exports.CheckActivityResponseSchema;
exports.SiteActivityRequestSchema = (0, utils_1.strictObject)({
    siteReference: sites_1.ActivitySiteReferenceSchema,
    siteDate: common_1.LocalDateSchema,
    siteTime: common_1.LocalTimeSchema,
}, {
    title: "Site activity",
    description: "Check activity at a site at a time",
});
exports.SiteActivityResponseSchema = exports.CheckActivityResponseSchema;
exports.AddActivityRequestSchema = (0, utils_1.strictObject)({
    orderReference: orders_1.OrderReferenceSchema,
    multipleNRTS: typebox_1.Type.Optional(common_1.MultipleNRTSSchema),
    siteReference: sites_1.SiteReferenceSchema,
    distributorSiteReference: typebox_1.Type.Optional(sites_1.SiteReferenceSchema),
    activity: common_1.ActivitySchema,
    activityUnit: common_1.ActivityUnitSchema,
    calibratedDate: orders_1.CalibratedDateSchema,
    calibratedTime: orders_1.CalibratedTimeSchema,
    deliveredDate: orders_1.DeliveredDateSchema,
    deliveredTime: orders_1.DeliveredTimeSchema,
    dockDate: typebox_1.Type.Optional(orders_1.DockDateSchema),
    dockTime: typebox_1.Type.Optional(orders_1.DockTimeSchema),
    shippedDate: orders_1.ShippedDateSchema,
    shippedTime: orders_1.ShippedTimeSchema,
    treatmentDate: orders_1.TreatmentDateSchema,
    treatmentTime: orders_1.TreatmentTimeSchema,
}, {
    title: "Create activity",
    description: "Request  activity",
});
exports.AddActivityResponseSchema = orders_1.OrderSchema;
exports.UpdateActivityRequestSchema = (0, utils_1.strictObject)({
    // Used for matching
    orderReference: orders_1.OrderReferenceSchema,
    // May be updated from original create
    siteReference: sites_1.SiteReferenceSchema,
    activity: common_1.ActivitySchema,
    activityUnit: common_1.ActivityUnitSchema,
    calibratedDate: orders_1.CalibratedDateSchema,
    calibratedTime: orders_1.CalibratedTimeSchema,
    deliveredDate: orders_1.DeliveredDateSchema,
    deliveredTime: orders_1.DeliveredTimeSchema,
    shippedDate: orders_1.ShippedDateSchema,
    shippedTime: orders_1.ShippedTimeSchema,
    treatmentDate: orders_1.TreatmentDateSchema,
    treatmentTime: orders_1.TreatmentTimeSchema,
}, {
    title: "Update activity",
    description: "Inform of an update to an order's activity",
});
exports.UpdateActivityResponseSchema = (0, utils_1.strictObject)({
    updated: typebox_1.Type.Integer({
        minimum: 0,
        title: "Orders updated",
        description: "Total number of orders updated",
        examples: [0],
    }),
}, {
    title: "Update activity",
    description: "Response when updating activity",
});
exports.CancelActivityRequestSchema = (0, utils_1.strictObject)({
    orderReference: orders_1.OrderReferenceSchema,
}, {
    title: "Cancel activity",
    description: "Request an order and its activity are removed from future calculations",
});
exports.CancelActivityResponseSchema = (0, utils_1.strictObject)({
    cancelled: typebox_1.Type.Integer({
        minimum: 0,
        title: "Orders cancelled",
        description: "Total number of orders cancelled",
    }),
}, {
    title: "Cancel activity",
    description: "Response when cancelling activity",
});
