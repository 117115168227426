import { Breadcrumb, Col, Row } from "react-bootstrap"
import { LinkContainer } from "react-router-bootstrap"
import { SiteWithMultipleLicensesTable } from "../components/reports/SiteWithMultipleLicensesTable"
import { SiteWithoutLicenseTable } from "../components/reports/SiteWithoutLicenseTable"
import Select, { ActionMeta } from "react-select"
import { useState } from "react"
import { COUNTRIES, getCountryNameForCode } from "raml-lib"

export function ReportLicenseQuantityPage() {
  const [countries, setCountries] = useState<string[] | []>([])

  const options = COUNTRIES.map((r) => ({
    value: r,
    label: `${getCountryNameForCode(r)} (${r})` ?? r,
  }))

  const onChange = (
    option: readonly typeof Option[],
    actionMeta: ActionMeta<typeof Option>
  ) => {
    // @ts-ignore
    setCountries(option.map((i) => i.value))
  }

  return (
    <>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item>Reports</Breadcrumb.Item>
        <Breadcrumb.Item active> License issues</Breadcrumb.Item>
      </Breadcrumb>
      <h1>License issues</h1>
      <p>
        On this page you can view sites which have incorrect number of licenses:
      </p>
      <ul>
        <li>Sites which no licenses and may not be delivered to.</li>
        <li>
          Sites with more than one valid license which may lead to incorrect
          activity allowance calculations.
        </li>
      </ul>

      <Row>
        <Col md={8}>
          <></>
        </Col>
        <Col md={4}>
          <Select
            placeholder="Select countries"
            // @ts-ignore
            options={options}
            onChange={onChange}
            isMulti
          />
        </Col>
      </Row>

      <h2>Sites with no (valid) licenses</h2>
      <SiteWithoutLicenseTable countries={countries.join(",")} />

      <h2>Sites with more than one valid license</h2>
      <SiteWithMultipleLicensesTable countries={countries.join(",")} />
    </>
  )
}
