"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListSiteWithMultipleLicensesResponseSchema = exports.ListSiteWithMultipleLicensesQuerySchema = exports.ListSiteWithoutLicenseResponseSchema = exports.ListSiteWithoutLicenseQuerySchema = exports.ListCheckingSiteLicenseResponseSchema = exports.ListCheckingSiteLicenseQuerySchema = exports.ListExpiringSiteLicenseResponseSchema = exports.ListExpiringSiteLicenseQuerySchema = exports.SiteLicenseCountSchema = exports.SiteLicenseOrderSchema = exports.SiteLicenseSchema = void 0;
var typebox_1 = require("@sinclair/typebox");
var licenses_1 = require("./licenses");
var sites_1 = require("./sites");
var common_1 = require("../common");
var orders_1 = require("./orders");
var crud_1 = require("../crud");
var utils_1 = require("../utils");
exports.SiteLicenseSchema = (0, utils_1.strictObject)({
    site: sites_1.SiteSchema,
    license: licenses_1.LicenseSchema,
}, {
    title: "Site License",
    description: "A license for a site",
});
exports.SiteLicenseOrderSchema = (0, utils_1.strictObject)({
    site: sites_1.SiteSchema,
    license: licenses_1.LicenseSchema,
    order: typebox_1.Type.Optional(orders_1.OrderSchema),
}, {
    title: "Site License with Orders",
    description: "A license for a site, including site orders",
});
exports.SiteLicenseCountSchema = (0, utils_1.strictObject)({
    site: sites_1.SiteSchema,
    licenseCount: typebox_1.Type.Number({
        title: "Licenses associated with site",
        minimum: 0,
        examples: [0],
    }),
}, {
    title: "Site License Count",
    description: "A license count for a site",
});
exports.ListExpiringSiteLicenseQuerySchema = typebox_1.Type.Intersect([
    crud_1.PaginationQuerySchema,
    typebox_1.Type.Object({
        after: typebox_1.Type.Optional(common_1.TimestampSchema),
        before: typebox_1.Type.Optional(common_1.TimestampSchema),
        status: typebox_1.Type.Optional(licenses_1.LicenseStatusSchema),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        countries: typebox_1.Type.Optional(common_1.StringSchema),
        order: typebox_1.Type.Optional(crud_1.SortOrderSchema),
        showLastOrder: typebox_1.Type.Optional(common_1.EnabledSchema),
    }),
], {
    title: "Query for sites which are expiring",
    description: "Specify the pagination and query to match",
});
exports.ListExpiringSiteLicenseResponseSchema = (0, crud_1.createListResponseSchema)(exports.SiteLicenseOrderSchema, crud_1.NoSortSchema);
exports.ListCheckingSiteLicenseQuerySchema = typebox_1.Type.Intersect([
    crud_1.PaginationQuerySchema,
    typebox_1.Type.Object({
        after: typebox_1.Type.Optional(common_1.TimestampSchema),
        before: typebox_1.Type.Optional(common_1.TimestampSchema),
        status: typebox_1.Type.Optional(licenses_1.LicenseStatusSchema),
        order: typebox_1.Type.Optional(crud_1.SortOrderSchema),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        countries: typebox_1.Type.Optional(common_1.StringSchema),
    }),
], {
    title: "Query for sites which need checking",
    description: "Specify the pagination and query to match",
});
exports.ListCheckingSiteLicenseResponseSchema = (0, crud_1.createListResponseSchema)(exports.SiteLicenseSchema, crud_1.NoSortSchema);
// Report number of valid licenses a site has
exports.ListSiteWithoutLicenseQuerySchema = typebox_1.Type.Intersect([
    crud_1.PaginationQuerySchema,
    typebox_1.Type.Object({
        at: typebox_1.Type.Optional(common_1.TimestampSchema),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        countries: typebox_1.Type.Optional(common_1.StringSchema),
    }),
], {
    title: "Query for sites without licenses",
    description: "Specify the pagination and query to match",
});
exports.ListSiteWithoutLicenseResponseSchema = (0, crud_1.createListResponseSchema)(exports.SiteLicenseCountSchema, crud_1.NoSortSchema);
exports.ListSiteWithMultipleLicensesQuerySchema = typebox_1.Type.Intersect([
    crud_1.PaginationQuerySchema,
    typebox_1.Type.Object({
        at: typebox_1.Type.Optional(common_1.TimestampSchema),
        countries: typebox_1.Type.Optional(common_1.StringSchema),
    }),
], {
    title: "Query for sites with multiple licenses",
    description: "Specify the pagination and query to match",
});
exports.ListSiteWithMultipleLicensesResponseSchema = (0, crud_1.createListResponseSchema)(exports.SiteLicenseCountSchema, crud_1.NoSortSchema);
