import { useEffect, useState } from "react"
import {
  IdParams,
  UpdateLicenseRequest,
  UpdateLicenseRequestSchema,
} from "raml-lib"
import { useApiGetLicense, useApiUpdateLicense } from "../../api"
import { FormDialog, ShowHideProps } from "../forms/FormDialog"
import { TimestampDateWidget } from "../forms/TimestampDateWidget"
import { SiteWidget } from "../forms/SiteWidget"
import { Link } from "react-router-dom"
import { useNotificationContext, Notification } from "../../notifications"
import { AjvError, FormValidation } from "@rjsf/core"
import {
  anyLimitsSet,
  CommonLicenseToDisplayHideElement,
  CommonLicenseToDisableClearEnableElement,
} from "./CreateLicenseDialog"
import { TextWidget } from "../forms/TextWidget"

type Props = ShowHideProps & IdParams

export function UpdateLicenseDialog(props: Props) {
  const { shown, id, superHide } = props
  const [siteCountry, setSiteCountry] = useState<string>("")
  const [warning, setWarning] = useState<string>("")
  const { onNotification } = useNotificationContext()
  const { mutate } = useApiUpdateLicense(id, {
    onSuccess: (result) => {
      onNotification(
        new Notification(
          "License updated",
          (
            <p>
              Updated{" "}
              <Link to={`/licenses/${result.id}`}>
                {result.licenseReference}
              </Link>
            </p>
          )
        )
      )
    },
  })
  const { data, refetch } = useApiGetLicense(id)

  useEffect(() => {
    CommonLicenseToDisplayHideElement(siteCountry === "FR")
  }, [props, siteCountry])

  useEffect(() => {
    if (shown && id) {
      refetch()
    }
  }, [shown, id, refetch])

  if (!data) {
    return null
  }

  const transformErrors = (errors: AjvError[]) => {
    return errors.map((error) => {
      if (error.name === "maxLength" && error.property === ".licenseNotes") {
        error.message = "License Notes: Should be under 500 characters"
        error.stack = "License Notes: Should be under 500 characters"
      }
      return error
    })
  }

  const validate = (
    formData: Partial<UpdateLicenseRequest>,
    errors: FormValidation
  ) => {
    if (formData) {
      // When country code is FR(France) then licenseAuthorisationNumber and licenseAuthorisationExpiresAt are mandatory
      if (siteCountry === "FR") {
        if (!formData.licenseAuthorisationNumber) {
          errors.licenseAuthorisationNumber.addError(
            "License authorisation number is required when site country is France(FR)."
          )
        }
        if (!formData.licenseAuthorisationExpiresAt) {
          errors.licenseAuthorisationExpiresAt.addError(
            "License authorisation expires at is required when site country is France(FR)."
          )
        }
      }
      // When Disable delivery activity limit is not ticked and delivery activity limit is not filled in
      if (
        !formData.deliveryActivityLimitDisabled &&
        !formData.deliveryActivityLimit
      ) {
        errors.deliveryActivityLimit.addError(
          "Delivery Activity Limit is required"
        )
      }
      // When no limits are set, set warning text
      if (!anyLimitsSet(formData)) {
        if (!warning) {
          errors.addError("")
          setWarning(
            "Warning no limits are set. Please click save again to proceed."
          )
        } else setWarning("")
      }
    }
    return errors
  }

  const onFormChange = (fData: Partial<UpdateLicenseRequest>) => {
    CommonLicenseToDisableClearEnableElement()
  }

  const onHide = () => {
    setWarning("")
    if (superHide) superHide()
  }

  return (
    <FormDialog
      schema={UpdateLicenseRequestSchema}
      uiSchema={{
        id: {
          "ui:widget": "hidden",
        },
        updatedAt: {
          "ui:widget": "hidden",
        },
        createdAt: {
          "ui:widget": "hidden",
        },
        expiresAt: {
          "ui:widget": TimestampDateWidget,
        },
        checkAt: {
          "ui:widget": TimestampDateWidget,
        },
        siteId: {
          "ui:widget": SiteWidget,
          "ui:options": {
            updateSiteCountry: setSiteCountry,
          },
        },
        licenseAuthorisationExpiresAt: {
          "ui:widget": TimestampDateWidget,
        },
        licenseNotes: {
          "ui:widget": "textarea",
        },
        isotope: {
          "ui:widget": "hidden",
        },
        deliveryActivityLimit: {
          "ui:widget": TextWidget,
        },
        maximumIndividualDoseActivityAtDeliveryLimit: {
          "ui:widget": TextWidget,
        },
        currentWeekActivityLimit: {
          "ui:widget": TextWidget,
        },
        currentMonthActivityLimit: {
          "ui:widget": TextWidget,
        },
        currentYearActivityLimit: {
          "ui:widget": TextWidget,
        },
      }}
      transformErrors={transformErrors}
      onSubmit={mutate}
      onFormChange={onFormChange}
      data={data}
      validate={validate}
      source="UpdateLicense"
      warning={warning}
      onHide={onHide}
      {...props}
    />
  )
}
