import { Container, Row, Col } from "react-bootstrap"
import { PropsWithChildren } from "react"

export function PlainLayout(props: PropsWithChildren<{}>) {
  return (
    <Container>
      <Row>
        <Col className="pt-5">{props.children}</Col>
      </Row>
    </Container>
  )
}
