import { WidgetProps } from "@rjsf/core"
import { Form } from "react-bootstrap"
import RjsfCoreFileWidget from "@rjsf/core/dist/cjs/components/widgets/FileWidget"

// See https://github.com/rjsf-team/react-jsonschema-form/issues/2095

export function FileWidget(props: WidgetProps) {
  return (
    <>
      <Form.Label className={props.rawErrors?.length > 0 ? "text-danger" : ""}>
        {props.label || props.schema.title}
        {(props.label || props.schema.title) && props.required ? "*" : null}
      </Form.Label>
      <div className="form-control-file">
        <RjsfCoreFileWidget {...props} type="file" />
      </div>
    </>
  )
}
