import { useCallback, useState } from "react"

export function useModal(
  shown: boolean = false
): [boolean, () => void, () => void] {
  const [visible, setVisible] = useState(shown)
  const hide = useCallback(() => setVisible(false), [setVisible])
  const show = useCallback(() => setVisible(true), [setVisible])
  return [visible, hide, show]
}
