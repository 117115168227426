import {
  ActivityUnit,
  CreateLicenseRequest,
  CreateLicenseRequestSchema,
  Id,
} from "raml-lib"
import { useApiCreateLicense } from "../../api"
import { Link } from "react-router-dom"
import { useNotificationContext, Notification } from "../../notifications"
import { FormDialog, ShowHideProps } from "../forms/FormDialog"
import { SiteWidget } from "../forms/SiteWidget"
import { TimestampDateWidget } from "../forms/TimestampDateWidget"
import { AjvError, FormValidation } from "@rjsf/core"
import { useEffect, useState } from "react"
import { TextWidget } from "../forms/TextWidget"

type Props = ShowHideProps & Partial<{ siteId: Id }>

export const CommonLicenseToDisplayHideElement = (display: boolean): void => {
  //License Authorisation Number
  const LicenseAuthorisationNumberElement = document.getElementById(
    "root_licenseAuthorisationNumber"
  )
  if (LicenseAuthorisationNumberElement) {
    const parentElement = LicenseAuthorisationNumberElement.closest(".row")
    if (!display) {
      parentElement?.setAttribute("style", "display:none;")
    } else {
      parentElement?.setAttribute("style", "display:block;")
    }
  }
  //License authorisation expires at
  const LicenseAuthorisationExpiresAtElement = document.getElementById(
    "root_licenseAuthorisationExpiresAt"
  )
  if (LicenseAuthorisationExpiresAtElement) {
    const parentElement = LicenseAuthorisationExpiresAtElement.closest(".row")
    if (!display) {
      parentElement?.setAttribute("style", "display:none;")
    } else {
      parentElement?.setAttribute("style", "display:block;")
    }
  }
}

export const anyLimitsSet = (formData: Partial<CreateLicenseRequest>) => {
  return (
    !!formData.deliveryActivityLimit ||
    !!formData.maximumIndividualDoseActivityAtDeliveryLimit ||
    // !!formData.weeklyActivityLimit ||
    // !!formData.monthlyActivityLimit ||
    // !!formData.yearlyActivityLimit ||
    !!formData.currentWeekActivityLimit ||
    !!formData.currentMonthActivityLimit ||
    !!formData.currentYearActivityLimit
  )
}

export const CommonLicenseToDisableClearEnableElement = () => {
  // Delivery Activity Limit
  const DeliveryActivityLimitElement = document.getElementById(
    "root_deliveryActivityLimit"
  ) as HTMLInputElement
  if (DeliveryActivityLimitElement) {
    const DeliveryActivityLimitDiabledElement = document.getElementById(
      "root_deliveryActivityLimitDisabled"
    ) as HTMLInputElement
    if (DeliveryActivityLimitDiabledElement.checked) {
      DeliveryActivityLimitElement.value = ""
      DeliveryActivityLimitElement?.setAttribute("disabled", "")
    } else {
      DeliveryActivityLimitElement?.removeAttribute("disabled")
    }
  }
}

export function CreateLicenseDialog(props: Props) {
  const { siteId, ...other } = props
  const [siteCountry, setSiteCountry] = useState<string>("")
  const [warning, setWarning] = useState<string>("")
  const { onNotification } = useNotificationContext()
  const { mutate } = useApiCreateLicense({
    onSuccess: (data) => {
      onNotification(
        new Notification(
          "License created",
          (
            <p>
              Created{" "}
              <Link to={`/licenses/${data.id}`}>{data.licenseReference}</Link>
            </p>
          )
        )
      )
    },
  })

  useEffect(() => {
    CommonLicenseToDisplayHideElement(siteCountry === "FR")
  }, [props, siteCountry])

  const transformErrors = (errors: AjvError[]) => {
    return errors.map((error) => {
      if (error.name === "maxLength" && error.property === ".licenseNotes") {
        error.message = "License Notes: Should be under 500 characters"
        error.stack = "License Notes: Should be under 500 characters"
      }
      return error
    })
  }

  const validate = (
    formData: Partial<CreateLicenseRequest>,
    errors: FormValidation
  ) => {
    if (formData) {
      //When country code is FR(France) then licenseAuthorisationNumber and licenseAuthorisationExpiresAt are mandatory
      if (siteCountry === "FR") {
        if (!formData.licenseAuthorisationNumber) {
          errors.licenseAuthorisationNumber.addError(
            "License authorisation number is required when site country is France(FR)."
          )
        }
        if (!formData.licenseAuthorisationExpiresAt) {
          errors.licenseAuthorisationExpiresAt.addError(
            "License authorisation expires at is required when site country is France(FR)."
          )
        }
      }
      // When Disable delivery activity limit is not ticked and delivery activity limit is not filled in
      if (
        !formData.deliveryActivityLimitDisabled &&
        !formData.deliveryActivityLimit
      ) {
        errors.deliveryActivityLimit.addError(
          "Delivery Activity Limit is required"
        )
      }
      // When no limits are set, set warning text
      if (!anyLimitsSet(formData)) {
        if (!warning) {
          errors.addError("")
          setWarning(
            "Warning no limits are set. Please click save again to proceed."
          )
        } else setWarning("")
      }
    }
    return errors
  }

  const onFormChange = (fData: Partial<CreateLicenseRequest>) => {
    CommonLicenseToDisableClearEnableElement()
  }

  const onHide = () => {
    setWarning("")
    if (other.superHide) other.superHide()
  }

  return (
    <FormDialog
      schema={CreateLicenseRequestSchema}
      uiSchema={{
        siteId: {
          "ui:widget": SiteWidget,
          "ui:options": {
            updateSiteCountry: setSiteCountry,
          },
        },
        licenseAuthorisationExpiresAt: {
          "ui:widget": TimestampDateWidget,
        },
        expiresAt: {
          "ui:widget": TimestampDateWidget,
        },
        checkAt: {
          "ui:widget": TimestampDateWidget,
        },
        licenseNotes: {
          "ui:widget": "textarea",
        },
        isotope: {
          "ui:widget": "hidden",
        },
        deliveryActivityLimit: {
          "ui:widget": TextWidget,
        },
        maximumIndividualDoseActivityAtDeliveryLimit: {
          "ui:widget": TextWidget,
        },
        currentWeekActivityLimit: {
          "ui:widget": TextWidget,
        },
        currentMonthActivityLimit: {
          "ui:widget": TextWidget,
        },
        currentYearActivityLimit: {
          "ui:widget": TextWidget,
        },
      }}
      transformErrors={transformErrors}
      onSubmit={mutate}
      onFormChange={onFormChange}
      data={{ siteId, activityUnit: ActivityUnit.GBQ, isotope: "Y-90T" }}
      validate={validate}
      source="CreateLicense"
      warning={warning}
      onHide={onHide}
      {...other}
    />
  )
}
