import { useCallback } from "react"
import { FormDialog, ShowHideProps } from "../forms/FormDialog"
import {
  NameSchema,
  IdSchema,
  FileTypeSchema,
  DescriptionSchema,
  Id,
  UploadFileResponse,
} from "raml-lib"
import { Type, Static } from "@sinclair/typebox"
import { FileWidget } from "../forms/FileWidget"
import { useApiMutation, UseApiMutationOptions } from "../../api"
import {
  Notification,
  ErrorNotification,
  useNotificationContext,
} from "../../notifications"
import parseDataUrl from "parse-data-url"
import { Link } from "react-router-dom"

export const UploadFileRequestFormSchema = Type.Object(
  {
    name: NameSchema,
    description: DescriptionSchema,
    licenseId: IdSchema,
    type: FileTypeSchema,
    file: Type.String({
      format: "data-url",
      title: "File",
      description: "The file to upload",
    }),
  },
  { title: "Upload file", description: "Upload a new file" }
)
export type UploadFileRequestForm = Static<typeof UploadFileRequestFormSchema>

export const useApiUploadFile = (
  options?: UseApiMutationOptions<UploadFileResponse, FormData>
) =>
  useApiMutation<UploadFileResponse, FormData>("POST", ["files"], {
    ...options,
    contentType: "multipart/form-data",
  })

type Props = ShowHideProps & {
  licenseId: Id
}

function convertDataUriToFile(dataUrl: string): File | null {
  const parsed = parseDataUrl(dataUrl)
  if (!parsed) {
    return null
  }
  // @types are wrong, parseDataUrl adds all the attributes to the object (in our case the filename as name)
  const filename = (parsed as { name?: string }).name ?? "download"
  const mimeType = parsed.contentType
  const buffer = parsed.toBuffer()
  return new File([buffer], filename, { type: mimeType })
}

export function UploadFileDialog(props: Props) {
  const { onErrorNotification, onNotification } = useNotificationContext()
  const { mutate } = useApiUploadFile({
    onSuccess: (data) => {
      onNotification(
        new Notification(
          "File uploaded",
          (
            <p>
              Uploaded new file {data.filename} for{" "}
              <Link to={`/licenses/${data.licenseId}`}>licence</Link>
            </p>
          )
        )
      )
    },
  })
  const handleSubmit = useCallback(
    (data: UploadFileRequestForm) => {
      const formData = new FormData()
      const file = convertDataUriToFile(data.file)
      if (file == null) {
        onErrorNotification(
          new ErrorNotification(
            "Upload error",
            "Unable to process file ready for upload"
          )
        )
        return
      }
      formData.append("file", file)
      formData.append("description", data.description)
      formData.append("name", data.name)
      formData.append("licenseId", data.licenseId.toString())
      formData.append("type", data.type)

      mutate(formData)
    },
    [mutate, onErrorNotification]
  )

  return (
    <FormDialog
      data={{ licenseId: props.licenseId }}
      schema={UploadFileRequestFormSchema}
      uiSchema={{
        licenseId: {
          "ui:widget": "hidden",
        },
        file: {
          "ui:widget": FileWidget,
        },
      }}
      onSubmit={handleSubmit}
      {...props}
    />
  )
}
