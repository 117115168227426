import dayjs from "dayjs"

function ageToColor(value: number, age = 30) {
  const now = dayjs()
  const ts = dayjs.unix(value)
  if (ts.isBefore(now)) {
    return "red"
  }

  const daysBefore = now.diff(ts, "days")

  if (daysBefore > age) {
    return "green"
  } else {
    return "orange"
  }
}

export function AgedTableRow({
  timestamp,
  age = 30,
  children,
}: {
  timestamp: number
  age?: number
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLTableRowElement>,
  HTMLTableRowElement
>) {
  const colour = ageToColor(timestamp, age)
  return <tr className={`table-${colour}`}>{children}</tr>
}
