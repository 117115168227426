import { CreateApiKeyRequestSchema, Role } from "raml-lib"
import { useApiCreateApiKey } from "../../api"
import { Notification, useNotificationContext } from "../../notifications"
import { FormDialog, ShowHideProps } from "../forms/FormDialog"

type CreateApiKeyProps = ShowHideProps

export function CreateApiKeyDialog(props: CreateApiKeyProps) {
  const { onNotification } = useNotificationContext()
  const { mutate } = useApiCreateApiKey({
    onSuccess: (data) => {
      onNotification(
        new Notification(
          "API Key created",
          <p>Created new API Key {data.name}</p>
        )
      )
    },
  })

  return (
    <FormDialog
      schema={CreateApiKeyRequestSchema}
      onSubmit={mutate}
      uiSchema={{
        enabled: {
          "ui:widget": "radio",
        },
      }}
      data={{ enabled: true, role: Role.READER }}
      {...props}
    />
  )
}
