import React, { useState, useMemo, useCallback, useContext } from "react"
import { ErrorNotification, Notification } from "./types"

export interface NotificationContextData {
  notifications: Notification[]
  dismissNotification(notification: Notification): void
  dismissAllNotifications(notification: Notification): void

  onErrorNotification(error: ErrorNotification): void
  onNotification(notification: Notification): void
}

const DEFAULT: NotificationContextData = {
  notifications: [],
  dismissNotification: () => {
    // Do nothing
  },
  dismissAllNotifications: () => {
    // Do nothing
  },
  onErrorNotification: () => {
    // Do nothing
  },
  onNotification: () => {
    // Do nothing,
  },
}

export const NotificationContext =
  React.createContext<NotificationContextData>(DEFAULT)

type NotificationContextProps = React.PropsWithChildren<{
  notifications?: Notification[]
}>

export function NotificationProvider(props: NotificationContextProps) {
  const [notifications, setNotifications] = useState<Notification[]>(
    props.notifications || DEFAULT.notifications
  )

  const dismissNotification = useCallback(
    (notification: Notification) => {
      setNotifications((ns) => ns.filter((n) => n !== notification))
    },
    [setNotifications]
  )

  const onNotification = useCallback(
    (notification: Notification) => {
      setNotifications((ns) => {
        return [...ns, notification]
      })
    },
    [setNotifications]
  )

  const dismissAllNotifications = useCallback(() => {
    setNotifications([])
  }, [setNotifications])

  const onErrorNotification = useCallback(
    (e: ErrorNotification) => {
      onNotification(e.toNotification())
    },
    [onNotification]
  )

  const data = useMemo(
    () => ({
      notifications,
      dismissNotification,
      onNotification,
      onErrorNotification,
      dismissAllNotifications,
    }),
    [
      notifications,
      dismissNotification,
      onNotification,
      onErrorNotification,
      dismissAllNotifications,
    ]
  )

  return (
    <NotificationContext.Provider value={data}>
      {props.children}
    </NotificationContext.Provider>
  )
}

export function useNotificationContext(): NotificationContextData {
  return useContext(NotificationContext)
}
