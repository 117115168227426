import { Breadcrumb } from "react-bootstrap"
import { LinkContainer } from "react-router-bootstrap"
import { Id } from "raml-lib"
import { useState } from "react"
import { useModal } from "../hooks/useModal"
import { CreateLicenseDialog } from "../components/licenses/CreateLicenseDialog"
import { UpdateLicenseDialog } from "../components/licenses/UpdateLicenseDialog"
import { PrimaryButton } from "../components/brand/PrimaryButton"
import { SecondaryButton } from "../components/brand/SecondaryButton"
import { ToolBar } from "../components/misc/ToolBar"
import { SearchBar } from "../components/misc/SearchBar"
import { LicenseTable } from "../components/licenses/LicenseTable"
import { EditorOnly } from "../components/auth/EditorOnly"

export function LicensesPage() {
  const selectedState = useState<Id | null>(null)
  const selected = selectedState[0]

  const [createModal, hideCreateModal, showCreateModal] = useModal()
  const [editModal, hideEditModal, showEditModal] = useModal()

  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined)

  return (
    <>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </LinkContainer>
        <LinkContainer to="/licenses">
          <Breadcrumb.Item active>Licenses</Breadcrumb.Item>
        </LinkContainer>
      </Breadcrumb>
      <h1>Licenses</h1>
      <p>
        Each customer site has a <i>Radioactive Material License</i> that
        defines the material they can hold.
      </p>
      <SearchBar
        placeholder="Search by license number"
        onQuery={setSearchQuery}
      />
      <EditorOnly>
        <ToolBar>
          <PrimaryButton onClick={showCreateModal} className="me-2">
            New
          </PrimaryButton>
          {selected && (
            <SecondaryButton onClick={showEditModal} className="me-2">
              Edit
            </SecondaryButton>
          )}
        </ToolBar>
      </EditorOnly>
      <CreateLicenseDialog shown={createModal} superHide={hideCreateModal} />
      {selected && (
        <UpdateLicenseDialog
          id={selected}
          shown={editModal}
          superHide={hideEditModal}
        />
      )}

      <LicenseTable
        selectedState={selectedState}
        licenseReference={searchQuery}
      />
    </>
  )
}
