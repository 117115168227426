import { Breadcrumb } from "react-bootstrap"
import { LinkContainer } from "react-router-bootstrap"
import { Id } from "raml-lib"
import { useState } from "react"
import { EditorOnly } from "../components/auth/EditorOnly"
import { useModal } from "../hooks/useModal"
import { CreateOrderDialog } from "../components/orders/CreateOrderDialog"
import { UpdateOrderDialog } from "../components/orders/UpdateOrderDialog"
import { ExportOrdersButton } from "../components/orders/ExportOrdersButton"
import { PrimaryButton } from "../components/brand/PrimaryButton"
import { SecondaryButton } from "../components/brand/SecondaryButton"
import { ToolBar } from "../components/misc/ToolBar"
import { SearchBar } from "../components/misc/SearchBar"
import { OrderTable } from "../components/orders/OrderTable"

export function OrdersPage() {
  const selectedState = useState<Id | null>(null)
  const selected = selectedState[0]

  const [createModal, hideCreateModal, showCreateModal] = useModal()
  const [editModal, hideEditModal, showEditModal] = useModal()
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined)

  return (
    <>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </LinkContainer>
        <LinkContainer to="/orders">
          <Breadcrumb.Item active>Orders</Breadcrumb.Item>
        </LinkContainer>
      </Breadcrumb>
      <h1>Orders</h1>
      <p>
        All orders which have been accepted by the customer are held in RAML.
      </p>
      <SearchBar
        placeholder="Search by order reference"
        onQuery={setSearchQuery}
      />
      <EditorOnly>
        <ToolBar>
          <PrimaryButton onClick={showCreateModal} className="me-2">
            New
          </PrimaryButton>
          {selected && (
            <SecondaryButton onClick={showEditModal} className="me-2">
              Edit
            </SecondaryButton>
          )}
          <ExportOrdersButton />
        </ToolBar>
      </EditorOnly>

      <CreateOrderDialog shown={createModal} onHide={hideCreateModal} />
      {selected && (
        <UpdateOrderDialog
          id={selected}
          shown={editModal}
          onHide={hideEditModal}
        />
      )}
      <OrderTable selectedState={selectedState} orderReference={searchQuery} />
    </>
  )
}
