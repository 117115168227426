import { Breadcrumb, Col, Row, ListGroup } from "react-bootstrap"
import { LinkContainer } from "react-router-bootstrap"
import { Link } from "react-router-dom"
import { AdminOnly } from "../components/auth/AdminOnly"

export function HomePage() {
  return (
    <>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item active>Home</Breadcrumb.Item>
        </LinkContainer>
      </Breadcrumb>
      <h1>
        <b>RAML</b>: Radioactive Material Licenses
      </h1>
      <p>
        The <i>Radioactive Material License (RAML)</i> platform stores customer
        radioactive material licenses, tracks radioactive doses sent to their
        sites, and calculates the activity levels at the site on a particular
        date. RAML is integrated into TOP, so orders are checked automatically
        as they are created.
      </p>
      <p>You can also use RAML to view customer licenses or previous orders.</p>

      <Row>
        <Col>
          <ListGroup>
            <ListGroup.Item>
              <Link to="/sites">Sites</Link>
            </ListGroup.Item>
            <ListGroup.Item>
              <Link to="/orders">Orders</Link>
            </ListGroup.Item>
            <ListGroup.Item>
              <Link to="/licenses">Licenses</Link>
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col>
          <ListGroup>
            <ListGroup.Item>
              <Link to="/reports/expiring">Expiring licenses</Link>
            </ListGroup.Item>
            <ListGroup.Item>
              <Link to="/reports/check">Licenses to check</Link>
            </ListGroup.Item>
            <ListGroup.Item>
              <Link to="/reports/issues">License issues</Link>
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <AdminOnly>
          <Col>
            <ListGroup>
              <ListGroup.Item>
                <Link to="/users">Users</Link>
              </ListGroup.Item>
              <ListGroup.Item>
                <Link to="/apikeys">API Keys</Link>
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </AdminOnly>
      </Row>
    </>
  )
}
