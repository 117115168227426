import { useEffect, useState } from "react"
import {
  CreateOrderRequest,
  IdParams,
  UpdateOrderRequestSchema,
} from "raml-lib"
import { useApiGetOrder, useApiUpdateOrder } from "../../api/raml"
import { FormDialog, ShowHideProps } from "../forms/FormDialog"
import { SiteWidget } from "../forms/SiteWidget"
import { LocalTimeWidget } from "../forms/LocalTimeWidget"
import { Link } from "react-router-dom"
import { useNotificationContext, Notification } from "../../notifications"
import { LocalDateWidget } from "../forms/LocalDateWidget"
import { FormValidation } from "@rjsf/core"
import { CommonToDisplayHideElement } from "./CreateOrderDialog"
import { TextWidget } from "../forms/TextWidget"
type Props = ShowHideProps & IdParams

export function UpdateOrderDialog(props: Props) {
  const { shown, id } = props
  const [customOrderRefError, setCustomOrderRefError] = useState<string>("")
  const { onNotification } = useNotificationContext()
  const [displayDistributorSiteId, setDisplayDistributorSiteId] =
    useState<boolean>(false)
  const { mutate } = useApiUpdateOrder(id, {
    onSuccess: (result) => {
      onNotification(
        new Notification(
          "Order updated",
          (
            <p>
              Updated{" "}
              <Link to={`/orders/${result.id}`}>{result.orderReference}</Link>
            </p>
          )
        )
      )
    },
  })

  const { data, refetch } = useApiGetOrder(id, {})
  const {
    calibratedAt,
    treatmentAt,
    shippedAt,
    deliveredAt,
    dockAt,
    ...formData
  } = data || {}

  useEffect(() => {
    if (shown && id) {
      refetch()
    }
  }, [shown, id, refetch])

  const updatedMultipleNRTS = (formData: Partial<CreateOrderRequest>) => {
    setDisplayDistributorSiteId(!!formData.multipleNRTS)
  }

  useEffect(() => {
    CommonToDisplayHideElement(displayDistributorSiteId)
  }, [props, displayDistributorSiteId])

  if (!data) {
    return null
  }

  const validate = (
    formData: Partial<CreateOrderRequest>,
    errors: FormValidation
  ) => {
    if (formData.multipleNRTS && !formData.distributorSiteId) {
      errors.distributorSiteId.addError(
        "Distributor site number is required when multiple NR-TS checkbox is checked."
      )
    }
    if (formData.multipleNRTS && !formData.dockDate) {
      errors.dockDate.addError(
        "Dock date is required when multiple NR-TS checkbox is checked."
      )
    }
    if (formData.multipleNRTS && !formData.dockTime) {
      errors.dockTime.addError(
        "Dock time is required when multiple NR-TS checkbox is checked."
      )
    }
    if (customOrderRefError) {
      errors.orderReference.addError(customOrderRefError)
    }
    return errors
  }

  return (
    <FormDialog
      schema={UpdateOrderRequestSchema}
      uiSchema={{
        multipleNRTS: {
          "ui:widget": "checkbox",
        },
        siteId: {
          "ui:widget": SiteWidget,
          "ui:options": { display: true },
        },
        distributorSiteId: {
          "ui:widget": SiteWidget,
        },
        calibratedDate: {
          "ui:widget": LocalDateWidget,
        },
        shippedDate: {
          "ui:widget": LocalDateWidget,
        },
        dockDate: {
          "ui:widget": LocalDateWidget,
        },
        deliveredDate: {
          "ui:widget": LocalDateWidget,
        },
        treatmentDate: {
          "ui:widget": LocalDateWidget,
        },
        calibratedTime: {
          "ui:widget": LocalTimeWidget,
        },
        shippedTime: {
          "ui:widget": LocalTimeWidget,
        },
        dockTime: {
          "ui:widget": LocalTimeWidget,
        },
        deliveredTime: {
          "ui:widget": LocalTimeWidget,
        },
        treatmentTime: {
          "ui:widget": LocalTimeWidget,
        },
        id: {
          "ui:widget": "hidden",
        },
        updatedAt: {
          "ui:widget": "hidden",
        },
        createdAt: {
          "ui:widget": "hidden",
        },
        activity: {
          "ui:widget": TextWidget,
        },
      }}
      onSubmit={mutate}
      data={formData}
      updatedMultipleNRTS={updatedMultipleNRTS}
      validate={validate}
      source="UpdateOrder"
      {...props}
    />
  )
}
