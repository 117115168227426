import { Route, Redirect, RouteProps, useHistory } from "react-router-dom"
import { useAuthenticated } from "../../api"
import { useCallback } from "react"

export function UnauthenticatedRoute({ children, ...rest }: RouteProps) {
  const authenticated = useAuthenticated()
  const history = useHistory<{ next?: string }>()
  const next = history.location.state?.next

  const render = useCallback(
    () => (!authenticated ? children : <Redirect to={next ?? "/"} />),
    [authenticated, children, next]
  )

  return <Route {...rest} render={render} />
}
