"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.replyWithExport = exports.ExportResponseSchema = exports.replyWithDeleted = exports.DeleteResponseSchema = exports.replyWithList = exports.createListResponseSchema = exports.NoSortSchema = exports.NoSort = exports.PaginationResponseSchema = exports.createSortedPaginationQuerySchema = exports.SortOrderSchema = exports.SortOrder = exports.PaginationQuerySchema = exports.TotalSchema = exports.SkipSchema = exports.TakeSchema = void 0;
var typebox_1 = require("@sinclair/typebox");
var utils_1 = require("./utils");
exports.TakeSchema = typebox_1.Type.Integer({
    default: 50,
    minimum: 0,
    maximum: 1000,
    title: "Maximum number of results to return",
    description: "Use take=0 to return only the count",
    examples: [0],
});
exports.SkipSchema = typebox_1.Type.Integer({
    default: 0,
    minimum: 0,
    title: "Offset in the results to jump to",
    examples: [0],
});
exports.TotalSchema = typebox_1.Type.Integer({
    minimum: 0,
    title: "Total matching results",
    description: "Total number of results which match the query (but not limited by pagination)",
    examples: [14],
});
exports.PaginationQuerySchema = typebox_1.Type.Object({
    take: exports.TakeSchema,
    skip: exports.SkipSchema,
}, {
    title: "Pagination",
    description: "Defines the page of results to return using number of items to skip (page*PAGE_SIZE) and the number to take (PAGE_SIZE)",
});
var SortOrder;
(function (SortOrder) {
    SortOrder["ASC"] = "asc";
    SortOrder["DESC"] = "desc";
})(SortOrder = exports.SortOrder || (exports.SortOrder = {}));
exports.SortOrderSchema = (0, utils_1.enumSchema)(typebox_1.Type.Enum(SortOrder), {
    default: SortOrder.ASC,
    title: "Sort Order",
    description: "Sort by ascending or descending values",
    examples: [SortOrder.ASC],
});
/**
 * Create a query schema for pagination
 *
 * @param sortEnumSchema
 * @param defaultValue
 * @returns
 */
// Disabled as its a complex return type based and based on the sortEnum
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function createSortedPaginationQuerySchema(sortEnumSchema, defaultValue) {
    if (sortEnumSchema === void 0) { sortEnumSchema = exports.NoSortSchema; }
    return typebox_1.Type.Intersect([
        exports.PaginationQuerySchema,
        typebox_1.Type.Object({
            sort: (0, utils_1.enumSchema)(sortEnumSchema, {
                default: defaultValue,
                title: "Sort field",
            }),
            order: exports.SortOrderSchema,
        }),
    ], {
        title: "Sorted pagination",
        description: "Defines the pagination, sort field and the order to use in a query",
    });
}
exports.createSortedPaginationQuerySchema = createSortedPaginationQuerySchema;
exports.PaginationResponseSchema = (0, utils_1.strictObject)({
    take: exports.TakeSchema,
    skip: exports.SkipSchema,
}, {
    title: "Pagination",
    description: "Describes the pagination used when generating the results",
});
/**
 * The options for not sorting.
 *
 * In cases where don't support sorting, use is the enum to use.
 *
 */
var NoSort;
(function (NoSort) {
    // We need at least one thing in an array for openapi!
    NoSort["NONE"] = "none";
})(NoSort = exports.NoSort || (exports.NoSort = {}));
exports.NoSortSchema = typebox_1.Type.Enum(NoSort);
/**
 * Helper function to create a schema response type for List Response
 *
 * @param item
 * @param SortEnum
 * @returns
 */
// This is ignored because its a complex generated return type which isn't easy to extract to copy and paste
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function createListResponseSchema(item, SortEnum) {
    if (SortEnum === void 0) { SortEnum = exports.NoSortSchema; }
    return typebox_1.Type.Intersect([
        exports.PaginationResponseSchema,
        (0, utils_1.strictObject)({
            sort: typebox_1.Type.Optional((0, utils_1.enumSchema)(SortEnum, { title: "Sort field" })),
            order: typebox_1.Type.Optional(exports.SortOrderSchema),
            total: exports.TotalSchema,
            results: typebox_1.Type.Array(item, {
                title: "Results",
                description: "Results which match the query and pagination",
            }),
        }),
    ], {});
}
exports.createListResponseSchema = createListResponseSchema;
/**
 * Helper function to construct a list response
 *
 * @param total
 * @param results
 * @param pagination
 * @returns
 */
function replyWithList(total, results, pagination) {
    var _a, _b;
    return {
        total: total,
        results: results,
        skip: (_a = pagination.skip) !== null && _a !== void 0 ? _a : 0,
        take: (_b = pagination.take) !== null && _b !== void 0 ? _b : 0,
        sort: pagination.sort,
        order: pagination.order,
    };
}
exports.replyWithList = replyWithList;
exports.DeleteResponseSchema = (0, utils_1.strictObject)({
    deleted: typebox_1.Type.Integer({
        title: "Number of deleted",
        description: "One or more if the item are actually deleted, 0 if nothing was deleted (not found or for another reason)",
        minimum: 0,
        examples: [0],
    }),
});
/**
 * Helper function to construct a deleted response
 *
 * @param count
 * @returns
 */
function replyWithDeleted(count) {
    return {
        deleted: count,
    };
}
exports.replyWithDeleted = replyWithDeleted;
exports.ExportResponseSchema = (0, utils_1.strictObject)({
    message: typebox_1.Type.String({
        title: "Exported",
        description: "Exported the CSV to your email",
    }),
});
function replyWithExport(message) {
    return {
        message: message,
    };
}
exports.replyWithExport = replyWithExport;
