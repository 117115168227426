import { Breadcrumb, Col, Row } from "react-bootstrap"
import { LinkContainer } from "react-router-bootstrap"
import {
  LicenseStatus,
  SortOrder,
  now,
  toTimestamp,
  COUNTRIES,
  getCountryNameForCode,
} from "raml-lib"
import dayjs from "dayjs"
import { CheckingSiteLicenseTable } from "../components/reports/CheckingSiteLicenseTable"
import Select, { ActionMeta } from "react-select"
import { useState } from "react"

export function ReportCheckLicensePage() {
  const [countries, setCountries] = useState<string[] | []>([])
  const passed = now()
  const approaching = toTimestamp(dayjs().add(60, "days").toDate())
  const timelyRenewalsApproaching = toTimestamp(
    dayjs().add(60, "days").toDate()
  )

  const options = COUNTRIES.map((r) => ({
    value: r,
    label: `${getCountryNameForCode(r)} (${r})` ?? r,
  }))

  const onChange = (
    option: readonly typeof Option[],
    actionMeta: ActionMeta<typeof Option>
  ) => {
    // @ts-ignore
    setCountries(option.map((i) => i.value))
  }

  return (
    <>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item>Reports</Breadcrumb.Item>
        <Breadcrumb.Item active>Check Licenses</Breadcrumb.Item>
      </Breadcrumb>
      <h1>Check Licenses</h1>
      <p>
        On this page you can view licenses which are approaching or past their
        check date.
      </p>

      <Row>
        <Col md={8}>
          <></>
        </Col>
        <Col md={4}>
          <Select
            placeholder="Select countries"
            // @ts-ignore
            options={options}
            onChange={onChange}
            isMulti
          />
        </Col>
      </Row>

      <h2>Approaching check</h2>
      <CheckingSiteLicenseTable
        after={passed}
        before={approaching}
        status={LicenseStatus.ACTIVE}
        countries={countries.join(",")}
        order={SortOrder.ASC}
      />

      <h2>Check date passed</h2>
      <CheckingSiteLicenseTable
        before={passed}
        status={LicenseStatus.ACTIVE}
        countries={countries.join(",")}
        order={SortOrder.DESC}
      />

      <h2>Timely renewals approaching check</h2>
      <CheckingSiteLicenseTable
        after={passed}
        before={timelyRenewalsApproaching}
        status={LicenseStatus.TIMELY_RENEWAL}
        order={SortOrder.ASC}
        countries={countries.join(",")}
      />

      <h2>Timely renewals check date passed</h2>
      <CheckingSiteLicenseTable
        before={passed}
        status={LicenseStatus.TIMELY_RENEWAL}
        order={SortOrder.DESC}
        countries={countries.join(",")}
      />
    </>
  )
}
