import { useCallback, Dispatch, SetStateAction } from "react"

export function TableRow<S>({
  selector,
  selectedState,
  children,
  onClick,
  ...others
}: {
  // Selector is the value in selectedState. It would normally be called id, but that's a HTML attribute already
  selector: S
  selectedState?: [S | null, Dispatch<SetStateAction<S | null>>] // Return of useState<id>
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLTableRowElement>,
  HTMLTableRowElement
>) {
  const handleOnClick = useCallback(
    (evt) => {
      if (onClick) {
        onClick(evt)
        return
      }

      if (selectedState) {
        selectedState[1]((prevState) => {
          if (prevState === selector) {
            return null
          } else {
            return selector
          }
        })
      }
    },
    [selector, selectedState, onClick]
  )

  const selected =
    (selectedState != null &&
      selectedState[0] &&
      selectedState[0] === selector) ??
    false

  return (
    <tr className={selected ? "table-active" : ""} onClick={handleOnClick}>
      {children}
    </tr>
  )
}
