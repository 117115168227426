"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthenticatedType = exports.TokenSchema = exports.MeResponseSchema = exports.LogoutResponseSchema = exports.LogoutRequestSchema = exports.LoginResponseSchema = exports.LoginRequestSchema = void 0;
var typebox_1 = require("@sinclair/typebox");
var common_1 = require("./common");
var utils_1 = require("./utils");
exports.LoginRequestSchema = (0, utils_1.strictObject)({
    email: common_1.EmailSchema,
    password: common_1.PasswordSchema,
}, {
    title: "Login",
    description: "Credentials used to sign in ",
});
exports.LoginResponseSchema = (0, utils_1.strictObject)({
    id: common_1.IdSchema,
    name: common_1.NameSchema,
    email: common_1.EmailSchema,
    role: common_1.RoleSchema,
}, {
    title: "Login",
    description: "Results of authentication, giving information on the authenticated user",
});
exports.LogoutRequestSchema = (0, utils_1.strictObject)({}, {
    title: "Logout",
    description: "Information required to logout",
});
exports.LogoutResponseSchema = (0, utils_1.strictObject)({
    name: common_1.NameSchema,
    email: common_1.EmailSchema,
}, {
    title: "Logout",
    description: "Information on who was logged out",
});
exports.MeResponseSchema = (0, utils_1.strictObject)({
    id: common_1.IdSchema,
    name: common_1.NameSchema,
    email: common_1.EmailSchema,
    role: common_1.RoleSchema,
}, {
    title: "Me",
    description: "Information on the currently authenticated user",
});
exports.TokenSchema = typebox_1.Type.String({
    title: "Authentication Token",
    description: "Token used for authentication (a JWT)",
    minLength: 1,
    maxLength: 1024,
    examples: [
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c",
    ],
});
/**
 * The type of subject (API Key or User) who is represented by the authentication token.
 */
var AuthenticatedType;
(function (AuthenticatedType) {
    AuthenticatedType["USER"] = "user";
    AuthenticatedType["APIKEY"] = "apikey";
})(AuthenticatedType = exports.AuthenticatedType || (exports.AuthenticatedType = {}));
