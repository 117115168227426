import { Form } from "@rjsf/bootstrap-4"
import { ErrorSchema, IChangeEvent, ISubmitEvent } from "@rjsf/core"
import { LoginRequest, LoginRequestSchema } from "raml-lib"
import { PrimaryButton } from "../brand/PrimaryButton"
import { useCallback, useState } from "react"

type LoginProps = {
  onLogin(request: LoginRequest): void
}

export function Login({ onLogin }: LoginProps) {
  const [formData, setFormData] = useState<LoginRequest>({
    email: "",
    password: "",
  })

  const loginCallback = useCallback(
    (e: ISubmitEvent<LoginRequest>) => {
      // Clear the password when we attempt to login
      setFormData({
        email: e.formData.email,
        password: "",
      })
      onLogin(e.formData)
    },
    [onLogin, setFormData]
  )

  const handleChange = useCallback(
    (event: IChangeEvent<LoginRequest>, es?: ErrorSchema) => {
      setFormData(event.formData)
    },
    [setFormData]
  )

  return (
    <Form
      schema={LoginRequestSchema}
      formData={formData}
      onChange={handleChange}
      uiSchema={{
        "ui:title": "Login",
        "ui:description": "Enter your username and password to login.",
        email: {
          "ui:description": " ",
        },
        password: {
          "ui:widget": "password",
          "ui:description": " ",
        },
      }}
      onSubmit={loginCallback}
    >
      <PrimaryButton type="submit">Login</PrimaryButton>
    </Form>
  )
}
