import { Breadcrumb, Table } from "react-bootstrap"
import { LinkContainer } from "react-router-bootstrap"
import { Id, ListApiKeyQuery } from "raml-lib"
import { useState, useCallback } from "react"
import { TableRow } from "../components/misc/TableRow"
import { AdminOnly } from "../components/auth/AdminOnly"
import { RelativeTimestamp } from "../components/brand/RelativeTimestamp"
import { FilterBar } from "../components/misc/FilterBar"
import { useApiListApiKeys } from "../api"
import { Paginator } from "../components/misc/Paginator"
import { DEFAULT_TAKE } from "../constants"
import { useModal } from "../hooks/useModal"
import { CreateApiKeyDialog } from "../components/apikeys/CreateApiKeyDialog"
import { UpdateApiKeyDialog } from "../components/apikeys/UpdateApiKeyDialog"
import { GenerateApiKeyTokenDialog } from "../components/apikeys/GenerateApiKeyTokenDialog"
import { PrimaryButton } from "../components/brand/PrimaryButton"
import { SecondaryButton } from "../components/brand/SecondaryButton"
import { ToolBar } from "../components/misc/ToolBar"
import { StatusButton } from "../components/brand/StatusButton"

const TAKE = DEFAULT_TAKE

export function ApiKeysPage() {
  const selectedState = useState<Id | null>(null)
  const selected = selectedState[0]

  const [createModal, hideCreateModal, showCreateModal] = useModal()
  const [editModal, hideEditModal, showEditModal] = useModal()
  const [generateModal, hideGenerateModal, showGenerateModal] = useModal()

  const [skip, setSkip] = useState(0)
  const [filter, setFilter] = useState<boolean | "all">("all")
  const q: ListApiKeyQuery = {
    take: TAKE,
    skip: skip,
  }
  if (filter !== "all") {
    q.enabled = filter
  }
  const list = useApiListApiKeys(q)

  // If the page changes we remove selection
  const setSelectedState = selectedState && selectedState[1]
  const handleSkip = useCallback(
    (s) => {
      setSkip(s)
      setSelectedState(null)
    },
    [setSkip, setSelectedState]
  )

  return (
    <>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </LinkContainer>
        <LinkContainer to="/apikeys">
          <Breadcrumb.Item active>API Keys</Breadcrumb.Item>
        </LinkContainer>
      </Breadcrumb>
      <h1>API keys</h1>
      <p>
        On this page you can manage RAML API Keys. An API Key gives other
        systems access to the RAML platform through the API.
      </p>
      <AdminOnly>
        <ToolBar>
          <PrimaryButton onClick={showCreateModal} className="me-2">
            New
          </PrimaryButton>
          {selected && (
            <SecondaryButton onClick={showEditModal} className="me-2">
              Edit
            </SecondaryButton>
          )}
          {selected && (
            <SecondaryButton onClick={showGenerateModal} className="me-2">
              Generate token
            </SecondaryButton>
          )}
        </ToolBar>
      </AdminOnly>

      <FilterBar
        filter={filter}
        onFilter={setFilter}
        options={{
          all: "All",
          true: "Enabled",
          false: "Revoked",
        }}
      />
      <CreateApiKeyDialog shown={createModal} onHide={hideCreateModal} />
      {selected && (
        <UpdateApiKeyDialog
          id={selected}
          shown={editModal}
          onHide={hideEditModal}
        />
      )}
      {selected && (
        <GenerateApiKeyTokenDialog
          id={selected}
          shown={generateModal}
          onHide={hideGenerateModal}
        />
      )}

      <Table striped={false} bordered={true} hover={true}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Role</th>
            <th>Key</th>
            <th>Status</th>
            <th>Created</th>
          </tr>
        </thead>
        <tbody>
          {(list.data?.results ?? []).map((r) => (
            <TableRow
              key={r.id}
              selector={r.id}
              selectedState={selectedState}
              className={!r.enabled ? "table-danger" : ""}
            >
              <td>{r.name}</td>
              <td>{r.role}</td>
              <td>
                <code>{r.key}</code>
              </td>
              <td>
                <StatusButton variant={r.enabled ? "green" : "red"}>
                  {r.enabled ? "Enabled" : "Revoked"}
                </StatusButton>
              </td>
              <td>
                <RelativeTimestamp value={r.createdAt} />
              </td>
            </TableRow>
          ))}
        </tbody>
      </Table>
      <Paginator
        skip={skip}
        take={TAKE}
        total={list.data?.total}
        setSkip={handleSkip}
      />

      <h2>Using API Keys</h2>
      <p>
        Select the key you wish to use, and then click <b>Generate Token</b>{" "}
        above. This will create a signed JWT which you can use with RAML.
      </p>
      <p>
        To use the JWT pass its content as the HTTP header:{" "}
        <code>Authorization: Bearer [jwt content]</code>.
      </p>
    </>
  )
}
