import { useEffect } from "react"
import { IdParams, UpdateFileRequestSchema } from "raml-lib"
import { useApiGetFile, useApiUpdateFile } from "../../api"
import { FormDialog, ShowHideProps } from "../forms/FormDialog"

type Props = ShowHideProps & IdParams

export function UpdateFileDialog(props: Props) {
  const { shown, id } = props

  const { mutate } = useApiUpdateFile(id)

  const { data, refetch } = useApiGetFile(id)

  useEffect(() => {
    if (shown && id) {
      refetch()
    }
  }, [shown, id, refetch])

  if (!data) {
    return null
  }

  return (
    <FormDialog
      schema={UpdateFileRequestSchema}
      uiSchema={{
        id: {
          "ui:widget": "hidden",
        },
        updatedAt: {
          "ui:widget": "hidden",
        },
        createdAt: {
          "ui:widget": "hidden",
        },
        licenseId: {
          "ui:widget": "hidden",
        },
      }}
      onSubmit={mutate}
      data={data}
      {...props}
    />
  )
}
