import { useEffect } from "react"
import { IdParams, UpdateApiKeyRequestSchema } from "raml-lib"
import { useApiGetApiKey, useApiUpdateApiKey } from "../../api"
import { FormDialog, ShowHideProps } from "../forms/FormDialog"
import { Notification, useNotificationContext } from "../../notifications"

type UpdateApiKeyProps = ShowHideProps & IdParams

export function UpdateApiKeyDialog(props: UpdateApiKeyProps) {
  const { shown, id } = props
  const { onNotification } = useNotificationContext()
  const { mutate } = useApiUpdateApiKey(id, {
    onSuccess: (result) => {
      onNotification(
        new Notification(
          "API Key updated",
          <p>Updated API Key {result.name}</p>
        )
      )
    },
  })
  const { data, refetch } = useApiGetApiKey(id)

  // We don't want key in our form data.
  // It can't be edited once set
  const { key, ...formData } = data || {}

  useEffect(() => {
    if (shown) {
      refetch()
    }
  }, [shown, id, refetch])

  if (!data) {
    return null
  }

  return (
    <FormDialog
      schema={UpdateApiKeyRequestSchema}
      uiSchema={{
        id: {
          "ui:widget": "hidden",
        },
        updatedAt: {
          "ui:widget": "hidden",
        },
        createdAt: {
          "ui:widget": "hidden",
        },
        enabled: {
          "ui:widget": "radio",
        },
      }}
      onSubmit={mutate}
      data={formData}
      {...props}
    />
  )
}
