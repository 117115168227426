import React from "react"

export type InfoPaneProps = {
  data: {
    key: string
    value: string | number | boolean | null | undefined | React.ReactElement
  }[]
}

export function InfoPane({ data }: InfoPaneProps) {
  if (data.length === 0) {
    return null
  }

  return (
    <>
      {data.map((r) => (
        <div key={r.key}>
          <small>{r.key}:</small> {r.value == null ? "-" : r.value}
        </div>
      ))}
    </>
  )
}
