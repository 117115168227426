import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter as Router } from "react-router-dom"
import dayjs from "dayjs"
import localizedFormat from "dayjs/plugin/localizedFormat"
import relativeTime from "dayjs/plugin/relativeTime"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"

import reportWebVitals from "./reportWebVitals"
import { ApiProvider } from "./api"
import { QueryClient, QueryClientProvider } from "react-query"
import { App } from "./App"

dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(timezone)

const queryClient = new QueryClient()

export function Index() {
  return (
    <QueryClientProvider client={queryClient}>
      <ApiProvider>
        <Router>
          <App />
        </Router>
      </ApiProvider>
    </QueryClientProvider>
  )
}

function render() {
  ReactDOM.render(Index(), document.getElementById("root"))
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.debug)

// When run in test mode (dev:mock) to use the mocks
// When you do a production build you must explicitly set (REACT_APP_MOCK=false)
// Otherwise  MSW will be put in the bundle (+50kb gzipped).
if (
  process.env.REACT_APP_MOCK === "true" &&
  process.env.NODE_ENV !== "production"
) {
  console.debug("Starting MSW")
  const { worker } = require("./tests/browser")
  worker.start().then(render)
} else {
  render()
}

console.info("Welcome to RAML")
console.debug("Running in %s mode", process.env.NODE_ENV)
