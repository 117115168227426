import { useParams, useHistory } from "react-router"
import { Breadcrumb, Row, Col } from "react-bootstrap"
import { LinkContainer } from "react-router-bootstrap"
import { useApiDeleteFile, useApiDeleteLicense, useApiGetLicense } from "../api"
import { InfoPane, InfoPaneProps } from "../components/misc/InfoPane"
import { SecondaryButton } from "../components/brand/SecondaryButton"
import { Timestamp } from "../components/brand/Timestamp"
import { ToolBar } from "../components/misc/ToolBar"
import { SitePreview } from "../components/sites/SitePreview"
import { FileTable } from "../components/files/FileTable"
import { useCallback, useState } from "react"
import { useModal } from "../hooks/useModal"
import { UpdateLicenseDialog } from "../components/licenses/UpdateLicenseDialog"
import { UploadFileDialog } from "../components/files/UploadFileDialog"
import { useNotificationContext, Notification } from "../notifications"
import { DeleteButton } from "../components/delete/DeleteButton"
import { Id } from "raml-lib"
import { UpdateFileDialog } from "../components/files/UpdateFileDialog"
import { ActivityMeasure } from "../components/activity/ActivityMeasure"
import { EditorOnly } from "../components/auth/EditorOnly"

export function LicensePage() {
  const { id } = useParams<{ id: string }>()
  const licenseId = parseInt(id)
  const { onNotification } = useNotificationContext()
  const { data: license } = useApiGetLicense(licenseId)
  const { mutate: mutateDelete } = useApiDeleteLicense(licenseId, {
    onSuccess: (data) => {
      onNotification(
        new Notification(
          "License deleted",
          <p>Deleted license number {license?.licenseReference || licenseId}</p>
        )
      )
    },
  })
  const history = useHistory()
  const [editModal, hideEditModal, showEditModal] = useModal()
  const [editFileModal, hideEditFileModal, showEditFileModal] = useModal()
  const [uploadModal, hideUploadModal, showUploadModal] = useModal()

  const selectedFileState = useState<Id | null>(null)
  const selectedFile = selectedFileState[0]

  const { mutate: mutateDeleteFile } = useApiDeleteFile(
    selectedFile ?? undefined
  )

  const deleteLicense = useCallback(() => {
    mutateDelete(
      {},
      {
        onSuccess: () => history.push("/licenses"),
      }
    )
  }, [mutateDelete, history])

  const deleteFile = useCallback(() => {
    mutateDeleteFile({})
  }, [mutateDeleteFile])

  const getInfoPaneData = useCallback((): InfoPaneProps => {
    let infoPaneData: InfoPaneProps = {
      data: [],
    }
    if (license) {
      infoPaneData.data = [
        {
          key: "Licence Number",
          value: license.licenseReference,
        },
        {
          key: "Licence Status",
          value: license.status,
        },
        {
          key: "License authorisation number",
          value: license.licenseAuthorisationNumber,
        },
        {
          key: "License authorisation expires at",
          value: license.licenseAuthorisationExpiresAt && (
            <Timestamp value={license.licenseAuthorisationExpiresAt} />
          ),
        },
        {
          key: "At delivery activity limit",
          value: (
            <ActivityMeasure
              value={license.deliveryActivityLimit}
              unit={license.activityUnit}
            />
          ),
        },
        {
          key: "Maximum Individual Dose Activity At Delivery Limit",
          value: (
            <ActivityMeasure
              value={license.maximumIndividualDoseActivityAtDeliveryLimit}
              unit={license.activityUnit}
            />
          ),
        },
        // {
        //   key: "Weekly activity (last 7 days) limit",
        //   value: (
        //     <ActivityMeasure
        //       value={license.weeklyActivityLimit}
        //       unit={license.activityUnit}
        //     />
        //   ),
        // },
        // {
        //   key: "Monthly activity (last 30 days) limit",
        //   value: (
        //     <ActivityMeasure
        //       value={license.monthlyActivityLimit}
        //       unit={license.activityUnit}
        //     />
        //   ),
        // },
        // {
        //   key: "Yearly activity (last 365 days) limit",
        //   value: (
        //     <ActivityMeasure
        //       value={license.yearlyActivityLimit}
        //       unit={license.activityUnit}
        //     />
        //   ),
        // },
        {
          key: "Current week activity limit",
          value: (
            <ActivityMeasure
              value={license.currentWeekActivityLimit}
              unit={license.activityUnit}
            />
          ),
        },
        {
          key: "Current month activity limit",
          value: (
            <ActivityMeasure
              value={license.currentMonthActivityLimit}
              unit={license.activityUnit}
            />
          ),
        },
        {
          key: "Current year activity limit",
          value: (
            <ActivityMeasure
              value={license.currentYearActivityLimit}
              unit={license.activityUnit}
            />
          ),
        },
        {
          key: "Activity units",
          value: license.activityUnit,
        },
        {
          key: "Check at",
          value: license.checkAt && <Timestamp value={license.checkAt} />,
        },
        {
          key: "Expires at",
          value: license.expiresAt && <Timestamp value={license.expiresAt} />,
        },
        {
          key: "Created at",
          value: <Timestamp value={license.createdAt} />,
        },
        {
          key: "Updated at",
          value: <Timestamp value={license.updatedAt} />,
        },
        {
          key: "Licence Notes",
          value: license.licenseNotes,
        },
      ]

      if (!license.licenseAuthorisationNumber) {
        infoPaneData.data = infoPaneData.data.filter(
          (d) => d.key !== "License authorisation number"
        )
      }
      if (!license.licenseAuthorisationExpiresAt) {
        infoPaneData.data = infoPaneData.data.filter(
          (d) => d.key !== "License authorisation expires at"
        )
      }
    }

    return infoPaneData
  }, [license])

  return (
    <>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </LinkContainer>
        <LinkContainer to="/licenses">
          <Breadcrumb.Item>Licenses</Breadcrumb.Item>
        </LinkContainer>
        <LinkContainer to={`/licenses/${id}`}>
          <Breadcrumb.Item active>
            License Number {license?.licenseReference}
          </Breadcrumb.Item>
        </LinkContainer>
      </Breadcrumb>
      <UpdateLicenseDialog
        id={licenseId}
        shown={editModal}
        superHide={hideEditModal}
      />
      {selectedFile && (
        <UpdateFileDialog
          id={selectedFile}
          shown={editFileModal}
          onHide={hideEditFileModal}
        />
      )}
      <UploadFileDialog
        licenseId={licenseId}
        shown={uploadModal}
        onHide={hideUploadModal}
      />
      <h1>License Number {license?.licenseReference}</h1>
      <Row>
        <Col md={8}>
          <h2>Files</h2>
          <EditorOnly>
            <ToolBar>
              <SecondaryButton onClick={showUploadModal}>
                Upload
              </SecondaryButton>
              {selectedFile && (
                <SecondaryButton onClick={showEditFileModal} className="me-2">
                  Edit
                </SecondaryButton>
              )}
              {selectedFile && (
                <DeleteButton onClick={deleteFile} className="me-2" />
              )}
            </ToolBar>
          </EditorOnly>
          <FileTable licenseId={licenseId} selectedState={selectedFileState} />
        </Col>
        <Col md={4}>
          <EditorOnly>
            <ToolBar>
              <SecondaryButton onClick={showEditModal}>Edit</SecondaryButton>
              <DeleteButton onClick={deleteLicense} />
            </ToolBar>
          </EditorOnly>
          {license && <InfoPane data={getInfoPaneData().data} />}
          <hr />
          {license && <SitePreview id={license.siteId} />}
        </Col>
      </Row>
    </>
  )
}
