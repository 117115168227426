import { format, parseISO } from "date-fns"

export function FormatDatetime({
  date,
  time,
}: {
  date: string
  time: string | number
}) {
  return (
    <small>
      {format(parseISO(date), "MMM dd, yyyy")} {time}
    </small>
  )
}
