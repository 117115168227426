"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CsrfTokenResponseSchema = void 0;
var typebox_1 = require("@sinclair/typebox");
var utils_1 = require("./utils");
exports.CsrfTokenResponseSchema = (0, utils_1.strictObject)({
    token: typebox_1.Type.String({
        title: "CSRF header token",
        description: "Value for the CSRF header",
        minLength: 1,
        maxLength: 1024,
        examples: ["l7ziGJqFdTz9RUcG"],
    }),
}, {
    title: "CSRF Token",
    description: "Contains the CSRF token to be send as an X-CSRF-TOKEN header with each POST request",
});
