import { WidgetProps } from "@rjsf/core"
import { Widgets } from "@rjsf/bootstrap-4"

export function LocalTimeWidget(props: WidgetProps) {
  // Remove examples from the schema - Chrome will display them
  const { schema, ...otherProps } = props
  const { examples, ...restOfSchema } = schema
  return (
    <Widgets.TextWidget type="time" {...otherProps} schema={restOfSchema} />
  )
}
