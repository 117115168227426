import React from "react"
import { Table } from "react-bootstrap"

type InfoTableProps = {
  data: {
    key: string
    value: string | number | boolean | null | undefined | React.ReactElement
  }[]
}

export function InfoTable({ data }: InfoTableProps) {
  if (data.length === 0) {
    return null
  }

  return (
    <Table bordered>
      <tbody>
        {data.map((r) => (
          <tr key={r.key}>
            <td>
              <small>{r.key}</small>
            </td>
            <td>{r.value == null ? "-" : r.value}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}
