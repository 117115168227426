"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UploadFileResponseSchema = exports.UploadFileRequestSchema = exports.UpdateFileResponseSchema = exports.UpdateFileRequestSchema = exports.ListFileResponseSchema = exports.ListFileQuerySchema = exports.GetFileResponseSchema = exports.DeleteFileResponseSchema = exports.FileSchema = exports.MimeSchema = exports.FilenameSchema = exports.FileTypeSchema = exports.FileType = void 0;
var typebox_1 = require("@sinclair/typebox");
var common_1 = require("../common");
var crud_1 = require("../crud");
var utils_1 = require("../utils");
// Aligns to DB FileType
var FileType;
(function (FileType) {
    FileType["OTHER"] = "OTHER";
    FileType["RENEWAL"] = "RENEWAL";
    FileType["LICENSE"] = "LICENSE";
})(FileType = exports.FileType || (exports.FileType = {}));
exports.FileTypeSchema = (0, utils_1.enumSchema)(typebox_1.Type.Enum(FileType), {
    title: "File type",
    description: "The type of file",
    examples: [FileType.LICENSE],
});
exports.FilenameSchema = typebox_1.Type.String({
    title: "File name",
    description: "The name of file",
    minLength: 1,
    maxLength: 256,
    examples: ["report.pdf"],
});
exports.MimeSchema = typebox_1.Type.String({
    title: "mime",
    minLength: 1,
    maxLength: 64,
    description: "Mime type",
    examples: ["application/pdf"],
});
exports.FileSchema = (0, utils_1.strictObject)(__assign({ name: common_1.NameSchema, description: common_1.DescriptionSchema, licenseId: common_1.IdSchema, type: exports.FileTypeSchema, mime: exports.MimeSchema, filename: exports.FilenameSchema }, common_1.MODEL_META_SCHEMA), {
    title: "File",
    description: "An document or file.",
});
exports.DeleteFileResponseSchema = crud_1.DeleteResponseSchema;
exports.GetFileResponseSchema = exports.FileSchema;
exports.ListFileQuerySchema = typebox_1.Type.Intersect([
    crud_1.PaginationQuerySchema,
    typebox_1.Type.Object({
        licenseId: typebox_1.Type.Optional(common_1.IdSchema),
    }),
], {
    title: "Files query",
    description: "Specify the pagination and query to match",
});
exports.ListFileResponseSchema = (0, crud_1.createListResponseSchema)(exports.FileSchema, crud_1.NoSortSchema);
exports.UpdateFileRequestSchema = (0, utils_1.strictObject)(__assign({ name: common_1.NameSchema, description: common_1.DescriptionSchema, licenseId: common_1.IdSchema, type: exports.FileTypeSchema, mime: exports.MimeSchema, filename: exports.FilenameSchema }, common_1.UPDATE_MODEL_META_SCHEMA), { title: "Update file", description: "Edit an existing file's metadata" });
exports.UpdateFileResponseSchema = exports.FileSchema;
// This is a shared schema which is used by fastify-multipart for form file fields
var MultipartSharedSchema = typebox_1.Type.Any({ $id: "#multipartSharedSchema" });
// this is a multi part file upload - it's not JSON as other other
exports.UploadFileRequestSchema = (0, utils_1.strictObject)({
    // There are representations of multipart form data (note allows additionalProperties)
    name: typebox_1.Type.Object({ value: common_1.NameSchema }),
    description: typebox_1.Type.Object({ value: common_1.DescriptionSchema }),
    licenseId: typebox_1.Type.Object({ value: common_1.IdSchema }),
    type: typebox_1.Type.Object({ value: exports.FileTypeSchema }),
    // This is the (multipart) file upload
    file: typebox_1.Type.Ref(MultipartSharedSchema),
}, { title: "Upload file", description: "Upload a new file" });
exports.UploadFileResponseSchema = exports.FileSchema;
