import { Id, timeZoneNames, timeZonesWithUtc } from "raml-lib"
import { useApiGetSite } from "../../api"
import { Link } from "react-router-dom"
import { InfoPane } from "../misc/InfoPane"
import { useMemo } from "react"

export function SitePreview({ id }: { id: Id }) {
  const { data } = useApiGetSite(id, {
    onError: () => {
      // Do nothing - we don't consider this essential enough to error about.
    },
  })

  const timezone = useMemo(() => {
    if (data) {
      return timeZoneNames.includes(data.timezone)
        ? `${
            timeZonesWithUtc.find((t) => t.name === data.timezone)
              ?.currentTimeFormat
          } (${data.timezone})`
        : data.timezone
    }
    return null
  }, [data])

  return (
    <>
      {data && (
        <>
          <h6>
            <Link to={`/sites/${data.id}`}>
              Site Number {data.siteReference}
            </Link>
          </h6>
          <InfoPane
            data={[
              { key: "Name", value: data.siteName },
              { key: "Country", value: data.country },
              { key: "Timezone", value: timezone },
              { key: "Doing Business As (DBA) name", value: data.customerName },
            ]}
          />
        </>
      )}
    </>
  )
}
