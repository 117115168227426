import { Breadcrumb, Table, Row, Col } from "react-bootstrap"
import { LinkContainer } from "react-router-bootstrap"
import { CustomerType, ListSiteQuery } from "raml-lib"
import { useCallback, useEffect, useMemo, useState } from "react"
import { COUNTRIES, getCountryNameForCode, Id } from "raml-lib"
import { TableRow } from "../components/misc/TableRow"
import { RelativeTimestamp } from "../components/brand/RelativeTimestamp"
import { useApiListSites } from "../api"
import { Paginator } from "../components/misc/Paginator"
import { DEFAULT_TAKE } from "../constants"
import { ExportSitesButton } from "../components/sites/ExportSitesButton"
import { ToolBar } from "../components/misc/ToolBar"
import { Link } from "react-router-dom"
import { SearchBar } from "../components/misc/SearchBar"
import { EditorOnly } from "../components/auth/EditorOnly"
import { FilterBar } from "../components/misc/FilterBar"
import Select, { ActionMeta } from "react-select"

const TAKE = DEFAULT_TAKE

export function SitesPage() {
  const selectedState = useState<Id | null>(null)

  const [countries, setCountries] = useState<string[] | []>([])
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined)

  const [skip, setSkip] = useState(0)
  const [filter, setFilter] = useState<
    CustomerType.DISTRIBUTORS | CustomerType.CUSTOMERS | CustomerType.ALL
  >(CustomerType.ALL)

  const query: ListSiteQuery = useMemo(() => {
    const q = {
      take: TAKE,
      skip: skip,
      // These searchQuery are OR query
      siteReference: searchQuery,
      customerName: searchQuery,
      siteName: searchQuery,
      countries: countries.join(","),
      type: CustomerType.ALL,
    }
    if (filter !== CustomerType.ALL) {
      q.type = filter
    }
    return q
  }, [filter, skip, TAKE, searchQuery, countries])

  const list = useApiListSites(query)

  // If the page changes we remove selection
  const setSelectedState = selectedState && selectedState[1]

  const handleSkip = useCallback(
    (s) => {
      setSkip(s)
      setSelectedState(null)
    },
    [setSkip, setSelectedState]
  )
  const options = COUNTRIES.map((r) => ({
    value: r,
    label: `${getCountryNameForCode(r)} (${r})` ?? r,
  }))

  const onChange = (
    option: readonly typeof Option[],
    actionMeta: ActionMeta<typeof Option>
  ) => {
    // @ts-ignore
    setCountries(option.map((i) => i.value))
  }

  // If the search query changes, we remove selection
  useEffect(() => {
    setSelectedState(null)
  }, [searchQuery, setSelectedState])

  // If the type filter changes, we reset the pagination
  useEffect(() => {
    setSkip(0)
  }, [filter, countries])

  return (
    <>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </LinkContainer>
        <LinkContainer to="/sites">
          <Breadcrumb.Item active>Sites</Breadcrumb.Item>
        </LinkContainer>
      </Breadcrumb>
      <h1>Sites</h1>
      <p>
        A Site is the shipping destination for an Order and the assumed
        treatment location. Each Site is associated with a{" "}
        <i>Radioactive Material License</i> that defines the material they can
        hold.
      </p>
      <Row>
        <Col md={8}>
          <SearchBar
            placeholder="Search by site number (NR-TS)"
            onQuery={setSearchQuery}
          />
        </Col>
        <Col md={4}>
          <Select
            placeholder="Select countries"
            // @ts-ignore
            options={options}
            onChange={onChange}
            isMulti
          />
        </Col>
      </Row>
      <EditorOnly>
        <ToolBar>
          <ExportSitesButton />
        </ToolBar>
      </EditorOnly>

      <FilterBar
        filter={filter}
        onFilter={setFilter}
        options={{
          all: "All",
          [CustomerType.CUSTOMERS]: "Customers",
          [CustomerType.DISTRIBUTORS]: "Distributors",
        }}
      />

      <Table striped={false} bordered={true} hover={true}>
        <thead>
          <tr>
            <th>Site Number (NR-TS)</th>
            <th>Ship-To Customer Site name</th>
            <th>Country</th>
            <th>Doing Business As (DBA) name</th>
            <th>Created</th>
          </tr>
        </thead>
        <tbody>
          {(list.data?.results ?? []).map((r) => (
            <TableRow key={r.id} selector={r.id} selectedState={selectedState}>
              <td>
                <Link to={`/sites/${r.id}`}>{r.siteReference}</Link>
              </td>
              <td>{r.siteName}</td>
              <td>{r.country}</td>
              <td>{r.customerName}</td>
              <td>
                <RelativeTimestamp value={r.createdAt} />
              </td>
            </TableRow>
          ))}
        </tbody>
      </Table>
      <Paginator
        skip={skip}
        take={TAKE}
        total={list.data?.total}
        setSkip={handleSkip}
      />
    </>
  )
}
