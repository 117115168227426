import { Toast } from "react-bootstrap"
import { useNotificationContext } from "../../notifications"
import { RelativeTimestamp } from "../brand/RelativeTimestamp"

export function ToastNotifications() {
  const { notifications, dismissNotification } = useNotificationContext()
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        right: 0,
      }}
    >
      {notifications.map((n) => (
        <Toast
          key={n.id}
          onClose={() => dismissNotification(n)}
          delay={10000}
          autohide={true}
        >
          <Toast.Header>
            <strong className={`mr-auto text-${n.variant}`}>{n.title}</strong>
            <small>
              <RelativeTimestamp value={n.at} />
            </small>
          </Toast.Header>
          <Toast.Body>{n.body}</Toast.Body>
        </Toast>
      ))}
    </div>
  )
}
