import { Button, ButtonProps } from "react-bootstrap"
import { PropsWithChildren } from "react"

type PrimaryButtonProps = Omit<ButtonProps, "variant">

export function PrimaryButton({
  children,
  ...others
}: PropsWithChildren<PrimaryButtonProps>) {
  return (
    <Button variant="navy" {...others}>
      {children}
    </Button>
  )
}
