"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.extendIntegerType = exports.extendNumberType = exports.extendStringType = exports.strictObject = exports.enumSchema = exports.enumToObjectSchema = void 0;
var typebox_1 = require("@sinclair/typebox");
/**
 * Helper function to convert from an TEnum to an TObject.
 *
 * We need this for as Type.keyOf requires an TObject and not a TEnum.
 *
 * This feels unnecessary... but is a utility.
 *
 * @param schema
 * @returns
 */
// This is ignored because its a generated return type dependant on the input value
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function enumToObjectSchema(schema) {
    var enumValues = schema.anyOf.map(function (x) { return x.const; });
    var enumValuesAsObject = {};
    for (var _i = 0, enumValues_1 = enumValues; _i < enumValues_1.length; _i++) {
        var x = enumValues_1[_i];
        enumValuesAsObject[x] = typebox_1.Type.String({ title: x });
    }
    // Copy as much as we can from enum to the object
    return strictObject(enumValuesAsObject, {
        title: schema.title,
        description: schema.description,
        examples: schema.examples,
    });
}
exports.enumToObjectSchema = enumToObjectSchema;
/**
 * Create an JSON Schema for a String Enum which can be used by JSON Schema Forms.
 *
 * Ideally we'd use Type.Enum() but this doesn't produce a schema which React Json Schema Forms interprets correctly.
 * As such we need to use Type.KeyOf. However that only requires an TObject not a TSchema.
 *
 * @param schema
 */
// Disabled as complex generated type
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function enumSchema(schema, options) {
    return typebox_1.Type.KeyOf(enumToObjectSchema(schema), Object.assign({
        title: schema.title,
        description: schema.description,
        examples: schema.examples,
    }, options));
}
exports.enumSchema = enumSchema;
/**
 * Create an strictObject  which does not allow any additional properties.
 *
 * This is essential for DTOs since we don't want additional fields being pushed into the database, etc.
 */
function strictObject(properties, options) {
    if (options === void 0) { options = {}; }
    return typebox_1.Type.Object(properties, __assign(__assign({}, options), { additionalProperties: false }));
}
exports.strictObject = strictObject;
function extendStringType(original, options) {
    var data = Object.assign({}, original);
    // Remove anything which could be specific to the original type
    delete data["$id"];
    delete data["type"];
    delete data["kind"];
    return typebox_1.Type.String(Object.assign(data, options));
}
exports.extendStringType = extendStringType;
function extendNumberType(original, options) {
    if (options == undefined) {
        return original;
    }
    var data = Object.assign({}, original);
    // Remove anything which could be specific to the original type
    delete data["$id"];
    delete data["type"];
    delete data["kind"];
    return typebox_1.Type.Number(Object.assign(data, options));
}
exports.extendNumberType = extendNumberType;
function extendIntegerType(original, options) {
    if (options == undefined) {
        return original;
    }
    var data = Object.assign({}, original);
    // Remove anything which could be specific to the original type
    delete data["$id"];
    delete data["type"];
    delete data["kind"];
    return typebox_1.Type.Integer(Object.assign(data, options));
}
exports.extendIntegerType = extendIntegerType;
