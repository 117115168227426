"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateApiKeyResponseSchema = exports.UpdateApiKeyRequestSchema = exports.ApiKeyTokenResponseSchema = exports.ListApiKeyResponseSchema = exports.ListApiKeyQuerySchema = exports.GetApiKeyResponseSchema = exports.DeleteApiKeyResponseSchema = exports.CreateApiKeyResponseSchema = exports.CreateApiKeyRequestSchema = exports.ApiKeySchema = exports.KeySchema = void 0;
var typebox_1 = require("@sinclair/typebox");
var common_1 = require("../common");
var crud_1 = require("../crud");
var auth_1 = require("../auth");
var utils_1 = require("../utils");
exports.KeySchema = typebox_1.Type.String({
    title: "Key",
    description: "API key identifier",
    minLength: 1,
    maxLength: 4096,
    examples: [
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c",
    ],
});
exports.ApiKeySchema = (0, utils_1.strictObject)(__assign({ name: common_1.NameSchema, key: exports.KeySchema, role: common_1.RoleSchema, enabled: common_1.EnabledSchema }, common_1.MODEL_META_SCHEMA), {
    title: "API key",
    description: "API keys are used to authenticate system calls",
});
exports.CreateApiKeyRequestSchema = (0, utils_1.strictObject)({
    name: common_1.NameSchema,
    role: common_1.RoleSchema,
    enabled: common_1.EnabledSchema,
}, {
    title: "Create API key",
    description: "Create a new API key. The key value will be generated automatically.",
});
exports.CreateApiKeyResponseSchema = exports.ApiKeySchema;
exports.DeleteApiKeyResponseSchema = crud_1.DeleteResponseSchema;
exports.GetApiKeyResponseSchema = exports.ApiKeySchema;
exports.ListApiKeyQuerySchema = typebox_1.Type.Intersect([
    crud_1.PaginationQuerySchema,
    typebox_1.Type.Object({
        enabled: typebox_1.Type.Optional(typebox_1.Type.Boolean()),
    }),
], {
    title: "API keys query",
    description: "Specify the pagination and query to match",
});
exports.ListApiKeyResponseSchema = (0, crud_1.createListResponseSchema)(exports.ApiKeySchema, crud_1.NoSortSchema);
exports.ApiKeyTokenResponseSchema = (0, utils_1.strictObject)({
    token: auth_1.TokenSchema,
}, {
    title: "API Key token",
    description: "The token to be used during authentication (using HTTP Bearer Authentication) to use as the API key",
});
exports.UpdateApiKeyRequestSchema = (0, utils_1.strictObject)(__assign({ name: common_1.NameSchema, role: common_1.RoleSchema, enabled: common_1.EnabledSchema }, common_1.UPDATE_MODEL_META_SCHEMA), {
    title: "Update API key",
    description: "Update API key, though changing the key itself is not supported",
});
exports.UpdateApiKeyResponseSchema = exports.ApiKeySchema;
