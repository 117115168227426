import { Activity, ActivityUnit, convertActivityUnits } from "raml-lib"

type Props = {
  value?: Activity
  unit: ActivityUnit
  displayUnit?: ActivityUnit
}

export function ActivityMeasure({ value, unit, displayUnit }: Props) {
  let convertedUnit: ActivityUnit | string
  let convertedValue: Activity | undefined
  if (!displayUnit) {
    convertedValue = value
    convertedUnit = unit
  } else {
    convertedValue = convertActivityUnits(value, unit, displayUnit) ?? undefined
    convertedUnit = displayUnit
  }

  if (convertedUnit === ActivityUnit.MILLI_CI) {
    convertedUnit = "mCI"
  }

  return (
    <span>
      {convertedValue?.toFixed(2) || "-"} {convertedUnit}
    </span>
  )
}
