import { WidgetProps } from "@rjsf/core"
import { Widgets } from "@rjsf/bootstrap-4"
import { Alert, ListGroup } from "react-bootstrap"
import { useApiGetSite, useApiListSites } from "../../api"
import { useState } from "react"
import { CustomerType } from "raml-lib"

interface Options {
  customError?: (error: string) => string
  checkForDuplicate?: boolean
  updateSiteCountry?: (countryCode: string) => string
}

export function SiteWidget(props: WidgetProps) {
  const { value, onChange, options, ...others } = props
  const { checkForDuplicate, customError, updateSiteCountry }: Options = options
  const [siteReference, setSiteReference] = useState<string>("")

  // Get the site by id and update the siteReference
  const {
    data: site,
    isFetching: isFetchingSite,
    isFetched: isFetchedSite,
  } = useApiGetSite(value, {
    enabled: value > 0 && !checkForDuplicate,
    onSuccess: (s) => {
      setSiteReference(s.siteReference)
      if (updateSiteCountry) {
        updateSiteCountry(s.country)
      }
    },
  })

  const { data: sites } = useApiListSites(
    {
      // We don't want too many results here...
      take: 5,
      skip: 0,
      siteReference: checkForDuplicate ? value : siteReference,
      type: CustomerType.ALL,
    },
    {
      // Wait until the users typed, or we've been through the get site by id above
      enabled: checkForDuplicate
        ? value != null && value.length > 0
        : siteReference != null && siteReference.length > 0,
      onSuccess: (data) => {
        if (checkForDuplicate) {
          if (data.results.length) {
            // If we find one result that will be the existing order present
            onChange(data.results[0].siteReference)
            if (customError) {
              customError(`${data.results[0].siteReference} already exists`)
            }
          } else {
            if (customError) {
              customError("")
            }
          }
        } else {
          if (data.results.length === 0) {
            // If we find no results we need to clear our selection
            onChange(undefined)
          } else if (data.results.length === 1) {
            // If we find one result that is our pick
            onChange(data.results[0].id)
          }
        }
      },
    }
  )

  if (props.options.display == false) {
    return null
  }

  return (
    <>
      <Widgets.TextWidget
        {...others}
        value={checkForDuplicate ? value : siteReference}
        onChange={checkForDuplicate ? onChange : setSiteReference}
        options={options}
      />
      {!checkForDuplicate && (
        <>
          {sites?.results && sites?.results.length > 1 && (
            <ListGroup>
              {sites?.results.map((x) => (
                <ListGroup.Item key={x.id} onClick={() => onChange(x.id)}>
                  {x.siteReference}
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
          {site && !isFetchingSite && (
            <small>
              Site {site.siteReference} is {site.siteName}, {site.address},{" "}
              {site.country}.
            </small>
          )}
          {!site && isFetchedSite && !isFetchingSite && siteReference && (
            <Alert variant="danger">
              <small>Site {siteReference} not found!</small>
            </Alert>
          )}
          {!site && !isFetchingSite && !siteReference && (
            <small>Site not selected.</small>
          )}
        </>
      )}
    </>
  )
}
