import { now } from "raml-lib"
import React from "react"
import { v4 } from "uuid"

export class ErrorNotification extends Error {
  constructor(
    public readonly title: string,
    message: string,
    public readonly code?: number
  ) {
    super(message)
  }

  public toNotification(): Notification {
    let m = this.message
    if (this.code) {
      m += `Error code is ${this.code}.`
    }
    return new Notification(this.title, <p>{m}</p>, "danger")
  }
}

export class Notification {
  id = v4()
  at = now()

  constructor(
    public readonly title: string,
    public readonly body: React.ReactElement,
    public readonly variant: string = "info"
  ) {}
}
