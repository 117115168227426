import { useCallback } from "react"
import { useApiExportSites } from "../../api"
import { PrimaryButton } from "../brand/PrimaryButton"
import { useNotificationContext, Notification } from "../../notifications"

export function ExportSitesButton() {
  const { onNotification } = useNotificationContext()
  const { mutate } = useApiExportSites({
    onSuccess: (data) => {
      onNotification(
        new Notification(
          "Export generated",
          (
            <p>
              Please check your email for the exported CSV
            </p>
          )
        )
      )
    },
  })

  const handleClick = useCallback(
    () => mutate({}),
    [mutate]
  )

  return (
    <PrimaryButton onClick={handleClick} className="me-2">
      Export
    </PrimaryButton>
  )
}
