import {
  UseQueryOptions,
  UseQueryResult,
  useQuery,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
  useMutation,
} from "react-query"
import { Role, MeResponse } from "raml-lib"
import { Api, ApiContext } from "./context"
import { useContext, useCallback } from "react"
import { useHistory } from "react-router"
import { ErrorNotification, useNotificationContext } from "../notifications"

export function useApiContext(): Api {
  return useContext(ApiContext)
}

export function useAuthenticated(): boolean {
  const { me } = useApiContext()
  return me != null
}

export function useMe(): MeResponse {
  const { me } = useApiContext()
  const history = useHistory()

  if (me == null) {
    history.push("/")
    // We should never get here really - i.e. you should not request the user if we have not passed authentication
    throw new Error("Not authenticated, but requesting user")
  }

  return me
}

export type Key = (string | number | unknown | undefined | null)[]

export function createKey(path: Key): Key {
  if (path.length === 0) {
    throw new Error("Invalid empty key")
  }
  return path
}

export function createUrl(path: Key): string {
  const key = path
    .filter((p) => typeof p === "string" || typeof p === "number")
    .join("/")
  if (key.length === 0) {
    throw new Error("Invalid url, unable to create key")
  }
  return key
}

export type ServerErrorBody = {
  statusCode?: number
  error?: string
  message?: string
}

export type UseApiQueryOptions<Data> = UseQueryOptions<
  Data,
  ErrorNotification,
  Data,
  Key
>
export type UseApiQueryResult<Data> = UseQueryResult<Data, ErrorNotification>

export function useApiQuery<Data, Query extends object>(
  path: Key,
  options?: UseApiQueryOptions<Data>,
  query?: Query
): UseApiQueryResult<Data> {
  const { onError, ...otherOptions } = options || {}
  const { onErrorNotification } = useNotificationContext()
  const { baseUrl } = useApiContext()
  const key = createKey(path)
  const url = createUrl(path)

  const queryFunction = useCallback(async () => {
    let u = `${baseUrl}${url}`
    if (query) {
      const search = new URLSearchParams()
      Object.entries(query).forEach((item) => {
        if (item[1] !== undefined) {
          search.append(item[0], item[1])
        }
      })
      u += `?${search}`
    }
    const response = await fetch(u, {
      method: "GET",
    })

    if (!response.ok) {
      await throwErrorFromResponse("GET", url, response)
    }

    return response.json()
  }, [baseUrl, query, url])

  return useQuery<Data, ErrorNotification, Data, Key>(key, queryFunction, {
    enabled: key.every((x) => x != null),
    onError: onError || onErrorNotification,
    ...otherOptions,
  })
}

export type UseApiMutationOptions<Data, Body> = UseMutationOptions<
  Data,
  ErrorNotification,
  Body
>
export type UseApiMutationResult<Data, Body> = UseMutationResult<
  Data,
  ErrorNotification,
  Body
>

type AdditionalApiMutationOptions = {
  contentType?: string
  invalidateKeys?: Key[]
}

export function useApiMutation<Data, Body = unknown>(
  method: "POST" | "PUT" | "DELETE",
  path: (string | number | undefined | null)[],
  options?: UseApiMutationOptions<Data, Body> & AdditionalApiMutationOptions
): UseApiMutationResult<Data, Body> {
  const { onSuccess, onError, invalidateKeys, ...otherOptions } = options || {}
  const { onErrorNotification } = useNotificationContext()
  const queryClient = useQueryClient()
  const { baseUrl, csrf } = useApiContext()
  const url = createUrl(path)
  const key = createKey(path)

  const contentType = options?.contentType ?? "application/json"

  const mutation = useCallback(
    async (body: Body) => {
      const u = `${baseUrl}${url}`
      const headers: HeadersInit = {}
      // Leave the browser to set the contentType when using multipart/form-data
      const headerContentType =
        contentType !== "multipart/form-data" ? contentType : undefined
      if (headerContentType) {
        headers["Content-Type"] = headerContentType
      }
      if (csrf.current?.token) {
        headers["X-CSRF-TOKEN"] = csrf.current?.token
      }

      let fetchBody: FormData | string | null | undefined = undefined
      if (body) {
        switch (contentType) {
          case "application/json":
            fetchBody = JSON.stringify(body)
            break
          case "multipart/form-data":
            fetchBody = body as unknown as FormData
            break
          default:
            // Leave unchanged
            break
        }
      }

      const response = await fetch(u, {
        method,
        body: fetchBody,
        headers,
      })

      if (!response.ok) {
        await throwErrorFromResponse(method, url, response)
      }

      return response.json()
    },
    [csrf, baseUrl, method, url, contentType]
  )

  return useMutation<Data, ErrorNotification, Body>(mutation, {
    onSuccess: (data, variables, context) => {
      // As our keys are RESTFUL a POST to /orders invalidates (/orders/123 and /orders)
      queryClient.invalidateQueries(key)

      if (invalidateKeys) {
        invalidateKeys.forEach((x) => queryClient.invalidateQueries(x))
      }

      if (onSuccess) {
        onSuccess(data, variables, context)
      }
    },
    onError: onError || onErrorNotification,
    ...otherOptions,
  })
}

export function useHasRole(requiredRole: Role): boolean {
  const me = useMe()
  const role = me.role

  switch (requiredRole) {
    case Role.ADMIN:
      return role === Role.ADMIN
    case Role.EDITOR:
      return role === Role.ADMIN || role === Role.EDITOR
    case Role.READER:
      return true
    default:
      return false
  }
}

async function throwErrorFromResponse(
  method: string,
  _url: string,
  response: Response
): Promise<void> {
  let message = response.statusText ?? ""
  try {
    const body = (await response.json()) as ServerErrorBody
    if (body?.message) {
      message = body?.message
    }
  } catch (err) {
    // Ignore... we use the default statusText
  }
  throw new ErrorNotification(
    "API Error",
    `Unable to ${method} information from API: ${message}`,
    response.status
  )
}
