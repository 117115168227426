import { Pagination } from "react-bootstrap"

import { useCallback, useMemo } from "react"

const PAGE_AROUND = 2

type PaginationProps = {
  skip: number
  take: number
  total?: number
  setSkip(skip: number): void
}
export function Paginator({ skip, take, total, setSkip }: PaginationProps) {
  const safeTake = take ?? 1

  const page = Math.floor(skip / safeTake) + 1
  const totalPages = Math.floor((total != null ? total - 1 : 0) / safeTake) + 1

  const setPage = useCallback(
    (nextPage) => {
      let p = Math.min(Math.max(nextPage, 1), totalPages) - 1
      setSkip(p * take)
    },
    [setSkip, take, totalPages]
  )

  const pages = useMemo(() => {
    const min = Math.max(page - PAGE_AROUND, 1)
    const max = Math.min(page + PAGE_AROUND, totalPages)

    const allPages = []
    for (let i = min; i <= max; i++) {
      allPages.push(i)
    }
    return allPages
  }, [page, totalPages])

  if (total == null || total <= 1) {
    return null
  }

  return (
    <Pagination>
      <Pagination.First disabled={page === 1} onClick={() => setPage(1)} />
      <Pagination.Prev
        disabled={page === 1}
        onClick={() => setPage(page - 1)}
      />
      {page > PAGE_AROUND + 1 && <Pagination.Ellipsis />}

      {pages.map((i) => (
        <Pagination.Item key={i} active={i === page} onClick={() => setPage(i)}>
          {i}
        </Pagination.Item>
      ))}

      {page < totalPages - PAGE_AROUND && <Pagination.Ellipsis />}
      <Pagination.Next
        disabled={page === totalPages}
        onClick={() => setPage(page + 1)}
      />
      <Pagination.Last
        disabled={totalPages === page}
        onClick={() => setPage(totalPages)}
      />
    </Pagination>
  )
}
