import { useEffect } from "react"
import { LocalDate, LocalTime, SiteReference } from "raml-lib"
import { useApiCheckSiteActivity } from "../../api"
import { ActivityCheckResult } from "./ActivityCheckResult"

type CheckSiteActivityProps = {
  siteReference: SiteReference
  siteDate: LocalDate
  siteTime: LocalTime
}

export function CheckSiteActivity({
  siteReference,
  siteDate,
  siteTime,
}: CheckSiteActivityProps) {
  const { mutate, data } = useApiCheckSiteActivity()

  useEffect(() => {
    mutate({
      siteReference,
      siteDate,
      siteTime,
    })
  }, [mutate, siteReference, siteDate, siteTime])

  return <ActivityCheckResult result={data} />
}
