import {
  ActivityUnit,
  CheckActivityResponse,
  CumulativeCheckResult,
  InstantaneousCheckResult,
} from "raml-lib"
import { Timestamp } from "../brand/Timestamp"
import { Table } from "react-bootstrap"
import { StatusButton } from "../brand/StatusButton"
import { ActivityMeasure } from "./ActivityMeasure"
import { CheckCircleFill, XCircleFill } from "react-bootstrap-icons"
import { Link } from "react-router-dom"

type ActivityRowProps = {
  title: string
  description?: string
  result?: CumulativeCheckResult | InstantaneousCheckResult
  activityUnit: ActivityUnit
}

function ActivityRow({
  title,
  description,
  result,
  activityUnit,
}: ActivityRowProps) {
  const titleCell = (
    <td>
      {title}
      <br />
      <small>{description}</small>
    </td>
  )

  if (!result) {
    return (
      <tr>
        {titleCell}
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
      </tr>
    )
  }
  let rowClassName = undefined
  let passed: string | React.ReactElement = "-"
  if (result.licensedActivity) {
    rowClassName = result.valid ? "table-success" : "table-warning"
    passed = result.valid ? (
      <span>
        <CheckCircleFill /> Yes
      </span>
    ) : (
      <span>
        <XCircleFill /> No
      </span>
    )
  }

  return (
    <tr className={rowClassName}>
      {titleCell}
      <td>
        {result.totalOrders} orders in the last {result.totalDays} days
        <br />
        <small>
          <Timestamp value={result.from} /> to <Timestamp value={result.to} />
        </small>
      </td>
      <td>
        <ActivityMeasure value={result.activity} unit={activityUnit} />
        <br />
        <small>
          <ActivityMeasure
            value={result.activity}
            unit={activityUnit}
            displayUnit={ActivityUnit.GBQ}
          />
          &nbsp;|&nbsp;
          <ActivityMeasure
            value={result.activity}
            unit={activityUnit}
            displayUnit={ActivityUnit.CI}
          />
          &nbsp;|&nbsp;
          <ActivityMeasure
            value={result.activity}
            unit={activityUnit}
            displayUnit={ActivityUnit.MILLI_CI}
          />
        </small>
      </td>
      <td>
        <ActivityMeasure value={result.licensedActivity} unit={activityUnit} />
        <br />
        <small>
          <ActivityMeasure
            value={result.licensedActivity}
            unit={activityUnit}
            displayUnit={ActivityUnit.GBQ}
          />
          &nbsp;|&nbsp;
          <ActivityMeasure
            value={result.licensedActivity}
            unit={activityUnit}
            displayUnit={ActivityUnit.CI}
          />
          &nbsp;|&nbsp;
          <ActivityMeasure
            value={result.licensedActivity}
            unit={activityUnit}
            displayUnit={ActivityUnit.MILLI_CI}
          />
        </small>
      </td>
      <td>{passed}</td>
    </tr>
  )
}

type ActivityCheckResultProps = {
  result?: CheckActivityResponse
}

export function ActivityCheckResult({ result }: ActivityCheckResultProps) {
  if (!result) {
    return <p>Awaiting result...</p>
  }

  const license = result.license
  const site = result.site

  return (
    <>
      <h2 style={{ marginTop: "0px" }}>Activity check</h2>
      {result.errors.length > 0 && (
        <p>
          {result.errors.map((r) => (
            <>
              <StatusButton key={r} variant="danger">
                {r}
              </StatusButton>{" "}
            </>
          ))}
        </p>
      )}
      {result.warnings.length > 0 && (
        <p>
          {result.warnings.map((r) => (
            <>
              <StatusButton key={r} variant="warning">
                {r}
              </StatusButton>{" "}
            </>
          ))}
        </p>
      )}
      {result.errors.length === 0 && result.valid && (
        <p>
          <StatusButton variant="success">Passed</StatusButton>
        </p>
      )}
      <p>
        {result.valid ? (
          <span>
            The activity check <b>passed</b>.
          </span>
        ) : (
          <span>
            The activity check <b>did not pass</b>.
          </span>
        )}
      </p>

      {result.messages.length > 0 && (
        <>
          <ul>
            {result.messages.map((r) => (
              <li key={r}>{r}</li>
            ))}
          </ul>
        </>
      )}
      {site && (
        <p>
          Site Number{" "}
          <b>
            {site.siteReference} ({site.siteName})
          </b>
          , for <b>{site.customerName}</b>, located at{" "}
          <b>
            {site.address}, {site.country}
          </b>{" "}
          in timezone <b>{site.timezone}</b>.
        </p>
      )}
      {license && (
        <p>
          <span>
            License number <b>{license.licenseReference}</b> has status{" "}
            <b>{license.status ?? "Unknown"}</b> and expires at{" "}
            <b>
              {license.expiresAt ? (
                <Timestamp value={license.expiresAt} />
              ) : (
                "Unknown"
              )}
            </b>
            .
          </span>{" "}
          {license.checkAt && (
            <span>
              License to be checked at{" "}
              <b>
                {license.checkAt ? (
                  <Timestamp value={license.checkAt} />
                ) : (
                  "Unknown"
                )}
              </b>
              .
            </span>
          )}
        </p>
      )}
      <OrderAtDelivery result={result} />
      <p>
        <small>Calculated at </small> <Timestamp value={result.calculatedAt} />
      </p>
      <Table>
        <thead>
          <tr>
            <th>Check</th>
            <th>Orders</th>
            <th>Calculated activity</th>
            <th>Activity limit</th>
            <th>Passed?</th>
          </tr>
        </thead>
        <tbody>
          <ActivityRow
            title="Activity held at time of delivery"
            description={
              site?.distributorSite
                ? "This excludes orders which have been docked and shipped from the distributor"
                : "This excludes orders which have been delivered and treated"
            }
            result={result.deliveryActivity}
            activityUnit={result.activityUnit ?? ActivityUnit.GBQ}
          />
          <ActivityRow
            title="Activity delivered in the current week"
            description="From Monday"
            result={result.currentWeekActivity}
            activityUnit={result.activityUnit ?? ActivityUnit.GBQ}
          />
          <ActivityRow
            title="Activity delivered in the current month"
            description="From the 1st of the month"
            result={result.currentMonthActivity}
            activityUnit={result.activityUnit ?? ActivityUnit.GBQ}
          />
          <ActivityRow
            title="Activity delivered in the current year"
            description="From the 1st January"
            result={result.currentYearActivity}
            activityUnit={result.activityUnit ?? ActivityUnit.GBQ}
          />
          {/*<ActivityRow*/}
          {/*  title="Activity delivered in the last 7 days"*/}
          {/*  result={result.weeklyActivity}*/}
          {/*  activityUnit={result.activityUnit ?? ActivityUnit.GBQ}*/}
          {/*/>*/}
          {/*<ActivityRow*/}
          {/*  title="Activity delivered in the last 31 days"*/}
          {/*  result={result.monthlyActivity}*/}
          {/*  activityUnit={result.activityUnit ?? ActivityUnit.GBQ}*/}
          {/*/>*/}
          {/*<ActivityRow*/}
          {/*  title="Activity delivered in the last 365 days"*/}
          {/*  result={result.yearlyActivity}*/}
          {/*  activityUnit={result.activityUnit ?? ActivityUnit.GBQ}*/}
          {/*/>*/}
        </tbody>
      </Table>
      <OrdersAtDelivery result={result} />
    </>
  )
}

type OrdersAtDeliveryProps = {
  result?: CheckActivityResponse
}

function OrdersAtDelivery({ result }: OrdersAtDeliveryProps) {
  // Do we have something to work with?
  if (!result || result.activityUnit == null) {
    return null
  }

  const otherOrders = result.deliveryActivity?.orders ?? []

  // If we don't have anything to display relevant to this panel, then display nothing
  // i.e. on site checks, we have no order to comment on
  if (otherOrders.length === 0) {
    return null
  }

  return (
    <>
      {otherOrders.length > 0 && (
        <>
          <p>
            Other orders contributing to the total activity at the time of
            delivery are
            {otherOrders.map((o) => (
              <span key={o.id}>
                {" "}
                <Link to={`/orders/${o.id}`}>{o.orderReference}</Link>
              </span>
            ))}
            . These orders have been delivered but not treated.
          </p>
        </>
      )}
    </>
  )
}

type OrderAtDeliveryProps = {
  result?: CheckActivityResponse
}

function OrderAtDelivery({ result }: OrderAtDeliveryProps) {
  // Do we have something to work with?
  if (!result || result.activityUnit == null) {
    return null
  }

  // If we don't have anything to display relevant to this panel, then display nothing
  // i.e. on site checks, we have no order to comment on
  if (result.activityAtDelivery == null) {
    return null
  }

  return (
    <>
      {result.activityAtDelivery && (
        <p>
          The activity of this order alone at the time of delivery is{" "}
          <ActivityMeasure
            value={result.activityAtDelivery}
            unit={result.activityUnit}
          />{" "}
          (
          <small>
            <ActivityMeasure
              value={result.activityAtDelivery}
              unit={result.activityUnit}
              displayUnit={ActivityUnit.GBQ}
            />
            &nbsp;|&nbsp;
            <ActivityMeasure
              value={result.activityAtDelivery}
              unit={result.activityUnit}
              displayUnit={ActivityUnit.CI}
            />
            &nbsp;|&nbsp;
            <ActivityMeasure
              value={result.activityAtDelivery}
              unit={result.activityUnit}
              displayUnit={ActivityUnit.MILLI_CI}
            />
          </small>
          ).
        </p>
      )}
    </>
  )
}
