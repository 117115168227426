import { Table } from "react-bootstrap"
import { DEFAULT_TAKE } from "../../constants"
import { Paginator } from "../misc/Paginator"
import { ListSiteWithoutLicenseQuery } from "raml-lib"
import { Link } from "react-router-dom"
import { useState } from "react"
import { useApiListSiteWithoutLicense } from "../../api"

type SiteWithoutLicenseTableProps = Partial<ListSiteWithoutLicenseQuery>

export function SiteWithoutLicenseTable(props: SiteWithoutLicenseTableProps) {
  const [skip, setSkip] = useState(0)
  const take = props.take ?? DEFAULT_TAKE

  const { data } = useApiListSiteWithoutLicense({
    ...props,
    take,
    skip,
  })

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Doing Business As (DBA) name</th>
            <th>Site Number</th>
            <th>Ship-To Customer Site name</th>
            <th>Licenses</th>
          </tr>
        </thead>
        <tbody>
          {data?.results.map((r) => (
            <tr key={r.site.id}>
              <td>
                <Link to={`/sites/${r.site.id}`}>{r.site.siteReference}</Link>
              </td>
              <td>{r.site.customerName}</td>
              <td>{r.site.siteName}</td>
              <td>{r.licenseCount}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Paginator
        skip={skip}
        take={take}
        total={data?.total}
        setSkip={setSkip}
      />
    </>
  )
}
