import { Breadcrumb, Table } from "react-bootstrap"
import { LinkContainer } from "react-router-bootstrap"
import { Id, ListUserQuery } from "raml-lib"
import { useState, useCallback, useEffect } from "react"
import { TableRow } from "../components/misc/TableRow"
import { AdminOnly } from "../components/auth/AdminOnly"
import { RelativeTimestamp } from "../components/brand/RelativeTimestamp"
import { FilterBar } from "../components/misc/FilterBar"
import { useApiListUsers, useMe } from "../api"
import { Paginator } from "../components/misc/Paginator"
import { DEFAULT_TAKE } from "../constants"
import { useModal } from "../hooks/useModal"
import { PrimaryButton } from "../components/brand/PrimaryButton"
import { SecondaryButton } from "../components/brand/SecondaryButton"
import { ToolBar } from "../components/misc/ToolBar"
import { StatusButton } from "../components/brand/StatusButton"
import { CreateUserDialog } from "../components/users/CreateUserDialog"
import { UpdateUserDialog } from "../components/users/UpdateUserDialog"
import { ResetUserPasswordDialog } from "../components/users/ResetUserPasswordDialog"
import { SearchBar } from "../components/misc/SearchBar"

const TAKE = DEFAULT_TAKE

export function UsersPage() {
  const me = useMe()
  const selectedState = useState<Id | null>(null)
  const selected = selectedState[0]

  const [createModal, hideCreateModal, showCreateModal] = useModal()
  const [editModal, hideEditModal, showEditModal] = useModal()
  const [resetPasswordModal, hideResetPasswordModal, showResetPasswordModal] =
    useModal()
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined)

  const [skip, setSkip] = useState(0)
  const [filter, setFilter] = useState<boolean | "all">("all")
  const q: ListUserQuery = {
    take: TAKE,
    skip: skip,
    email: searchQuery,
  }
  if (filter !== "all") {
    q.enabled = filter
  }
  const list = useApiListUsers(q)

  // If the page changes we remove selection
  const setSelectedState = selectedState && selectedState[1]
  const handleSkip = useCallback(
    (s) => {
      setSkip(s)
      setSelectedState(null)
    },
    [setSkip, setSelectedState]
  )

  // If the search query changes, we remove selection
  useEffect(() => {
    setSelectedState(null)
  }, [searchQuery, setSelectedState])

  return (
    <>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </LinkContainer>
        <LinkContainer to="/users">
          <Breadcrumb.Item active>Users</Breadcrumb.Item>
        </LinkContainer>
      </Breadcrumb>
      <h1>Users</h1>
      <p>On this page you can manage the users of RAML.</p>
      <SearchBar placeholder="Search by email" onQuery={setSearchQuery} />
      <AdminOnly>
        <ToolBar>
          <PrimaryButton onClick={showCreateModal} className="me-2">
            New
          </PrimaryButton>
          {selected && (
            <SecondaryButton onClick={showEditModal} className="me-2">
              Edit
            </SecondaryButton>
          )}
          {selected && selected !== me.id && (
            <SecondaryButton onClick={showResetPasswordModal} className="me-2">
              Reset Password
            </SecondaryButton>
          )}
        </ToolBar>
      </AdminOnly>

      <FilterBar
        filter={filter}
        onFilter={setFilter}
        options={{
          all: "All",
          true: "Enabled",
          false: "Disabled",
        }}
      />
      <CreateUserDialog shown={createModal} onHide={hideCreateModal} />
      {selected && (
        <UpdateUserDialog
          id={selected}
          shown={editModal}
          onHide={hideEditModal}
        />
      )}
      {selected && (
        <ResetUserPasswordDialog
          id={selected}
          shown={resetPasswordModal}
          onHide={hideResetPasswordModal}
        />
      )}

      <Table striped={false} bordered={true} hover={true}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Status</th>
            <th>Created</th>
          </tr>
        </thead>
        <tbody>
          {(list.data?.results ?? []).map((r) => (
            <TableRow
              key={r.id}
              selector={r.id}
              selectedState={selectedState}
              className={!r.enabled ? "table-danger" : ""}
            >
              <td>{r.name}</td>
              <td>{r.email}</td>
              <td>{r.role}</td>
              <td>
                <StatusButton variant={r.enabled ? "green" : "red"}>
                  {r.enabled ? "Enabled" : "Disabled"}
                </StatusButton>
              </td>
              <td>
                <RelativeTimestamp value={r.createdAt} />
              </td>
            </TableRow>
          ))}
        </tbody>
      </Table>
      <Paginator
        skip={skip}
        take={TAKE}
        total={list.data?.total}
        setSkip={handleSkip}
      />
    </>
  )
}
