import { ToggleButton, ToggleButtonGroup } from "react-bootstrap"

type FilterBarProps<S> = {
  filter: S
  onFilter(value: S): void
  options: Record<S | any, string> // value to name
}

export function FilterBar<S>({ options, filter, onFilter }: FilterBarProps<S>) {
  return (
    <ToggleButtonGroup
      name="filter"
      type="radio"
      size="sm"
      defaultValue={filter}
      className="mb-2"
      onChange={onFilter}
    >
      {Object.entries(options).map((e) => (
        <ToggleButton key={e[0]} variant="outline-secondary" value={e[0]}>
          {e[1] ?? e[0]}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}
