import { useEffect } from "react"
import { OrderReference } from "raml-lib"
import { useApiRecheckOrderActivity } from "../../api"
import { ActivityCheckResult } from "./ActivityCheckResult"

type RecheckOrderActivityProps = {
  orderReference: OrderReference
  siteId: string | undefined
}

export function RecheckOrderActivity({
  orderReference,
  siteId,
}: RecheckOrderActivityProps) {
  const { mutate, data } = useApiRecheckOrderActivity({})

  useEffect(() => {
    mutate({ orderReference, siteId })
  }, [mutate, orderReference, siteId ])

  return <ActivityCheckResult result={data} />
}
