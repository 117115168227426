import { CreateUserRequestSchema, Role } from "raml-lib"
import { useApiCreateUser } from "../../api"
import { FormDialog, ShowHideProps } from "../forms/FormDialog"
import { useNotificationContext, Notification } from "../../notifications"

type Props = ShowHideProps

export function CreateUserDialog(props: Props) {
  const { onNotification } = useNotificationContext()
  const { mutate } = useApiCreateUser({
    onSuccess: (data) => {
      onNotification(
        new Notification("User created", <p>Created {data.email}</p>)
      )
    },
  })

  return (
    <FormDialog
      schema={CreateUserRequestSchema}
      uiSchema={{
        password: {
          "ui:widget": "password",
        },
        enabled: {
          "ui:widget": "radio",
        },
      }}
      data={{ enabled: true, role: Role.READER }}
      onSubmit={mutate}
      {...props}
    />
  )
}
