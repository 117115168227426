"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IS_PRODUCTION = exports.IS_DEVELOPMENT = exports.IS_TEST = void 0;
/**
 * Are we in running in an environment for testing only (unit / e2e / etc)?
 *
 */
exports.IS_TEST = process.env.NODE_ENV === "test";
/**
 * Are we running in an environment for developing?
 */
exports.IS_DEVELOPMENT = process.env.NODE_ENV === "development";
/**
 * Is this a production/deployed environment?
 */
exports.IS_PRODUCTION = !exports.IS_TEST && !exports.IS_DEVELOPMENT;
