import { Button, Modal } from "react-bootstrap"
import { PropsWithChildren, useCallback } from "react"
import { CancelButtonProps } from "../brand/CancelButton"
import { useModal } from "../../hooks/useModal"

type DeleteButtonProps = Omit<CancelButtonProps, "onClick"> & {
  onClick: () => void
}

export function DeleteButton({
  children,
  onClick,
  ...others
}: PropsWithChildren<DeleteButtonProps>) {
  const [shown, hideModal, showModal] = useModal()

  const onConfirm = useCallback(() => {
    hideModal()
    onClick()
  }, [hideModal, onClick])

  return (
    <>
      <Modal show={shown} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm deletion?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you wish to delete this?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onConfirm}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Button
        variant="red"
        style={{ borderRadius: 0 }}
        onClick={showModal}
        {...others}
      >
        {children ?? "Delete"}
      </Button>
    </>
  )
}
