import { Breadcrumb } from "react-bootstrap"
import { LinkContainer } from "react-router-bootstrap"
import { PrimaryButton } from "../components/brand/PrimaryButton"
import { ToolBar } from "../components/misc/ToolBar"
import { useModal } from "../hooks/useModal"
import { ChangeUserPasswordDialog } from "../components/users/ChangeUserPasswordDialog"
import { InfoTable } from "../components/misc/InfoTable"
import { useMe } from "../api"

export function MePage() {
  const me = useMe()

  const [
    changePasswordModal,
    hideChangePasswordModal,
    showChangePasswordModal,
  ] = useModal()

  return (
    <>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </LinkContainer>
        <LinkContainer to="/me">
          <Breadcrumb.Item active>My account</Breadcrumb.Item>
        </LinkContainer>
      </Breadcrumb>
      <h1>Your account</h1>
      <p>On this page you can manage your account and settings.</p>
      <ToolBar>
        <PrimaryButton onClick={showChangePasswordModal} className="me-2">
          Change password
        </PrimaryButton>
      </ToolBar>

      <ChangeUserPasswordDialog
        shown={changePasswordModal}
        onHide={hideChangePasswordModal}
        id={me.id}
      />
      <InfoTable
        data={[
          { key: "Name", value: me.name },
          { key: "Email", value: me.email },
          { key: "Role", value: me.role },
        ]}
      />
    </>
  )
}
