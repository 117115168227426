import { ResetPasswordRequestSchema, IdParams } from "raml-lib"
import { useApiResetPassword } from "../../api"
import { FormDialog, ShowHideProps } from "../forms/FormDialog"
import { useNotificationContext, Notification } from "../../notifications"

type Props = ShowHideProps & IdParams

export function ResetUserPasswordDialog({ id, ...others }: Props) {
  const { onNotification } = useNotificationContext()
  const { mutate } = useApiResetPassword(id, {
    onSuccess: (data) => {
      onNotification(
        new Notification(
          "User password reset",
          <p>Reset password for {data.email}</p>
        )
      )
    },
  })

  return (
    <FormDialog
      schema={ResetPasswordRequestSchema}
      uiSchema={{
        password: {
          "ui:widget": "password",
          "ui:description": " ",
        },
      }}
      onSubmit={mutate}
      {...others}
    />
  )
}
