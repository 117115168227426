import { useParams } from "react-router"
import { Breadcrumb, Row, Col } from "react-bootstrap"
import { LinkContainer } from "react-router-bootstrap"
import { useApiGetSite } from "../api"
import { InfoPane } from "../components/misc/InfoPane"
import { SecondaryButton } from "../components/brand/SecondaryButton"
import { Timestamp } from "../components/brand/Timestamp"
import { ToolBar } from "../components/misc/ToolBar"
import { LicenseTable } from "../components/licenses/LicenseTable"
import { OrderTable } from "../components/orders/OrderTable"
import { CheckSiteActivity } from "../components/activity/CheckSiteActivity"
import { useMemo } from "react"
import { useModal } from "../hooks/useModal"
import { CreateLicenseDialog } from "../components/licenses/CreateLicenseDialog"
import { CreateOrderDialog } from "../components/orders/CreateOrderDialog"
import { nowInTimezone, timeZoneNames, timeZonesWithUtc } from "raml-lib"
import { EditorOnly } from "../components/auth/EditorOnly"

export function SitePage() {
  const { id } = useParams<{ id: string }>()
  const siteId = parseInt(id)

  const { data: site } = useApiGetSite(siteId)

  const [newOrderModal, hideNewOrderModal, showNewOrderModal] = useModal()
  const [newLicenseModal, hideNewLicenseModal, showNewLicenseModal] = useModal()

  const [siteLocalDate, siteLocalTime] = useMemo(() => {
    if (site != null) {
      const now = nowInTimezone(site.timezone)
      return [now.format("YYYY-MM-DD"), now.format("HH:mm")]
    } else {
      return [null, null]
    }
  }, [site])

  const timezone = useMemo(() => {
    if (site) {
      return timeZoneNames.includes(site.timezone)
        ? `${
            timeZonesWithUtc.find((t) => t.name === site.timezone)
              ?.currentTimeFormat
          } (${site.timezone})`
        : site.timezone
    }
    return null
  }, [site])

  return (
    <>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </LinkContainer>
        <LinkContainer to="/sites">
          <Breadcrumb.Item>Sites</Breadcrumb.Item>
        </LinkContainer>
        <LinkContainer to={`/sites/${id}`}>
          <Breadcrumb.Item active>
            Site Number {site?.siteReference}
          </Breadcrumb.Item>
        </LinkContainer>
      </Breadcrumb>
      <CreateLicenseDialog
        siteId={siteId}
        shown={newLicenseModal}
        onHide={hideNewLicenseModal}
      />
      <CreateOrderDialog
        siteId={siteId}
        shown={newOrderModal}
        onHide={hideNewOrderModal}
      />
      <h1>Site Number {site?.siteReference}</h1>
      <Row>
        <Col md={8}>
          {site && siteLocalDate && siteLocalTime && (
            <CheckSiteActivity
              siteReference={site.siteReference}
              siteDate={siteLocalDate}
              siteTime={siteLocalTime}
            />
          )}
          <h2>Licenses</h2>
          <EditorOnly>
            <ToolBar>
              <SecondaryButton onClick={showNewLicenseModal}>
                New
              </SecondaryButton>
            </ToolBar>
          </EditorOnly>
          <LicenseTable siteId={siteId} />
          <h2>Orders</h2>
          <EditorOnly>
            <ToolBar>
              <SecondaryButton onClick={showNewOrderModal}>New</SecondaryButton>
            </ToolBar>
          </EditorOnly>
          <OrderTable siteId={siteId} distributorSiteId={siteId} />
        </Col>
        <Col md={4}>
          {site && (
            <InfoPane
              data={[
                { key: "Site Number", value: site.siteReference },
                { key: "Name", value: site.siteName },
                {
                  key: "Distributor Site",
                  value: site.distributorSite ? "Yes" : "No",
                },
                { key: "Address", value: site.address },
                { key: "Country", value: site.country },
                { key: "Timezone", value: timezone },
                {
                  key: "Doing Business As (DBA) name",
                  value: site.customerName,
                },
                {
                  key: "Created at",
                  value: <Timestamp value={site.createdAt} />,
                },
                {
                  key: "Updated at",
                  value: <Timestamp value={site.updatedAt} />,
                },
              ]}
            />
          )}
        </Col>
      </Row>
    </>
  )
}
