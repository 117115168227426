import { WidgetProps } from "@rjsf/core"
import { Widgets } from "@rjsf/bootstrap-4"
import { useApiListOrders } from "../../api"

interface Options {
  customError?: (error: string) => string
}

export function OrderWidget(props: WidgetProps) {
  const { value, onChange, options, ...others } = props
  const { customError }: Options = options

  const { data: orders } = useApiListOrders(
    {
      // We don't want too many results here...
      take: 1,
      skip: 0,
      orderReference: value,
    },
    {
      // Wait until the users typed, or we've been through the get site by id above
      enabled: value != null && value.length > 0,
      onSuccess: (data) => {
        if (data.results.length && value && value.trim() === data.results[0].orderReference) {
          // If we find one result that will be the existing order present
          onChange(data.results[0].orderReference)
          if (customError) {
            customError("There is already an order with the entered Order ID")
          }
        } else {
          if (customError) {
            customError("")
          }
        }
      },
    }
  )

  return (
    <>
      <Widgets.TextWidget
        {...others}
        options={options}
        value={value}
        onChange={onChange}
      />
    </>
  )
}
