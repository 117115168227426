import { useCallback, useState } from "react"
import { LoginRequest } from "raml-lib"
import { Card, Button, Container, Modal, Row, Col } from "react-bootstrap"
import { Login } from "../components/auth/Login"
import { useApiAuthLogin } from "../api"

export function LoginPage() {
  const [show, setShow] = useState(false)
  const handleClose = useCallback(() => setShow(false), [setShow])
  const handleShow = useCallback(() => setShow(true), [setShow])

  const { mutate } = useApiAuthLogin({
    onError: handleShow,
  })

  const loginCallback = useCallback(
    (data: LoginRequest) => {
      mutate(data)
    },
    [mutate]
  )

  return (
    <>
      <Container>
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <Card>
              <Card.Body>
                <Login onLogin={loginCallback} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Sorry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Unable to log in - please check your credentials.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
