import { Nav, Navbar, Container, Row, Col, NavDropdown } from "react-bootstrap"
import { PropsWithChildren, useCallback } from "react"
import { useHistory } from "react-router"
import { NavLinkProps } from "react-router-dom"
import { LinkContainer } from "react-router-bootstrap"
import { Role } from "raml-lib"
import blueLogo from "../../assets/bsc-blue.svg"
import whiteLogo from "../../assets/bsc-tag-white.svg"
import { Paper } from "./Paper"
import { PersonFill } from "react-bootstrap-icons"
import { useApiAuthLogout, useHasRole, useMe } from "../../api"
import { ToastNotifications } from "../notifications/ToastNotifications"

// See https://github.com/react-bootstrap/react-router-bootstrap/issues/242
function RouterNavLink({
  children,
  ...props
}: React.PropsWithChildren<NavLinkProps>) {
  return (
    <LinkContainer {...props}>
      <Nav.Link active={false}>{children}</Nav.Link>
    </LinkContainer>
  )
}

export function AppLayout(props: PropsWithChildren<{}>) {
  const user = useMe()
  const logoutMutation = useApiAuthLogout()
  const history = useHistory()
  const isAdmin = useHasRole(Role.ADMIN)

  const logoutCallback = useCallback(() => {
    logoutMutation.mutate(
      {},
      {
        onError: () => {
          // On error, reload the whole window!
          history.go(0)
        },
      }
    )
  }, [logoutMutation, history])

  return (
    <>
      <Navbar fixed="top" bg="white" variant="light" expand="lg">
        <LinkContainer to="/">
          <Navbar.Brand className="py-0">
            <img
              alt="logo"
              src={blueLogo}
              width="64"
              height="64"
              className="d-inline-block align-top"
            />{" "}
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Brand>RAML</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-nav" />
        <Navbar.Collapse id="navbar-nav" className="justify-content-end">
          <Nav>
            <RouterNavLink to="/home">Home</RouterNavLink>
            <RouterNavLink to="/sites">Sites</RouterNavLink>
            <RouterNavLink to="/licenses">Licenses</RouterNavLink>
            <RouterNavLink to="/orders">Orders</RouterNavLink>
            <NavDropdown title="Reports" id="reports">
              <LinkContainer to="/reports/check">
                <NavDropdown.Item>To check</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/reports/expiring">
                <NavDropdown.Item>Expiring licenses</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/reports/issues">
                <NavDropdown.Item>License issues</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
            {isAdmin && <RouterNavLink to="/users">Users</RouterNavLink>}
            {isAdmin && <RouterNavLink to="/apikeys">API Keys</RouterNavLink>}
            <NavDropdown
              title={
                <span>
                  <PersonFill /> {user.name}
                </span>
              }
              id="user"
            >
              <LinkContainer to="/me">
                <NavDropdown.Item>My account</NavDropdown.Item>
              </LinkContainer>
              <NavDropdown.Item onClick={logoutCallback}>
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Container fluid style={{ marginTop: "75px", marginBottom: "100px" }}>
        <Row>
          <Col className="pt-3">
            <ToastNotifications />
            <Paper>{props.children}</Paper>
          </Col>
        </Row>
      </Container>
      <Navbar
        fixed="bottom"
        bg="primary"
        variant="dark"
        expand="md"
        className="flex-row-reverse"
      >
        <Navbar.Brand>
          <img
            alt="logo"
            src={whiteLogo}
            width="96"
            height="96"
            className="d-inline-block align-top"
          />
        </Navbar.Brand>
      </Navbar>
    </>
  )
}
