import { useState, useEffect, useMemo } from "react"
import { Id, ListFileQuery } from "raml-lib"
import { StatusButton } from "../brand/StatusButton"
import { Paginator } from "../misc/Paginator"
import { useApiListFiles } from "../../api"
import { Table } from "react-bootstrap"
import { DEFAULT_TAKE } from "../../constants"
import { RelativeTimestamp } from "../brand/RelativeTimestamp"
import { TableRow } from "../misc/TableRow"
import { ReactNullableUseState } from "../../types"

type FileTableProps = Partial<ListFileQuery> & {
  selectedState?: ReactNullableUseState<Id>
}

export function FileTable(props: FileTableProps) {
  const { selectedState, take = DEFAULT_TAKE, licenseId } = props
  const [skip, setSkip] = useState(0)
  const query = useMemo(() => {
    return {
      licenseId,
      take,
      skip,
    }
  }, [take, skip, licenseId])
  const list = useApiListFiles(query)

  // If the query query changes, we remove selection
  const setSelectedState = selectedState && selectedState[1]
  useEffect(() => {
    if (setSelectedState) {
      setSelectedState(null)
    }
  }, [query, setSelectedState])

  return (
    <>
      <Table striped={false} bordered={true} hover={true}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Type</th>
            <th>Uploaded at</th>
          </tr>
        </thead>
        <tbody>
          {(list.data?.results ?? []).map((r) => (
            <TableRow key={r.id} selector={r.id} selectedState={selectedState}>
              <td>
                <a
                  href={`/api/v1/files/${r.id}/download`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {r.name}
                </a>
                <br />
                <StatusButton variant="cyan">{r.mime}</StatusButton>
              </td>
              <td>{r.description}</td>
              <td>
                <StatusButton variant="secondary">{r.type}</StatusButton>
              </td>
              <td>
                <RelativeTimestamp value={r.createdAt} />
              </td>
            </TableRow>
          ))}
        </tbody>
      </Table>
      <Paginator
        skip={skip}
        take={query.take}
        total={list.data?.total}
        setSkip={setSkip}
      />
    </>
  )
}
