import {
  ActivityUnit,
  CreateOrderRequestSchema,
  Id,
  CreateOrderRequest,
} from "raml-lib"
import { useApiCreateOrder } from "../../api/raml"
import { FormDialog, ShowHideProps } from "../forms/FormDialog"
import { LocalTimeWidget } from "../forms/LocalTimeWidget"
import { SiteWidget } from "../forms/SiteWidget"
import { Link } from "react-router-dom"
import { useNotificationContext, Notification } from "../../notifications"
import { LocalDateWidget } from "../forms/LocalDateWidget"
import { OrderWidget } from "../forms/OrderWidget"
import { FormValidation } from "@rjsf/core"
import { useEffect, useState } from "react"
import { TextWidget } from "../forms/TextWidget"

type Props = ShowHideProps & Partial<{ siteId: Id }>

export const CommonToDisplayHideElement = (display: boolean): void => {
  //Distributor site
  const DistributorSiteElement = document.getElementById(
    "root_distributorSiteId"
  )
  if (DistributorSiteElement) {
    const parentElement = DistributorSiteElement.closest(".row")
    if (!display) {
      parentElement?.setAttribute("style", "display:none;")
    } else {
      parentElement?.setAttribute("style", "display:block;")
    }
  }
  //Dock Date
  const DockDateElement = document.getElementById("root_dockDate")
  if (DockDateElement) {
    const parentElement = DockDateElement.closest(".row")
    if (!display) {
      parentElement?.setAttribute("style", "display:none;")
    } else {
      parentElement?.setAttribute("style", "display:block;")
    }
  }
  //Dock Time
  const DockTimeElement = document.getElementById("root_dockTime")
  if (DockTimeElement) {
    const parentElement = DockTimeElement.closest(".row")
    if (!display) {
      parentElement?.setAttribute("style", "display:none;")
    } else {
      parentElement?.setAttribute("style", "display:block;")
    }
  }
}

export function CreateOrderDialog(props: Props) {
  const { siteId, ...others } = props
  const [customOrderRefError, setCustomOrderRefError] = useState<string>("")
  const [displayDistributorSiteId, setDisplayDistributorSiteId] =
    useState<boolean>(false)
  const { onNotification } = useNotificationContext()
  const { mutate } = useApiCreateOrder({
    onSuccess: (data) => {
      onNotification(
        new Notification(
          "Order created",
          (
            <p>
              Created{" "}
              <Link to={`/orders/${data.id}`}>{data.orderReference}</Link>
            </p>
          )
        )
      )
    },
  })

  useEffect(() => {
    CommonToDisplayHideElement(displayDistributorSiteId)
  }, [props, displayDistributorSiteId])

  const updatedMultipleNRTS = (formData: Partial<CreateOrderRequest>) => {
    setDisplayDistributorSiteId(!!formData.multipleNRTS)
  }

  const validate = (
    formData: Partial<CreateOrderRequest>,
    errors: FormValidation
  ) => {
    if (formData.multipleNRTS && !formData.distributorSiteId) {
      errors.distributorSiteId.addError(
        "Distributor site number is required when multiple NR-TS checkbox is checked."
      )
    }
    if (formData.multipleNRTS && !formData.dockDate) {
      errors.dockDate.addError(
        "Dock date is required when multiple NR-TS checkbox is checked."
      )
    }
    if (formData.multipleNRTS && !formData.dockTime) {
      errors.dockTime.addError(
        "Dock time is required when multiple NR-TS checkbox is checked."
      )
    }
    if (customOrderRefError) {
      errors.orderReference.addError(customOrderRefError)
    }
    return errors
  }

  return (
    <FormDialog
      schema={CreateOrderRequestSchema}
      uiSchema={{
        multipleNRTS: {
          "ui:widget": "checkbox",
        },
        siteId: {
          "ui:widget": SiteWidget,
        },
        distributorSiteId: {
          "ui:widget": SiteWidget,
        },
        orderReference: {
          "ui:widget": OrderWidget,
          "ui:options": {
            customError: setCustomOrderRefError,
          },
        },
        calibratedDate: {
          "ui:widget": LocalDateWidget,
        },
        shippedDate: {
          "ui:widget": LocalDateWidget,
        },
        dockDate: {
          "ui:widget": LocalDateWidget,
        },
        deliveredDate: {
          "ui:widget": LocalDateWidget,
        },
        treatmentDate: {
          "ui:widget": LocalDateWidget,
        },
        calibratedTime: {
          "ui:widget": LocalTimeWidget,
        },
        shippedTime: {
          "ui:widget": LocalTimeWidget,
        },
        dockTime: {
          "ui:widget": LocalTimeWidget,
        },
        deliveredTime: {
          "ui:widget": LocalTimeWidget,
        },
        treatmentTime: {
          "ui:widget": LocalTimeWidget,
        },
        activity: {
          "ui:widget": TextWidget,
        },
      }}
      onSubmit={mutate}
      data={{ activityUnit: ActivityUnit.GBQ, siteId }}
      validate={validate}
      updatedMultipleNRTS={updatedMultipleNRTS}
      source="CreateOrder"
      {...others}
    />
  )
}
