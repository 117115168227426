import { PropsWithChildren } from "react"

export function Paper(props: PropsWithChildren<{}>) {
  return (
    <div
      className="bg-white pt-5 pb-5 pl-5 pr-5"
      style={{
        borderTop: "3px solid #2c5697",
        borderBottom: "3px solid #2c5697",
        borderLeft: "0.5px solid #a09e9e",
        borderRight: "0.5px solid #a09e9e",
        boxShadow:
          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      }}
    >
      {props.children}
    </div>
  )
}
