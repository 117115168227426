import { Role } from "raml-lib"
import { PropsWithChildren } from "react"
import { useHasRole } from "../../api"

export function AdminOnly(props: PropsWithChildren<{}>) {
  const authorised = useHasRole(Role.ADMIN)

  if (authorised) {
    return <>{props.children}</>
  } else {
    return null
  }
}
