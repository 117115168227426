import { Redirect, Route, Switch } from "react-router"
import { AppLayout } from "./components/layout/AppLayout"
import { PlainLayout } from "./components/layout/PlainLayout"
import { AuthenticatedRoute } from "./components/router/AuthenticatedRoute"
import { UnauthenticatedRoute } from "./components/router/UnauthenticatedRoute"
import { HomePage } from "./pages/HomePage"
import { LoginPage } from "./pages/LoginPage"

import "./App.scss"
import { Link } from "react-router-dom"
import { MePage } from "./pages/MePage"
import { ApiKeysPage } from "./pages/ApiKeysPage"
import { UsersPage } from "./pages/UsersPage"
import { SitePage } from "./pages/SitePage"
import { SitesPage } from "./pages/SitesPage"
import { LicensePage } from "./pages/LicensePage"
import { LicensesPage } from "./pages/LicensesPage"
import { OrderPage } from "./pages/OrderPage"
import { OrdersPage } from "./pages/OrdersPage"
import { ReportCheckLicensePage } from "./pages/ReportCheckLicensePage"
import { ReportExpiringLicensesPage } from "./pages/ReportExpiringLicensesPage"
import { ReportLicenseQuantityPage } from "./pages/ReportLicenseQuantityPage"
import { NotificationProvider } from "./notifications"

export function App() {
  return (
    <Switch>
      <UnauthenticatedRoute path="/login">
        <PlainLayout>
          <LoginPage />
        </PlainLayout>
      </UnauthenticatedRoute>
      <AuthenticatedRoute path="/">
        <NotificationProvider>
          <AppLayout>
            <Switch>
              <Route path="/apikeys">
                <ApiKeysPage />
              </Route>
              <Route path="/licenses/:id">
                <LicensePage />
              </Route>
              <Route path="/licenses">
                <LicensesPage />
              </Route>
              <Route path="/orders/:id">
                <OrderPage />
              </Route>
              <Route path="/orders">
                <OrdersPage />
              </Route>
              <Route path="/reports/issues">
                <ReportLicenseQuantityPage />
              </Route>
              <Route path="/reports/check">
                <ReportCheckLicensePage />
              </Route>
              <Route path="/reports/expiring">
                <ReportExpiringLicensesPage />
              </Route>
              <Route path="/sites/:id">
                <SitePage />
              </Route>
              <Route path="/sites">
                <SitesPage />
              </Route>
              <Route path="/users">
                <UsersPage />
              </Route>
              <Route path="/me">
                <MePage />
              </Route>
              <Route path="/home">
                <HomePage />
              </Route>
              <Route exact={true} path="/">
                <Redirect to="/home" />
              </Route>
              <Route>
                <h1>Sorry, that page was not found.</h1>
                <p>
                  Please go back to the <Link to="/">Home page</Link>.
                </p>
              </Route>
            </Switch>
          </AppLayout>
        </NotificationProvider>
      </AuthenticatedRoute>
    </Switch>
  )
}
