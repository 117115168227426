"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchStringSchema = exports.LocalTimeSchema = exports.LocalDateSchema = exports.IdParamsSchema = exports.UPDATE_MODEL_META_SCHEMA = exports.MODEL_META_SCHEMA = exports.MultipleNRTSSchema = exports.EnabledSchema = exports.ActivityUnitSchema = exports.ActivityUnit = exports.ActivitySchema = exports.StringSchema = exports.TimestampSchema = exports.RoleSchema = exports.Role = exports.PasswordSchema = exports.EmailSchema = exports.DescriptionSchema = exports.NameSchema = exports.IdSchema = void 0;
var typebox_1 = require("@sinclair/typebox");
var utils_1 = require("../utils");
var utils_2 = require("./utils");
// Assume that no order before year 2000 (so timestamps like 0 are invalid, and as are second since epoch)
var MIN_TIMESTAMP = (0, utils_1.toTimestamp)(new Date("2000/01/01 00:00:00 UTC"));
// Provide a maximum just so if people send nano time, its an error
var MAX_TIMESTAMP = (0, utils_1.toTimestamp)(new Date("2100/01/01 00:00:00 UTC"));
exports.IdSchema = typebox_1.Type.Integer({
    title: "Id",
    description: "Unique reference internal to the system",
    minimum: 0,
    examples: [123],
});
exports.NameSchema = typebox_1.Type.String({
    minLength: 1,
    maxLength: 256,
    title: "Name",
    description: "Short friendly name",
    examples: ["Example"],
});
exports.DescriptionSchema = typebox_1.Type.String({
    minLength: 0,
    maxLength: 1024,
    title: "Description",
    description: "Longer description of the item",
    examples: ["A longer example"],
});
exports.EmailSchema = typebox_1.Type.String({
    format: "email",
    examples: ["user@example.com"],
    title: "Email",
    description: "Email address",
    maxLength: 256,
});
exports.PasswordSchema = typebox_1.Type.String({
    title: "Password",
    description: "Password",
    minLength: 6,
    maxLength: 256,
    examples: ["C0mpl3x!"],
});
// Aligns to DB Role
var Role;
(function (Role) {
    Role["ADMIN"] = "ADMIN";
    Role["EDITOR"] = "EDITOR";
    Role["READER"] = "READER";
})(Role = exports.Role || (exports.Role = {}));
exports.RoleSchema = (0, utils_2.enumSchema)(typebox_1.Type.Enum(Role), {
    title: "Role",
    description: "The role (permission level) of the user or API key",
    examples: [Role.EDITOR],
});
exports.TimestampSchema = typebox_1.Type.Integer({
    minimum: MIN_TIMESTAMP,
    maximum: MAX_TIMESTAMP,
    examples: [(0, utils_1.toTimestamp)(new Date("2021/08/01 09:00:00"))],
    title: "Timestamp",
    description: "Timestamp in seconds since Unix Epoch",
});
exports.StringSchema = typebox_1.Type.String({
    title: "String",
    description: "String",
    examples: ['fg'],
});
exports.ActivitySchema = typebox_1.Type.Number({
    minimum: 0,
    title: "Activity",
    description: "The Activity measurement (in GBQ, CI, mCI)",
    examples: [12.3],
});
// Aligns to the DB ActivityUnit
var ActivityUnit;
(function (ActivityUnit) {
    ActivityUnit["MILLI_CI"] = "MILLI_CI";
    ActivityUnit["CI"] = "CI";
    ActivityUnit["GBQ"] = "GBQ";
})(ActivityUnit = exports.ActivityUnit || (exports.ActivityUnit = {}));
exports.ActivityUnitSchema = (0, utils_2.enumSchema)(typebox_1.Type.Enum(ActivityUnit), {
    title: "Activity Unit",
    description: "Defines the unit in which Activity is measured",
    examples: [ActivityUnit.GBQ],
});
exports.EnabledSchema = typebox_1.Type.Boolean({
    title: "Enabled",
    description: "Is the item enabled / able to be used?",
    examples: [true],
});
exports.MultipleNRTSSchema = typebox_1.Type.Boolean({
    title: "Multiple NR-TS",
    description: "Please select this if the order requires 2 NR-TS. This is the case for some Customer Via Distributor orders.",
    examples: [true],
});
/**
 * Additional information about the model from the database
 */
exports.MODEL_META_SCHEMA = {
    id: exports.IdSchema,
    createdAt: exports.TimestampSchema,
    updatedAt: exports.TimestampSchema,
};
/**
 * Model metadata which could be sent on PUT / update.
 *
 * We don't want to return BadRequest if a client was to call  GET and then send everything to PUT.
 *
 * We might move to require updatedAt since we could use that to verify the 'version' of the data hasn't changed.
 */
exports.UPDATE_MODEL_META_SCHEMA = {
    id: typebox_1.Type.Optional(exports.IdSchema),
    createdAt: typebox_1.Type.Optional(exports.TimestampSchema),
    updatedAt: typebox_1.Type.Optional(exports.TimestampSchema),
};
exports.IdParamsSchema = (0, utils_2.strictObject)({
    id: exports.IdSchema,
}, { title: "Id", description: "Id in a query parameter" });
exports.LocalDateSchema = typebox_1.Type.String({
    title: "Local Date",
    description: "The date in location's timezone",
    format: "date",
    // By adding an example Chrome to display this as an option!
    // But we remove these through a widget
    examples: ["2021-09-15"],
    // Ajv supports this (but may not be passed through by Typebox)
    formatMinimum: "2000-01-01",
    formatMaximum: "2100-12-31",
});
exports.LocalTimeSchema = typebox_1.Type.String({
    title: "Local Time",
    description: "The time in location's timezone",
    format: "regex",
    pattern: "^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$",
    // By adding an example Chrome to display this as an option!
    // But we remove these through a widget
    examples: ["22:34"],
    minLength: 5,
    maxLength: 5,
});
exports.SearchStringSchema = typebox_1.Type.String({
    title: "Search",
    description: "Matches by strings starting with this value",
    minLength: 1,
    maxLength: 100,
    examples: ["ex"],
});
