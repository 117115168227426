import { ChangePasswordRequestSchema, IdParams } from "raml-lib"
import { useState } from "react"
import { Alert } from "react-bootstrap"
import { useApiChangePassword } from "../../api"
import { Notification, useNotificationContext } from "../../notifications"
import { FormDialog, ShowHideProps } from "../forms/FormDialog"

type Props = ShowHideProps & IdParams

export function ChangeUserPasswordDialog({ id, onHide, ...others }: Props) {
  const { onNotification } = useNotificationContext()
  const [message, setMessage] = useState<string | undefined>(undefined)
  const { mutate } = useApiChangePassword(id, {
    onSuccess: () => {
      onHide && onHide()
      onNotification(
        new Notification(
          "Password changed",
          (
            <p>
              Your password has been changed. To test your new password, logout
              and then login again.
            </p>
          )
        )
      )
    },
    onError: () => {
      setMessage(
        "Unable to change your password. Is your old password correct? Is your new password suitably complex?"
      )
    },
  })

  // TODO: Typically you'd have to confirm the newPassword in the form

  return (
    <FormDialog
      schema={ChangePasswordRequestSchema}
      uiSchema={{
        oldPassword: {
          "ui:widget": "password",
        },
        newPassword: {
          "ui:widget": "password",
        },
      }}
      onSubmit={mutate}
      onCancel={onHide}
      submit="Change password"
      {...others}
    >
      {message && <Alert variant="danger">{message}</Alert>}
    </FormDialog>
  )
}
