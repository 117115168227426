import { Table } from "react-bootstrap"
import { Id, LicenseStatus, ListLicenseQuery } from "raml-lib"
import { useState, useEffect, useMemo } from "react"
import { TableRow } from "../misc/TableRow"
import { RelativeTimestamp } from "../brand/RelativeTimestamp"
import { Timestamp } from "../brand/Timestamp"
import { FilterBar } from "../misc/FilterBar"
import { useApiListLicenses } from "../../api"
import { Paginator } from "../misc/Paginator"
import { DEFAULT_TAKE } from "../../constants"
import { StatusButton } from "../brand/StatusButton"
import dayjs from "dayjs"
import { Link } from "react-router-dom"
import { ReactNullableUseState } from "../../types"

function licenseStatusToColor(status: LicenseStatus | string): string {
  switch (status) {
    case LicenseStatus.ACTIVE:
      return "green"
    case LicenseStatus.TIMELY_RENEWAL:
      return "blue"
    case LicenseStatus.INACTIVE:
      return "red"
    case LicenseStatus.SUSPENDED:
      return "yellow"
    default:
      return "grey"
  }
}

type LicenseTableProps = Partial<ListLicenseQuery> & {
  selectedState?: ReactNullableUseState<Id>
}

export function LicenseTable(props: LicenseTableProps) {
  const {
    selectedState,
    take = DEFAULT_TAKE,
    status,
    siteId,
    licenseReference,
  } = props

  const [filter, setFilter] = useState<
    | LicenseStatus.ACTIVE
    | LicenseStatus.INACTIVE
    | LicenseStatus.SUSPENDED
    | LicenseStatus.TIMELY_RENEWAL
    | "all"
  >("all")

  const [skip, setSkip] = useState(0)

  const query: ListLicenseQuery = useMemo(() => {
    const q = {
      siteId,
      licenseReference,
      status,
      take: take,
      skip: skip,
    }
    if (filter !== "all") {
      q.status = filter
    }
    return q
  }, [filter, skip, take, status, siteId, licenseReference])
  const list = useApiListLicenses(query)

  // If the search query  / skip changes, we remove selection
  const setSelectedState = selectedState && selectedState[1]
  useEffect(() => {
    if (setSelectedState) {
      setSelectedState(null)
    }
  }, [query, setSelectedState])

  const now = dayjs()

  return (
    <>
      <FilterBar
        filter={filter}
        onFilter={setFilter}
        options={{
          all: "All",
          [LicenseStatus.ACTIVE]: "Active",
          [LicenseStatus.TIMELY_RENEWAL]: "Timely Renewal",
          [LicenseStatus.SUSPENDED]: "Suspended",
          [LicenseStatus.INACTIVE]: "Inactive",
        }}
      />
      <Table striped={false} bordered={true} hover={true}>
        <thead>
          <tr>
            <th>License Number</th>
            <th>Check at</th>
            <th>Expires at</th>
            <th>Status</th>
            <th>Created</th>
          </tr>
        </thead>
        <tbody>
          {(list.data?.results ?? []).map((r) => (
            <TableRow
              key={r.id}
              selector={r.id}
              selectedState={props.selectedState}
              className={!now.isAfter(r.expiresAt) ? "table-danger" : ""}
            >
              <td>
                <Link to={`/licenses/${r.id}`}>{r.licenseReference}</Link>
              </td>
              <td>{r.checkAt && <Timestamp value={r.checkAt} />}</td>
              <td>
                <Timestamp value={r.expiresAt} />
              </td>
              <td>
                <StatusButton variant={licenseStatusToColor(r.status)}>
                  {r.status}
                </StatusButton>
              </td>
              <td>
                <RelativeTimestamp value={r.createdAt} />
              </td>
            </TableRow>
          ))}
        </tbody>
      </Table>
      <Paginator
        skip={skip}
        take={query.take}
        total={list.data?.total}
        setSkip={setSkip}
      />
    </>
  )
}
