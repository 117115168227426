import { WidgetProps } from "@rjsf/core"
import { Widgets } from "@rjsf/bootstrap-4"

export function TextWidget(props: WidgetProps) {
  const { onChange, schema, ...others } = props

  const handleOnChange = (value: any) => {
    if (schema.type === "number") {
      const isNumber = /^(undefined|[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+))$/
      if (isNumber.test(value)) {
        onChange(value)
      }
    } else {
      onChange(value)
    }
  }

  return (
    <>
      <Widgets.TextWidget
        {...others}
        schema={{ ...schema, type: "string" }}
        onChange={handleOnChange}
      />
    </>
  )
}
