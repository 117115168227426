"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateUserResponseSchema = exports.UpdateUserRequestSchema = exports.ResetPasswordResponseSchema = exports.ResetPasswordRequestSchema = exports.ChangePasswordResponseSchema = exports.ChangePasswordRequestSchema = exports.ListUserResponseSchema = exports.ListUserQuerySchema = exports.GetUserResponseSchema = exports.DeleteUserResponseSchema = exports.CreateUserResponseSchema = exports.CreateUserRequestSchema = exports.UserSchema = void 0;
var typebox_1 = require("@sinclair/typebox");
var crud_1 = require("../crud");
var common_1 = require("../common");
var utils_1 = require("../utils");
exports.UserSchema = (0, utils_1.strictObject)(__assign({ name: common_1.NameSchema, email: common_1.EmailSchema, role: common_1.RoleSchema, enabled: common_1.EnabledSchema }, common_1.MODEL_META_SCHEMA), { title: "User", description: "A user of the system" });
exports.CreateUserRequestSchema = (0, utils_1.strictObject)({
    name: common_1.NameSchema,
    email: common_1.EmailSchema,
    role: common_1.RoleSchema,
    enabled: common_1.EnabledSchema,
    password: common_1.PasswordSchema,
}, { title: "Create user", description: "Register a new user" });
exports.CreateUserResponseSchema = exports.UserSchema;
exports.DeleteUserResponseSchema = crud_1.DeleteResponseSchema;
exports.GetUserResponseSchema = exports.UserSchema;
exports.ListUserQuerySchema = typebox_1.Type.Intersect([
    crud_1.PaginationQuerySchema,
    typebox_1.Type.Object({
        email: typebox_1.Type.Optional(common_1.SearchStringSchema),
        enabled: typebox_1.Type.Optional(typebox_1.Type.Boolean()),
    }),
], {
    title: "User query",
    description: "Specify the pagination and query to match",
});
exports.ListUserResponseSchema = (0, crud_1.createListResponseSchema)(exports.UserSchema, crud_1.NoSortSchema);
exports.ChangePasswordRequestSchema = (0, utils_1.strictObject)({
    oldPassword: (0, utils_1.extendStringType)(common_1.PasswordSchema, {
        title: "Old Password",
        description: "Current password for the user",
    }),
    newPassword: (0, utils_1.extendStringType)(common_1.PasswordSchema, {
        title: "New Password",
        description: "New password for the user",
    }),
}, { title: "Change password", description: "Change password" });
exports.ChangePasswordResponseSchema = exports.UserSchema;
exports.ResetPasswordRequestSchema = (0, utils_1.strictObject)({
    password: (0, utils_1.extendStringType)(common_1.PasswordSchema, {
        title: "New Password",
        description: "New password for the user",
    }),
}, { title: "Reset password", description: "Reset password" });
exports.ResetPasswordResponseSchema = exports.UserSchema;
exports.UpdateUserRequestSchema = (0, utils_1.strictObject)(__assign({ name: common_1.NameSchema, role: common_1.RoleSchema, enabled: common_1.EnabledSchema }, common_1.UPDATE_MODEL_META_SCHEMA), { title: "Edit user", description: "Edit an existing user" });
exports.UpdateUserResponseSchema = exports.UserSchema;
