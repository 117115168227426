import React, { RefObject, useMemo, useRef } from "react"
import { MeResponse, CsrfTokenResponse } from "raml-lib"
import { useApiAuthMe, useApiGetCsrfToken } from "./raml"

export interface Api {
  csrf: RefObject<CsrfTokenResponse | undefined>
  baseUrl: string
  me: MeResponse | undefined

  updateMe(): void
}

const DEFAULT_API = {
  csrf: { current: undefined },
  me: undefined,
  baseUrl: "/api/v1/",

  updateMe: () => {
    // Do nothing - just for structure
  },
}

export const ApiContext = React.createContext<Api>(DEFAULT_API)

type ApiProviderProps = React.PropsWithChildren<{
  // Use undefined for hte default (get the user from the API).
  // In tests: Use null to force no user, or provide a valid user
  me?: MeResponse | null
  // Use undefined for default behaviour (get form API)
  // In tests: Use null to force no CSRF token, or provide a valid token.
  csrfToken?: CsrfTokenResponse | null
}>

export function ApiProvider(props: ApiProviderProps) {
  const forceMeFromProps = props.me !== undefined
  const forceCsrfFromProps = props.csrfToken !== undefined

  // We use a ref because we don't want the UI to react to a change in the CSRF token
  const csrfRef = useRef<CsrfTokenResponse | undefined>(
    props.csrfToken ?? undefined
  )

  useApiGetCsrfToken({
    // Refresh the CSRF every 30 minutes
    refetchInterval: 30 * 60 * 1000,
    onSuccess: (value) => {
      csrfRef.current = value
    },
  })

  const {
    data: meData,
    refetch: updateMe,
    error: meError,
    isFetched: isFetchedMe,
  } = useApiAuthMe({
    // Don't retry - otherwise we are likely to spam the API on login screen
    // Also the error is only reported after retry, and we want immediate response in we are logged out
    retry: 0,
  })

  const data = useMemo(() => {
    let meValue: MeResponse | undefined
    if (forceMeFromProps) {
      // If forced to use the props, then use it (null becomes undefined)
      meValue = props.me ?? undefined
    } else {
      // If error then we have no value, otherwise use the response
      meValue = meError ? undefined : meData
    }

    return {
      csrf: csrfRef,
      me: meValue,
      updateMe,
      baseUrl: DEFAULT_API.baseUrl,
    }
  }, [forceMeFromProps, csrfRef, meError, meData, updateMe, props])

  // Only proceed when we have our true authentication state and are ready to make calls
  // (Unless our props have been set to force us to continue)
  const ready =
    (forceCsrfFromProps || csrfRef.current) &&
    (forceMeFromProps || data.me || isFetchedMe)

  return (
    <ApiContext.Provider value={data}>
      {ready && props.children}
    </ApiContext.Provider>
  )
}
