"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertActivityUnits = void 0;
var common_1 = require("../dtos/common");
function convertActivityUnits(activity, fromUnit, toUnit) {
    if (activity == null) {
        return null;
    }
    if (fromUnit === toUnit) {
        return activity;
    }
    if (fromUnit === common_1.ActivityUnit.GBQ) {
        switch (toUnit) {
            case common_1.ActivityUnit.CI:
                return activity / 37;
            case common_1.ActivityUnit.MILLI_CI:
                return activity / 0.037;
        }
    }
    else if (fromUnit == common_1.ActivityUnit.CI) {
        switch (toUnit) {
            case common_1.ActivityUnit.GBQ:
                return activity * 37;
            case common_1.ActivityUnit.MILLI_CI:
                return activity * 1000;
        }
    }
    else if (fromUnit === common_1.ActivityUnit.MILLI_CI) {
        switch (toUnit) {
            case common_1.ActivityUnit.GBQ:
                return (activity * 37) / 1000;
            case common_1.ActivityUnit.CI:
                return activity / 1000;
        }
    }
    throw new Error("Unable to convert from " + fromUnit + " to " + toUnit);
}
exports.convertActivityUnits = convertActivityUnits;
