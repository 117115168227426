"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateLicenseResponseSchema = exports.UpdateLicenseRequestSchema = exports.ListLicenseResponseSchema = exports.ListLicenseQuerySchema = exports.GetLicenseResponseSchema = exports.DeleteLicenseResponseSchema = exports.CreateLicenseResponseSchema = exports.CreateLicenseRequestSchema = exports.LicenseSchema = exports.LicenseStatusSchema = exports.LicenseStatus = exports.LicenseCheckAtSchema = exports.LicenseAuthorisationExpiresAtSchema = exports.LicenseExpiresAtSchema = exports.MaximumIndividualDoseActivityAtDeliveryLimitSchema = exports.CurrentYearActivityLimitSchema = exports.CurrentMonthActivityLimitSchema = exports.CurrentWeekActivityLimitSchema = exports.YearlyActivityLimitSchema = exports.MonthlyActivityLimitSchema = exports.WeeklyActivityLimitSchema = exports.DeliveryActivityLimitDisabledSchema = exports.DeliveryActivityLimitSchema = exports.LicenseNotesSchema = exports.LicenseReferenceSchema = exports.LicenseAuthorisationNumberSchema = exports.IsotopeSchema = void 0;
var typebox_1 = require("@sinclair/typebox");
var crud_1 = require("../crud");
var common_1 = require("../common");
var utils_1 = require("../utils");
exports.IsotopeSchema = typebox_1.Type.String({
    title: "Isotope",
    description: "Isotope to which the license relates",
    minLength: 0,
    maxLength: 1024,
    examples: ["ABC"],
});
exports.LicenseAuthorisationNumberSchema = typebox_1.Type.String({
    title: "License authorisation number*",
    description: "Number on the authorisation license",
    minLength: 0,
    maxLength: 1024,
    examples: ["LIC-1235-EX"],
});
exports.LicenseReferenceSchema = typebox_1.Type.String({
    title: "License Number",
    description: "Number on the RAM licenses",
    minLength: 1,
    maxLength: 1024,
    examples: ["LIC-1235-EX"],
});
exports.LicenseNotesSchema = typebox_1.Type.String({
    title: "License Notes",
    description: "Additional information regarding the license",
    minLength: 0,
    maxLength: 500,
});
exports.DeliveryActivityLimitSchema = (0, utils_1.extendNumberType)(common_1.ActivitySchema, {
    title: "Delivery Activity Limit",
    description: "The maximum Activity which can be on-site at time of delivery",
});
exports.DeliveryActivityLimitDisabledSchema = typebox_1.Type.Boolean({
    title: "Delivery Activity Limit is not required",
    description: "Select this if license does not require a Delivery Activity Limit",
});
exports.WeeklyActivityLimitSchema = (0, utils_1.extendNumberType)(common_1.ActivitySchema, {
    title: "Last 7 days Activity Limit",
    description: "The maximum Activity which can be ordered in the last 7 days (before delivery)",
});
exports.MonthlyActivityLimitSchema = (0, utils_1.extendNumberType)(common_1.ActivitySchema, {
    title: "Last 30 days Activity Limit",
    description: "The maximum Activity which can be ordered in the last 30 days (before delivery)",
});
exports.YearlyActivityLimitSchema = (0, utils_1.extendNumberType)(common_1.ActivitySchema, {
    title: "Last 365 days Activity Limit",
    description: "The maximum Activity which can be ordered in the last 365 days (before delivery)",
});
exports.CurrentWeekActivityLimitSchema = (0, utils_1.extendNumberType)(common_1.ActivitySchema, {
    title: "Current Week Activity Limit",
    description: "The maximum Activity which can be ordered in the week of delivery",
});
exports.CurrentMonthActivityLimitSchema = (0, utils_1.extendNumberType)(common_1.ActivitySchema, {
    title: "Current Month Activity Limit",
    description: "The maximum Activity which can be ordered in the month of delivery",
});
exports.CurrentYearActivityLimitSchema = (0, utils_1.extendNumberType)(common_1.ActivitySchema, {
    title: "Current Year Activity Limit",
    description: "The maximum Activity which can be ordered in the year of delivery",
});
exports.MaximumIndividualDoseActivityAtDeliveryLimitSchema = (0, utils_1.extendNumberType)(common_1.ActivitySchema, {
    title: "Maximum Individual Dose Activity At Delivery Limit",
    description: 'The maximum individual dose activity at delivery limit',
});
exports.LicenseExpiresAtSchema = (0, utils_1.extendIntegerType)(common_1.TimestampSchema, {
    title: "License expiries at",
    description: "Timestamp at which the license will expire",
});
exports.LicenseAuthorisationExpiresAtSchema = (0, utils_1.extendIntegerType)(common_1.TimestampSchema, {
    title: "License authorisation expires at*",
    description: "Timestamp at which the license authorisation will expire",
});
exports.LicenseCheckAtSchema = (0, utils_1.extendIntegerType)(common_1.TimestampSchema, {
    title: "License check at",
    description: "Timestamp at which the license should be checked",
});
// Aligns to DB LicenseStatus
var LicenseStatus;
(function (LicenseStatus) {
    LicenseStatus["ACTIVE"] = "ACTIVE";
    LicenseStatus["INACTIVE"] = "INACTIVE";
    LicenseStatus["SUSPENDED"] = "SUSPENDED";
    LicenseStatus["TIMELY_RENEWAL"] = "TIMELY_RENEWAL";
})(LicenseStatus = exports.LicenseStatus || (exports.LicenseStatus = {}));
exports.LicenseStatusSchema = (0, utils_1.enumSchema)(typebox_1.Type.Enum(LicenseStatus), {
    title: "License status",
    description: "Status of the license",
    examples: [LicenseStatus.ACTIVE],
});
var SiteIdSchema = (0, utils_1.extendIntegerType)(common_1.IdSchema, {
    title: "Site",
    description: "Id for the site to which this licenses relates",
});
exports.LicenseSchema = (0, utils_1.strictObject)(__assign({ siteId: SiteIdSchema, licenseAuthorisationNumber: typebox_1.Type.Optional(exports.LicenseAuthorisationNumberSchema), licenseAuthorisationExpiresAt: typebox_1.Type.Optional(exports.LicenseAuthorisationExpiresAtSchema), licenseReference: exports.LicenseReferenceSchema, checkAt: typebox_1.Type.Optional(exports.LicenseCheckAtSchema), expiresAt: exports.LicenseExpiresAtSchema, isotope: exports.IsotopeSchema, activityUnit: common_1.ActivityUnitSchema, status: exports.LicenseStatusSchema, deliveryActivityLimit: typebox_1.Type.Optional(exports.DeliveryActivityLimitSchema), deliveryActivityLimitDisabled: typebox_1.Type.Optional(exports.DeliveryActivityLimitDisabledSchema), maximumIndividualDoseActivityAtDeliveryLimit: typebox_1.Type.Optional(exports.MaximumIndividualDoseActivityAtDeliveryLimitSchema), weeklyActivityLimit: typebox_1.Type.Optional(exports.WeeklyActivityLimitSchema), monthlyActivityLimit: typebox_1.Type.Optional(exports.MonthlyActivityLimitSchema), yearlyActivityLimit: typebox_1.Type.Optional(exports.YearlyActivityLimitSchema), currentWeekActivityLimit: typebox_1.Type.Optional(exports.CurrentWeekActivityLimitSchema), currentMonthActivityLimit: typebox_1.Type.Optional(exports.CurrentMonthActivityLimitSchema), currentYearActivityLimit: typebox_1.Type.Optional(exports.CurrentYearActivityLimitSchema), licenseNotes: typebox_1.Type.Optional(exports.LicenseNotesSchema) }, common_1.MODEL_META_SCHEMA), {
    title: "License",
    description: "A license to hold a certain quantity of material at a site",
});
exports.CreateLicenseRequestSchema = (0, utils_1.strictObject)({
    siteId: SiteIdSchema,
    licenseAuthorisationNumber: typebox_1.Type.Optional(exports.LicenseAuthorisationNumberSchema),
    licenseAuthorisationExpiresAt: typebox_1.Type.Optional(exports.LicenseAuthorisationExpiresAtSchema),
    licenseReference: exports.LicenseReferenceSchema,
    checkAt: typebox_1.Type.Optional(exports.LicenseCheckAtSchema),
    expiresAt: exports.LicenseExpiresAtSchema,
    isotope: exports.IsotopeSchema,
    activityUnit: common_1.ActivityUnitSchema,
    status: exports.LicenseStatusSchema,
    deliveryActivityLimit: typebox_1.Type.Optional(exports.DeliveryActivityLimitSchema),
    deliveryActivityLimitDisabled: typebox_1.Type.Optional(exports.DeliveryActivityLimitDisabledSchema),
    maximumIndividualDoseActivityAtDeliveryLimit: typebox_1.Type.Optional(exports.MaximumIndividualDoseActivityAtDeliveryLimitSchema),
    // weeklyActivityLimit: Type.Optional(WeeklyActivityLimitSchema),
    // monthlyActivityLimit: Type.Optional(MonthlyActivityLimitSchema),
    // yearlyActivityLimit: Type.Optional(YearlyActivityLimitSchema),
    currentWeekActivityLimit: typebox_1.Type.Optional(exports.CurrentWeekActivityLimitSchema),
    currentMonthActivityLimit: typebox_1.Type.Optional(exports.CurrentMonthActivityLimitSchema),
    currentYearActivityLimit: typebox_1.Type.Optional(exports.CurrentYearActivityLimitSchema),
    licenseNotes: typebox_1.Type.Optional(exports.LicenseNotesSchema),
}, { title: "Create license", description: "Create an new license" });
exports.CreateLicenseResponseSchema = exports.LicenseSchema;
exports.DeleteLicenseResponseSchema = crud_1.DeleteResponseSchema;
exports.GetLicenseResponseSchema = exports.LicenseSchema;
exports.ListLicenseQuerySchema = typebox_1.Type.Intersect([
    crud_1.PaginationQuerySchema,
    typebox_1.Type.Object({
        status: typebox_1.Type.Optional(exports.LicenseStatusSchema),
        siteId: typebox_1.Type.Optional(common_1.IdSchema),
        licenseReference: typebox_1.Type.Optional(common_1.SearchStringSchema),
    }),
], {
    title: "License query",
    description: "Specify the pagination and query to match",
});
exports.ListLicenseResponseSchema = (0, crud_1.createListResponseSchema)(exports.LicenseSchema, crud_1.NoSortSchema);
exports.UpdateLicenseRequestSchema = (0, utils_1.strictObject)(__assign({ siteId: SiteIdSchema, licenseAuthorisationNumber: typebox_1.Type.Optional(exports.LicenseAuthorisationNumberSchema), licenseAuthorisationExpiresAt: typebox_1.Type.Optional(exports.LicenseAuthorisationExpiresAtSchema), licenseReference: exports.LicenseReferenceSchema, checkAt: typebox_1.Type.Optional(exports.LicenseCheckAtSchema), expiresAt: exports.LicenseExpiresAtSchema, isotope: exports.IsotopeSchema, activityUnit: common_1.ActivityUnitSchema, status: exports.LicenseStatusSchema, deliveryActivityLimit: typebox_1.Type.Optional(exports.DeliveryActivityLimitSchema), deliveryActivityLimitDisabled: typebox_1.Type.Optional(exports.DeliveryActivityLimitDisabledSchema), maximumIndividualDoseActivityAtDeliveryLimit: typebox_1.Type.Optional(exports.MaximumIndividualDoseActivityAtDeliveryLimitSchema), 
    // weeklyActivityLimit: Type.Optional(WeeklyActivityLimitSchema),
    // monthlyActivityLimit: Type.Optional(MonthlyActivityLimitSchema),
    // yearlyActivityLimit: Type.Optional(YearlyActivityLimitSchema),
    currentWeekActivityLimit: typebox_1.Type.Optional(exports.CurrentWeekActivityLimitSchema), currentMonthActivityLimit: typebox_1.Type.Optional(exports.CurrentMonthActivityLimitSchema), currentYearActivityLimit: typebox_1.Type.Optional(exports.CurrentYearActivityLimitSchema), licenseNotes: typebox_1.Type.Optional(exports.LicenseNotesSchema) }, common_1.UPDATE_MODEL_META_SCHEMA), { title: "Update license", description: "Edit an existing license" });
exports.UpdateLicenseResponseSchema = exports.LicenseSchema;
