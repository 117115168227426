import { useEffect } from "react"
import { Modal } from "react-bootstrap"
import { Id } from "raml-lib"
import { useApiGenerateApiKeyToken } from "../../api"
import { PrimaryButton } from "../brand/PrimaryButton"

type GenerateApiTokenDialogProps = {
  id: Id
  shown: boolean
  onHide(): void
}

export function GenerateApiKeyTokenDialog({
  id,
  shown,
  onHide,
}: GenerateApiTokenDialogProps) {
  const { mutate, data } = useApiGenerateApiKeyToken(id)

  useEffect(() => {
    mutate({})
  }, [mutate])

  const token = data ? data.token : "...generating..."
  return (
    <Modal show={shown} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>API Key</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>The generated API token for this key is:</p>
        <pre>{token}</pre>
        <p>To use, add a HTTP Header:</p>
        <pre>Authorization: Bearer {token}</pre>
      </Modal.Body>

      <Modal.Footer>
        <PrimaryButton onClick={onHide}>Close</PrimaryButton>
      </Modal.Footer>
    </Modal>
  )
}
