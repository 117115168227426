import { Badge } from "react-bootstrap"
import { PropsWithChildren } from "react"

type StatusButtonProps = {
  variant: string
}

export function StatusButton({
  children,
  variant,
}: PropsWithChildren<StatusButtonProps>) {
  return (
    <Badge variant={variant} color="white">
      {children}
    </Badge>
  )
}
