import { Button, ButtonProps } from "react-bootstrap"
import { PropsWithChildren } from "react"

export type CancelButtonProps = Omit<ButtonProps, "variant">

export function CancelButton({
  children,
  ...others
}: PropsWithChildren<CancelButtonProps>) {
  return (
    <Button variant="red" style={{ borderRadius: 0 }} {...others}>
      {children}
    </Button>
  )
}
