import {
  UiSchema,
  ISubmitEvent,
  IChangeEvent,
  ErrorSchema,
  AjvError,
  FormValidation,
} from "@rjsf/core"
import { Modal } from "react-bootstrap"
import { JSONSchema7 } from "json-schema"
import { Form } from "@rjsf/bootstrap-4"
import { PrimaryButton } from "../brand/PrimaryButton"
import { CancelButton } from "../brand/CancelButton"
import { ToolBar } from "../misc/ToolBar"
import { useState, useCallback } from "react"

export type FormDialogProps<S> = React.PropsWithChildren<{
  schema: JSONSchema7
  uiSchema?: UiSchema
  transformErrors?: (errors: AjvError[]) => AjvError[]
  onSubmit(data: S): void
  data?: Partial<S>
  submit?: string
  shown: boolean
  onHide?(): void
  onCancel?(): void
  validate?: (formData: S, errors: FormValidation) => FormValidation
  updatedMultipleNRTS?: (formData: S) => void
  source?: string
  onFormChange?(formData: S): void
  warning?: string
  superHide?(): void
}>

export type ShowHideProps = Pick<FormDialogProps<unknown>, "shown" | "onHide" | "superHide">

export function FormDialog<S>({
  schema,
  uiSchema,
  transformErrors,
  onSubmit,
  data,
  submit = "Save",
  shown,
  onHide,
  onCancel,
  children,
  validate,
  updatedMultipleNRTS,
  source,
  onFormChange,
  warning,
}: FormDialogProps<S>) {
  const [formData, setFormData] = useState<Partial<S>>(data ?? {})

  const submitCallback = useCallback(
    (e: ISubmitEvent<S>) => {
      onSubmit(e.formData)
      if (onHide) {
        onHide()
      }
      if(source?.match(/^Create(Site|Order|License)$/)) setFormData({})
    },
    [onSubmit, onHide]
  )

  const handleChange = useCallback(
    (event: IChangeEvent<S>, es?: ErrorSchema) => {
      if (source === "CreateOrder" || source === "UpdateOrder") {
        // @ts-ignore
        if (formData.multipleNRTS !== event.formData.multipleNRTS) {
          // @ts-ignore
          event.formData.siteId = undefined
        }
        if (!updatedMultipleNRTS) {
          // @ts-ignore
          event.formData.distibutorSiteId = undefined
        }
        setFormData(event.formData)
        if (updatedMultipleNRTS) {
          updatedMultipleNRTS(event.formData)
        }
      } else if (source === "CreateLicense" || source === "UpdateLicense") {
        // @ts-ignore
        if (formData.siteId !== event.formData.siteId) {
          // @ts-ignore
          event.formData.licenseAuthorisationNumber = undefined
          // @ts-ignore
          event.formData.licenseAuthorisationExpiresAt = undefined
        }
        // @ts-ignore
        if(event.formData.deliveryActivityLimitDisabled) event.formData.deliveryActivityLimit = undefined
        setFormData(event.formData)
      } else if (source == "CreateSite" || source == "UpdateSite") {
        // @ts-ignore
        if (formData.country !== event.formData.country) {
          // @ts-ignore
          event.formData.timezone = undefined
        }
        setFormData(event.formData)
      } else {
        setFormData(event.formData)
      }
      if (onFormChange) onFormChange(event.formData)
    },
    [formData, setFormData]
  )

  const handleCancel = onCancel ?? onHide

  const onError = (errors: AjvError[]) => {
    var invalidElements = document.getElementsByClassName("is-invalid")
    if (invalidElements.length > 0) {
      var el: Element = invalidElements[0]
      document.getElementById(el.id)?.focus()
    }
  }
  return (
    <Modal show={shown} onHide={onHide}>
      <Modal.Body>
        <Form
          schema={schema}
          uiSchema={uiSchema}
          onSubmit={submitCallback}
          onChange={handleChange}
          formData={formData}
          transformErrors={transformErrors}
          onError={onError}
          validate={validate}
        >
          {children}
          <p className="text-danger">{warning}</p>
          <Modal.Footer>
            <ToolBar>
              {handleCancel && (
                <CancelButton onClick={handleCancel}>Cancel</CancelButton>
              )}
              <PrimaryButton type="submit">{submit}</PrimaryButton>
            </ToolBar>
          </Modal.Footer>
        </Form>
      </Modal.Body>

      {/* 
        Ideally the save button above would be out of body and into a footer
        <Modal.Footer>
        <Button variant="primary" type="submit">
              {submit}
            </Button>
          </Modal.Footer> */}
    </Modal>
  )
}
