import { Button, ButtonProps } from "react-bootstrap"
import { PropsWithChildren } from "react"

type SecondaryButtonProps = Omit<ButtonProps, "variant">

export function SecondaryButton({
  children,
  ...others
}: PropsWithChildren<SecondaryButtonProps>) {
  return (
    <Button variant="secondary" {...others}>
      {children}
    </Button>
  )
}
