import { WidgetProps } from "@rjsf/core"
import { Widgets } from "@rjsf/bootstrap-4"
import { useEffect, useState } from "react"
import dayjs from "dayjs"
import { toDate, toTimestamp } from "raml-lib"

/**
 * Input Timestamp as a Date
 *
 * Where we are currently using Timestamps we don't need time granularity, so this widget just provides
 * date level choice for the user.
 *
 * Note that we treating this as a 'local-date' which gets converted to a timestamp.
 * The timezone is likely unclear.
 *
 * For example, license expiry could be on a letter but what is the timezone?
 * Is the sites timezone? Is is the addresses timezone (perhaps a corporate HQ)? Is it the sender's timezone? Is it always UTC?
 *
 * So our timestamp is likely wrong in practise because we've ignored timezone, but it will make little difference (max 1-2 days)
 *
 * @param props
 * @returns
 */
export function TimestampDateWidget(props: WidgetProps) {
  const { value, onChange, ...others } = props

  const [stringValue, setStringValue] = useState<string | null>(
    value ? dayjs(toDate(value)).format("YYYY-MM-DD") : null
  )

  useEffect(() => {
    if (stringValue == null) {
      onChange(undefined)
    } else {
      try {
        let ts = toTimestamp(dayjs(stringValue).toDate())
        // It's possible that the user selects s time before epoch...
        // We definitely don't want negatives
        if (ts < 0) {
          ts = 0
        }
        onChange(ts)
      } catch (err) {
        // Ignore invalid dates
      }
    }
  }, [stringValue, onChange])

  return (
    <Widgets.DateWidget
      value={value ? stringValue : null}
      onChange={setStringValue}
      {...others}
    />
  )
}
