import { useCallback } from "react"
import { Route, Redirect, RouteProps } from "react-router-dom"
import { useAuthenticated } from "../../api"

export function AuthenticatedRoute({ children, ...rest }: RouteProps) {
  const authenticated = useAuthenticated()

  const render = useCallback(
    ({ location }) =>
      authenticated ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { next: location },
          }}
        />
      ),
    [authenticated, children]
  )

  return <Route {...rest} render={render} />
}
