"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a, _b, _c, _d, _e;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExportOrdersResponseSchema = exports.ExportOrdersRequestSchema = exports.ExportOrdersSchema = exports.UpdateOrderResponseSchema = exports.UpdateOrderRequestSchema = exports.ListOrderResponseSchema = exports.ListOrderQuerySchema = exports.GetOrderResponseSchema = exports.DeleteOrderResponseSchema = exports.CreateOrderResponseSchema = exports.CreateOrderRequestSchema = exports.OrderSchema = exports.OrderReferenceSchema = exports.DockAtSchema = exports.DockTimeSchema = exports.DockDateSchema = exports.TreatmentAtSchema = exports.TreatmentTimeSchema = exports.TreatmentDateSchema = exports.DeliveredAtSchema = exports.DeliveredTimeSchema = exports.DeliveredDateSchema = exports.ShippedAtSchema = exports.ShippedTimeSchema = exports.ShippedDateSchema = exports.CalibratedAtSchema = exports.CalibratedTimeSchema = exports.CalibratedDateSchema = exports.createDateTimeTimestampSchema = void 0;
var typebox_1 = require("@sinclair/typebox");
var common_1 = require("../common");
var crud_1 = require("../crud");
var sites_1 = require("../api/sites");
var utils_1 = require("../utils");
function createDateTimeTimestampSchema(text, appendText, isMandatory) {
    var defaultLocationText = appendText ? appendText + "'s" : "location's";
    return [
        (0, utils_1.extendStringType)(common_1.LocalDateSchema, {
            title: text + " date" + (appendText ? " at " + appendText + (isMandatory ? "*" : "") : ""),
            description: "The " + text.toLowerCase() + " date in the " + defaultLocationText + " timezone",
        }),
        (0, utils_1.extendStringType)(common_1.LocalTimeSchema, {
            title: text + " time" + (appendText ? " at " + appendText + (isMandatory ? "*" : "") : ""),
            description: "The " + text.toLowerCase() + " time in the " + defaultLocationText + " timezone",
        }),
        (0, utils_1.extendIntegerType)(common_1.TimestampSchema, {
            title: text + " at",
            description: "The " + text.toLowerCase() + " timestamp",
        }),
    ];
}
exports.createDateTimeTimestampSchema = createDateTimeTimestampSchema;
exports.CalibratedDateSchema = (_a = createDateTimeTimestampSchema("Calibrated"), _a[0]), exports.CalibratedTimeSchema = _a[1], exports.CalibratedAtSchema = _a[2];
exports.ShippedDateSchema = (_b = createDateTimeTimestampSchema("Shipped"), _b[0]), exports.ShippedTimeSchema = _b[1], exports.ShippedAtSchema = _b[2];
exports.DeliveredDateSchema = (_c = createDateTimeTimestampSchema("Delivered"), _c[0]), exports.DeliveredTimeSchema = _c[1], exports.DeliveredAtSchema = _c[2];
exports.TreatmentDateSchema = (_d = createDateTimeTimestampSchema("Treatment"), _d[0]), exports.TreatmentTimeSchema = _d[1], exports.TreatmentAtSchema = _d[2];
exports.DockDateSchema = (_e = createDateTimeTimestampSchema("Dock", "distributor", true), _e[0]), exports.DockTimeSchema = _e[1], exports.DockAtSchema = _e[2];
exports.OrderReferenceSchema = typebox_1.Type.String({
    title: "Order reference",
    description: "The unique order reference",
    minLength: 1,
    examples: ["TOP12356"],
});
var SiteIdSchema = (0, utils_1.extendIntegerType)(common_1.IdSchema, {
    title: "Site Number",
    description: "Id for the site to which this order will be delivered",
});
var DistributorSiteIdSchema = (0, utils_1.extendIntegerType)(common_1.IdSchema, {
    title: "Distributor Site Number*",
    description: "Id for the distributor site for this order",
});
exports.OrderSchema = (0, utils_1.strictObject)(__assign({ multipleNRTS: typebox_1.Type.Optional(common_1.MultipleNRTSSchema), siteId: SiteIdSchema, distributorSiteId: typebox_1.Type.Optional(DistributorSiteIdSchema), orderReference: exports.OrderReferenceSchema, activity: common_1.ActivitySchema, activityUnit: common_1.ActivityUnitSchema, calibratedAt: exports.CalibratedAtSchema, shippedAt: exports.ShippedAtSchema, deliveredAt: exports.DeliveredAtSchema, dockAt: typebox_1.Type.Optional(exports.DockAtSchema), treatmentAt: exports.TreatmentAtSchema, calibratedDate: exports.CalibratedDateSchema, calibratedTime: exports.CalibratedTimeSchema, shippedDate: exports.ShippedDateSchema, shippedTime: exports.ShippedTimeSchema, deliveredDate: exports.DeliveredDateSchema, deliveredTime: exports.DeliveredTimeSchema, dockDate: typebox_1.Type.Optional(exports.DockDateSchema), dockTime: typebox_1.Type.Optional(exports.DockTimeSchema), treatmentDate: exports.TreatmentDateSchema, treatmentTime: exports.TreatmentTimeSchema }, common_1.MODEL_META_SCHEMA), { title: "Order", description: "An order with a known activity level" });
exports.CreateOrderRequestSchema = (0, utils_1.strictObject)({
    multipleNRTS: typebox_1.Type.Optional(common_1.MultipleNRTSSchema),
    siteId: SiteIdSchema,
    distributorSiteId: typebox_1.Type.Optional(DistributorSiteIdSchema),
    orderReference: exports.OrderReferenceSchema,
    activity: common_1.ActivitySchema,
    activityUnit: common_1.ActivityUnitSchema,
    calibratedDate: exports.CalibratedDateSchema,
    calibratedTime: exports.CalibratedTimeSchema,
    shippedDate: exports.ShippedDateSchema,
    shippedTime: exports.ShippedTimeSchema,
    deliveredDate: exports.DeliveredDateSchema,
    deliveredTime: exports.DeliveredTimeSchema,
    dockDate: typebox_1.Type.Optional(exports.DockDateSchema),
    dockTime: typebox_1.Type.Optional(exports.DockTimeSchema),
    treatmentDate: exports.TreatmentDateSchema,
    treatmentTime: exports.TreatmentTimeSchema,
    // Note you can not input the timestamps, they are always calculated
}, { title: "Create order", description: "Create an new order" });
exports.CreateOrderResponseSchema = exports.OrderSchema;
exports.DeleteOrderResponseSchema = crud_1.DeleteResponseSchema;
exports.GetOrderResponseSchema = exports.OrderSchema;
exports.ListOrderQuerySchema = typebox_1.Type.Intersect([
    crud_1.PaginationQuerySchema,
    typebox_1.Type.Object({
        siteId: typebox_1.Type.Optional(SiteIdSchema),
        distributorSiteId: typebox_1.Type.Optional(DistributorSiteIdSchema),
        orderReference: typebox_1.Type.Optional(common_1.SearchStringSchema),
    }),
], {
    title: "List order query",
    description: "Specify the pagination and query to match",
});
exports.ListOrderResponseSchema = (0, crud_1.createListResponseSchema)(exports.OrderSchema, crud_1.NoSortSchema);
exports.UpdateOrderRequestSchema = (0, utils_1.strictObject)(__assign({ multipleNRTS: typebox_1.Type.Optional(common_1.MultipleNRTSSchema), siteId: SiteIdSchema, distributorSiteId: typebox_1.Type.Optional(DistributorSiteIdSchema), orderReference: exports.OrderReferenceSchema, activity: common_1.ActivitySchema, activityUnit: common_1.ActivityUnitSchema, calibratedDate: exports.CalibratedDateSchema, calibratedTime: exports.CalibratedTimeSchema, shippedDate: exports.ShippedDateSchema, shippedTime: exports.ShippedTimeSchema, deliveredDate: exports.DeliveredDateSchema, deliveredTime: exports.DeliveredTimeSchema, dockDate: typebox_1.Type.Optional(exports.DockDateSchema), dockTime: typebox_1.Type.Optional(exports.DockTimeSchema), treatmentDate: exports.TreatmentDateSchema, treatmentTime: exports.TreatmentTimeSchema }, common_1.UPDATE_MODEL_META_SCHEMA), { title: "Update order", description: "Edit an existing order" });
exports.UpdateOrderResponseSchema = exports.OrderSchema;
exports.ExportOrdersSchema = (0, utils_1.strictObject)({
    topNumber: exports.OrderReferenceSchema,
    activityAtCalibration: common_1.ActivitySchema,
    // orderActivityAtDelivery: ActivitySchema,
    // totalActivityAtDelivery: ActivitySchema,
    timezone: sites_1.TimezoneSchema,
    doseQuantity: common_1.NameSchema,
    calibratedDate: exports.CalibratedDateSchema,
    shippedTo: sites_1.SiteNameSchema,
    shippedToId: sites_1.SiteReferenceSchema,
    shippedAt: exports.ShippedDateSchema,
    deliveredDate: exports.DeliveredDateSchema,
    dockDate: exports.DockDateSchema,
    treatmentDate: exports.TreatmentDateSchema,
    createdAt: common_1.TimestampSchema,
    orderUpdatedAt: common_1.TimestampSchema
}, { title: "Export Orders", description: "Export orders" });
exports.ExportOrdersRequestSchema = (0, utils_1.strictObject)({}, {
    title: "Order export query",
    description: "Specifiy order export query",
});
exports.ExportOrdersResponseSchema = crud_1.ExportResponseSchema;
