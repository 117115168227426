"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExportSitesResponseSchema = exports.ExportSitesRequestSchema = exports.UpdateSiteResponseSchema = exports.UpdateSiteRequestSchema = exports.ListSiteResponseSchema = exports.ListSiteQuerySchema = exports.GetSiteResponseSchema = exports.DeleteSiteResponseSchema = exports.CreateSiteResponseSchema = exports.CreateSiteRequestSchema = exports.ExportSitesSchema = exports.SiteSchema = exports.DistributorSiteSchema = exports.TimezoneSchema = exports.CustomerTypeSchema = exports.CustomerType = exports.CountrySchema = exports.AddressSchema = exports.CustomerNameSchema = exports.CustomerAccountSchema = exports.ActivitySiteReferenceSchema = exports.SiteReferenceSchema = exports.SiteNameSchema = void 0;
var typebox_1 = require("@sinclair/typebox");
var common_1 = require("../common");
var crud_1 = require("../crud");
var licenses_1 = require("./licenses");
var utils_1 = require("../utils");
exports.SiteNameSchema = (0, utils_1.extendStringType)(common_1.NameSchema, {
    title: "Ship-To Customer Site name",
    description: "Friendly name for the customer site which is the shipping destination",
    examples: ["Example Medical Unit"],
});
exports.SiteReferenceSchema = typebox_1.Type.String({
    title: "Site Number (NR-TS)",
    description: "The site location reference code must begin with 'NR-TS-'",
    minLength: 1,
    maxLength: 1024,
    examples: ["NR-TS-1234"],
    pattern: "^(NR)-(TS)-(?:([A-Z]+-))?[0-9]+$",
});
exports.ActivitySiteReferenceSchema = typebox_1.Type.String({
    title: "Site Number (NR-TS)",
    description: "The site location reference code must begin with 'NR-TS-'",
    minLength: 1,
    maxLength: 1024,
    examples: ["NR-TS-1234"],
    pattern: "^(NR)-(TS)-(?:([A-Z]+-))?[0-9]+$",
});
exports.CustomerAccountSchema = typebox_1.Type.String({
    title: "Customer account",
    description: "Customer account reference code",
    minLength: 0,
    maxLength: 1024,
    examples: ["12345"],
    default: "",
});
exports.CustomerNameSchema = (0, utils_1.extendStringType)(common_1.NameSchema, {
    title: "Doing Business As (DBA) name",
    description: "Friendly name for the customer",
    minLength: 0,
    maxLength: 1024,
    examples: ["Example Medical Plc"],
});
exports.AddressSchema = typebox_1.Type.String({
    title: "Address",
    description: "Address of the site",
    minLength: 0,
    maxLength: 1024,
    examples: ["123 Example Street, Example, EX123"],
});
exports.CountrySchema = typebox_1.Type.String({
    title: "Country",
    description: "Country where the site is located",
    minLength: 1,
    maxLength: 3,
    examples: ["GB"],
});
var CustomerType;
(function (CustomerType) {
    CustomerType["CUSTOMERS"] = "customers";
    CustomerType["DISTRIBUTORS"] = "distributors";
    CustomerType["ALL"] = "all";
})(CustomerType = exports.CustomerType || (exports.CustomerType = {}));
exports.CustomerTypeSchema = (0, utils_1.enumSchema)(typebox_1.Type.Enum(CustomerType), {
    title: "Customer Type",
    description: "Different customer types",
    examples: [CustomerType.CUSTOMERS],
});
exports.TimezoneSchema = typebox_1.Type.String({
    title: "Timezone",
    description: "The timezone in which the site is located",
    // TODO Ideally this would be a regex, but the pattern does not seem to match
    // format: "regex",
    // pattern: "^[+-](0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$",
    examples: [
        "America/Maceio",
        "America/Managua",
        "America/Manaus",
        "America/Marigot",
    ],
});
exports.DistributorSiteSchema = typebox_1.Type.Boolean({
    title: "Distributor Site",
    description: "Select if this if you are setting up a distributor site",
});
exports.SiteSchema = (0, utils_1.strictObject)(__assign({ siteReference: exports.SiteReferenceSchema, siteName: exports.SiteNameSchema, distributorSite: typebox_1.Type.Optional(exports.DistributorSiteSchema), address: exports.AddressSchema, country: exports.CountrySchema, timezone: exports.TimezoneSchema, customerName: exports.CustomerNameSchema, customerAccount: exports.CustomerAccountSchema }, common_1.MODEL_META_SCHEMA), { title: "Site", description: "A site to deliver orders to" });
exports.ExportSitesSchema = (0, utils_1.strictObject)({
    siteReference: exports.SiteReferenceSchema,
    siteName: exports.SiteNameSchema,
    distributorSite: typebox_1.Type.Optional(exports.DistributorSiteSchema),
    address: exports.AddressSchema,
    timezone: exports.TimezoneSchema,
    country: exports.CountrySchema,
    customerName: exports.CustomerNameSchema,
    licenseReference: typebox_1.Type.Optional(licenses_1.LicenseReferenceSchema),
    licenseCheckAt: typebox_1.Type.Optional(licenses_1.LicenseCheckAtSchema),
    licenseExpiresAt: typebox_1.Type.Optional(licenses_1.LicenseExpiresAtSchema),
    licenseStatus: typebox_1.Type.Optional(licenses_1.LicenseStatusSchema),
    licenseCreatedAt: typebox_1.Type.Optional(common_1.TimestampSchema),
    licenseUpdatedAt: typebox_1.Type.Optional(common_1.TimestampSchema),
    deliveryActivityLimit: typebox_1.Type.Optional(licenses_1.DeliveryActivityLimitSchema),
    deliveryActivityLimitDisabled: typebox_1.Type.Optional(licenses_1.DeliveryActivityLimitDisabledSchema),
    currentWeekActivityLimit: typebox_1.Type.Optional(licenses_1.CurrentWeekActivityLimitSchema),
    currentMonthActivityLimit: typebox_1.Type.Optional(licenses_1.CurrentMonthActivityLimitSchema),
    currentYearActivityLimit: typebox_1.Type.Optional(licenses_1.CurrentYearActivityLimitSchema),
    weeklyActivityLimit: typebox_1.Type.Optional(licenses_1.WeeklyActivityLimitSchema),
    monthlyActivityLimit: typebox_1.Type.Optional(licenses_1.MonthlyActivityLimitSchema),
    yearlyActivityLimit: typebox_1.Type.Optional(licenses_1.YearlyActivityLimitSchema),
    // customerAccount: CustomerAccountSchema,
    // ...MODEL_META_SCHEMA,
}, { title: "Export Sites", description: "Export Sites with Licenses" });
exports.CreateSiteRequestSchema = (0, utils_1.strictObject)({
    siteReference: exports.SiteReferenceSchema,
    siteName: exports.SiteNameSchema,
    distributorSite: typebox_1.Type.Optional(exports.DistributorSiteSchema),
    address: exports.AddressSchema,
    country: exports.CountrySchema,
    timezone: exports.TimezoneSchema,
    customerName: exports.CustomerNameSchema,
    customerAccount: typebox_1.Type.Optional(exports.CustomerAccountSchema),
}, { title: "Create site", description: "Create a new site" });
exports.CreateSiteResponseSchema = exports.SiteSchema;
exports.DeleteSiteResponseSchema = crud_1.DeleteResponseSchema;
exports.GetSiteResponseSchema = exports.SiteSchema;
exports.ListSiteQuerySchema = typebox_1.Type.Intersect([
    crud_1.PaginationQuerySchema,
    typebox_1.Type.Object({
        // These are OR (you match need only one, if you supply multiple)
        customerAccount: typebox_1.Type.Optional(common_1.SearchStringSchema),
        siteReference: typebox_1.Type.Optional(common_1.SearchStringSchema),
        siteName: typebox_1.Type.Optional(common_1.SearchStringSchema),
        customerName: typebox_1.Type.Optional(common_1.SearchStringSchema),
        type: typebox_1.Type.Optional(exports.CustomerTypeSchema),
        countries: typebox_1.Type.Optional(common_1.StringSchema),
    }),
], {
    title: "Site query",
    description: "Specify the pagination and query to match",
});
exports.ListSiteResponseSchema = (0, crud_1.createListResponseSchema)(exports.SiteSchema, crud_1.NoSortSchema);
exports.UpdateSiteRequestSchema = (0, utils_1.strictObject)(__assign({ siteReference: exports.SiteReferenceSchema, siteName: exports.SiteNameSchema, distributorSite: typebox_1.Type.Optional(exports.DistributorSiteSchema), address: exports.AddressSchema, country: exports.CountrySchema, timezone: exports.TimezoneSchema, customerName: exports.CustomerNameSchema, customerAccount: typebox_1.Type.Optional(exports.CustomerAccountSchema) }, common_1.UPDATE_MODEL_META_SCHEMA), { title: "Update site", description: "Edit an existing site" });
exports.UpdateSiteResponseSchema = exports.SiteSchema;
exports.ExportSitesRequestSchema = (0, utils_1.strictObject)({}, {
    title: "Site query",
    description: "Specify the pagination and query to match",
});
exports.ExportSitesResponseSchema = crud_1.ExportResponseSchema;
