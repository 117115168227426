import React, { useCallback, useState, useEffect } from "react"
import { Form, FormControl } from "react-bootstrap"
import { useDebounce } from "@committed/hooks"

type SearchBarProps = {
  placeholder?: string
  onQuery(query: string | undefined): void
  minimumLength?: number
  debounceDelay?: number
}

export function SearchBar({
  placeholder = "Search",
  onQuery,
  minimumLength = 1,
  debounceDelay = 250,
}: SearchBarProps) {
  const [query, setQuery] = useState<string | null>(null)
  const [debouncedQuery, flush] = useDebounce(query, debounceDelay)

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      flush()
    },
    [flush]
  )
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setQuery(event.target.value ?? "")
    },
    [setQuery]
  )

  useEffect(() => {
    if (debouncedQuery != null && debouncedQuery.length >= minimumLength) {
      onQuery(debouncedQuery)
    } else {
      onQuery(undefined)
    }
  }, [debouncedQuery, onQuery, minimumLength])

  return (
    <Form className="mb-3" onSubmit={handleSubmit}>
      <FormControl
        name="query"
        type="search"
        placeholder={placeholder}
        aria-label="Search"
        value={query ?? ""}
        onChange={handleChange}
      />
    </Form>
  )
}
