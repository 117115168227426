import { useHistory, useParams } from "react-router"
import {Breadcrumb, Row, Col, Accordion, Card, Button} from "react-bootstrap"
import { LinkContainer } from "react-router-bootstrap"
import { useApiGetOrder, useApiDeleteOrder } from "../api/raml"
import { InfoPane, InfoPaneProps } from "../components/misc/InfoPane"
import { SecondaryButton } from "../components/brand/SecondaryButton"
import { Timestamp } from "../components/brand/Timestamp"
import { FormatDatetime } from "../components/brand/FormatDatetime"
import { ToolBar } from "../components/misc/ToolBar"
import { SitePreview } from "../components/sites/SitePreview"
import { DistributorSitePreview } from "../components/sites/DistributorSitePreview"
import { RecheckOrderActivity } from "../components/activity/RecheckOrderActivity"
import React, { useCallback } from "react"
import { useModal } from "../hooks/useModal"
import { UpdateOrderDialog } from "../components/orders/UpdateOrderDialog"
import { useNotificationContext, Notification } from "../notifications"
import { DeleteButton } from "../components/delete/DeleteButton"
import { EditorOnly } from "../components/auth/EditorOnly"

export function OrderPage() {
  const { id } = useParams<{ id: string }>()
  const orderId = parseInt(id)
  const { onNotification } = useNotificationContext()

  const { data: order } = useApiGetOrder(orderId)
  const { mutate: mutateDelete } = useApiDeleteOrder(orderId, {
    onSuccess: (data) => {
      onNotification(
        new Notification(
          "Order deleted",
          <p>Deleted order {order?.orderReference || orderId}</p>
        )
      )
    },
  })
  const history = useHistory()
  const [editModal, hideEditModal, showEditModal] = useModal()
  // @ts-ignore
  const onAccordionToggle = (e) => {
    e.currentTarget.children[0].classList.toggle("collapsed")
  };


  const deleteOrder = useCallback(() => {
    mutateDelete(
      {},
      {
        onSuccess: () => history.push("/orders"),
      }
    )
  }, [mutateDelete, history])

  const getInfoPaneData = (): InfoPaneProps => {
    let infoPaneData: InfoPaneProps = {
      data: []
    }
    if(order){
      infoPaneData.data = [
        {
          key: "Reference",
          value: order.orderReference,
        },
        {
          key: "Activity",
          value: order.activity,
        },
        {
          key: "Activity units",
          value: order.activityUnit,
        },
        {
          key: "Calibrated at",
          value: <Timestamp value={order.calibratedAt} />,
        },
        {
          key: "Shipped at",
          value: <Timestamp value={order.shippedAt} />,
        },
        {
          key: "Delivery Date at Destination",
          value: (
            <FormatDatetime
              date={order.deliveredDate}
              time={order.deliveredTime}
            />
          ),
        },
        {
          key: "Dock Date at Distributor",
          value: order.dockDate && order.dockTime && (
            <FormatDatetime
              date={order.dockDate}
              time={order.dockTime}
            />
          ),
        },
        {
          key: "Treatment at",
          value: <Timestamp value={order.treatmentAt} />,
        },
        {
          key: "Created at",
          value: <Timestamp value={order.createdAt} />,
        },
        {
          key: "Updated at",
          value: <Timestamp value={order.updatedAt} />,
        },
      ]

      if (!(order.dockTime || order.dockDate)) {
        infoPaneData.data = infoPaneData.data.filter(d => d.key !== "Dock Date at Distributor")
      }
    }

    return infoPaneData
  }

  return (
    <>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>Home</Breadcrumb.Item>
        </LinkContainer>
        <LinkContainer to="/orders">
          <Breadcrumb.Item>Orders</Breadcrumb.Item>
        </LinkContainer>
        <LinkContainer to={`/orders/${id}`}>
          <Breadcrumb.Item active>
            Order {order?.orderReference}
          </Breadcrumb.Item>
        </LinkContainer>
      </Breadcrumb>
      <UpdateOrderDialog
        id={orderId}
        shown={editModal}
        onHide={hideEditModal}
      />
      <h1>Order {order?.orderReference}</h1>
      <Row>
        <Col md={8}>
          {order && (
              <>
                <Accordion defaultActiveKey="0">
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Card.Header}
                                        variant="link" eventKey="0"
                                        onClick={onAccordionToggle}>
                        <h2 style={{marginTop: "0px", borderBottom:'none', cursor: "pointer"}}>Order Site Activity</h2>

                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <RecheckOrderActivity orderReference={order.orderReference} siteId={undefined} />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  { order?.distributorSiteId ?
                    <Card>
                      <Card.Header>
                        <Accordion.Toggle as={Card.Header}
                                          variant="link" eventKey="1"
                                          onClick={onAccordionToggle}>
                          <h2 className='collapsed' style={{marginTop: "0px", borderBottom:'none', cursor: "pointer"}}>Order Distributor Activity</h2>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body>
                          <RecheckOrderActivity orderReference={order.orderReference} siteId={order?.distributorSiteId?.toString()} />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  : ' '}
                </Accordion>
              </>
          )}
        </Col>
        <Col md={4}>
          <EditorOnly>
            <ToolBar>
              <SecondaryButton onClick={showEditModal}>Edit</SecondaryButton>
              <DeleteButton onClick={deleteOrder} />
            </ToolBar>
          </EditorOnly>

          {order && (
            <InfoPane
              data={getInfoPaneData().data}
            />
          )}
          <hr />
          {order && <SitePreview id={order.siteId} />}
          {order && order.multipleNRTS && order.distributorSiteId &&
            <>
              <hr />
              <DistributorSitePreview id={order.distributorSiteId} />
            </>
          }
        </Col>
      </Row>
    </>
  )
}
